import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  IoPersonCircleSharp,
  IoAddCircleOutline,
} from "react-icons/io5";

function ProfileLinks({ handleCreate, firstName }) {

  const location = useLocation();
  const isCreateEventForm = location.pathname === "/createEventForm";

  return (
    <div className="flex items-center space-x-4">

      {/* Blogs button */}
      <a
        href="https://events.eventfullsunday.co.uk/"
        target="_blank"
        rel="noopener noreferrer"
        className="font-poppins text-base text-black font-semibold ml-2"
      >
        ORGANIZER GUIDE
      </a>

      {/* Create event button */}
      <button
        onClick={handleCreate}
        className={`${isCreateEventForm ? "hidden" : "flex"} items-center space-x-1 text-blue-500 hover:text-blue-700`}
      >
        <IoAddCircleOutline size={25} className="text-black" />
        <span className="hidden sm:inline font-poppins text-black">Create</span>
      </button>

      {/* Profile button */}
      <Link to="/dashboard" className="flex items-center ml-4">
        <IoPersonCircleSharp
          size={25}
          className="cursor-pointer text-black"
        />
        {firstName ? (
          <p className="ml-1 font-poppins text-base text-nowrap text-black font-medium">
            {firstName}
          </p>
        ) : (
          <div className="h-4 bg-gray-200 rounded mb-2 animate-pulse"></div>
        )}
      </Link>

    </div >
  )
}

export default ProfileLinks
