// src/components/AdditionalInfo.jsx

import React from 'react';
import PropTypes from 'prop-types';

const AdditionalInfo = ({
  company_name,
  description,
  business_email,
  business_phone,
  flat_house_number,
  street_address,
  city_town,
  county,
  post_code,
  country,
}) => {
  // Helper function to safely assign values or default to "N/A"
  const safeValue = (value) => (value === null || value === undefined || value === "" ? "N/A" : value);

  return (
    <div className="bg-white p-6 mb-4 xl:mt-0 rounded-lg shadow-lg w-full md:w-96">
      <h2 className="text-lg font-semibold mb-4">Additional Information</h2>
      <div className="space-y-3">
        <div>
          <p className="text-sm font-bold text-gray-700">Company Name</p>
          <p className="text-gray-900">{safeValue(company_name)}</p>
        </div>

        <div>
          <p className="text-sm font-bold text-gray-700">Description</p>
          <p className="text-gray-900">{safeValue(description)}</p>
        </div>

        <div>
          <p className="text-sm font-bold text-gray-700">Business Email</p>
          <p className="text-gray-900">{safeValue(business_email)}</p>
        </div>

        <div>
          <p className="text-sm font-bold text-gray-700">Business Phone</p>
          <p className="text-gray-900">{safeValue(business_phone)}</p>
        </div>

        <div>
          <p className="text-sm font-bold text-gray-700">Flat/House Number</p>
          <p className="text-gray-900">{safeValue(flat_house_number)}</p>
        </div>

        <div>
          <p className="text-sm font-bold text-gray-700">Street Address</p>
          <p className="text-gray-900">{safeValue(street_address)}</p>
        </div>

        <div>
          <p className="text-sm font-bold text-gray-700">City/Town</p>
          <p className="text-gray-900">{safeValue(city_town)}</p>
        </div>

        <div>
          <p className="text-sm font-bold text-gray-700">County</p>
          <p className="text-gray-900">{safeValue(county)}</p>
        </div>

        <div>
          <p className="text-sm font-bold text-gray-700">Postcode</p>
          <p className="text-gray-900">{safeValue(post_code)}</p>
        </div>

        <div>
          <p className="text-sm font-bold text-gray-700">Country</p>
          <p className="text-gray-900">{safeValue(country)}</p>
        </div>
      </div>
    </div>
  );
};

AdditionalInfo.propTypes = {
  company_name: PropTypes.string,
  description: PropTypes.string,
  business_email: PropTypes.string,
  business_phone: PropTypes.string,
  flat_house_number: PropTypes.string,
  street_address: PropTypes.string,
  city_town: PropTypes.string,
  county: PropTypes.string,
  post_code: PropTypes.string,
  country: PropTypes.string,
};

AdditionalInfo.defaultProps = {
  company_name: "",
  description: "",
  business_email: "",
  business_phone: "",
  flat_house_number: "",
  street_address: "",
  city_town: "",
  county: "",
  post_code: "",
  country: "",
};

export default AdditionalInfo;
