import apiClient from "../../../apiClient/apiClient";
import {
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
} from "../createConstant/createConstant";
import { toast } from "react-toastify";

export const createEventRequest = () => ({
  type: CREATE_EVENT_REQUEST,
});

export const createEventSuccess = (event) => ({
  type: CREATE_EVENT_SUCCESS,
  payload: event,
});

export const createEventFailure = (error) => ({
  type: CREATE_EVENT_FAILURE,
  payload: error,
});

export const createEvent = (formData) => async (dispatch) => {
  dispatch({ type: CREATE_EVENT_REQUEST });

  try {

    // Log the key/value pairs
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + " - " + pair[1]);
    // }

    const token = localStorage.getItem("access") || sessionStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await apiClient.post("api/events/create/", formData, config);

    dispatch({
      type: CREATE_EVENT_SUCCESS,
      payload: response.data,
    });

    if (!toast.isActive(1, "createEventForm")) {
      toast.success("Event created successfully!", {
        toastId: 5
      })
    }

    setTimeout(() => {
       window.location.href = "/";
    }, 1500);

  } catch (error) {
    let errorMessage =
      "An error occurred while creating the event. Please try again.";

    console.log(error);


    if (error.response && error.response.data) {
      // Check for specific field errors
      const {
        event_name,
        organizer,
        start_date,
        start_time,
        end_time,
        event_origin_country,
        ticket_price,
        capacity,
        venue,
        share_link,
        thumbnail_path,
        extra_image,
        extra_image2,
        category,
        subcategory_id,
        description,
      } = error.response.data;
      if (event_name) {
        errorMessage = `Event name: ${event_name}`;
      }
      if (organizer) {
        errorMessage = `Organizer: ${organizer}`;
      }
      if (start_date) {
        errorMessage = `Start Date:${start_date}`;
      }
      if (start_time) {
        errorMessage = `Start Time:${start_time}`;
      }
      if (end_time) {
        errorMessage = `End Time:${end_time}`;
      }
      if (event_origin_country) {
        errorMessage = `Event Origin country:${event_origin_country}`;
      }
      if (ticket_price) {
        errorMessage = `Ticket price:${ticket_price}`;
      }
      if (capacity) {
        errorMessage = `Capacity:${capacity}`;
      }
      if (venue) {
        errorMessage = `venue:${venue}`;
      }
      if (share_link) {
        errorMessage = `Share Link:${share_link}`;
      }
      if (thumbnail_path) {
        errorMessage = `Thumbnail path:${thumbnail_path}`;
      }
      if (extra_image) {
        errorMessage = `Extra Image:${extra_image}`;
      }
      if (extra_image2) {
        errorMessage = `Extra_image2:${extra_image2}`;
      }

      if (category) {
        errorMessage = `Category Error: ${category}`;
      } else if (subcategory_id) {
        errorMessage = `Subcategory Error: ${subcategory_id}`;
      } else if (description) {
        errorMessage = `Description Error: ${description}`;
      } else {
        errorMessage = error.response.data.message || errorMessage;
      }
    }

    dispatch({
      type: CREATE_EVENT_FAILURE,
      payload: errorMessage,
    });

    if (!toast.isActive(1, "editEventForm")) {
      toast.error(`${errorMessage}`, {
        toastId: 1
      })
    }

    console.log(errorMessage)
  }
};

// export const createEvent = (formData) => async (dispatch) => {
//   dispatch({ type: CREATE_EVENT_REQUEST });

//   try {
//     const token = localStorage.getItem('access');
//     const config = {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         Authorization: `Bearer ${token}`,
//       },
//     };

//     const response = await apiClient.post('/create-event/', formData, config);

//     dispatch({
//       type: CREATE_EVENT_SUCCESS,
//       payload: response.data,
//     });

//     toast.success('Event created successfully!');
//     setTimeout(() => {
//      window.location.href = '/home';
//     }, 2000);
//   } catch (error) {
//     dispatch({
//       type: CREATE_EVENT_FAILURE,
//       payload: error.message,
//     });

//     toast.error('An error occurred while creating the event. Please try again.');
//   }
// };
