// PlaceholderCard.jsx

import React from 'react';

const PlaceholderCard = () => {
  return (
    <div
      className="
        card-container
        flex
        flex-col
        flex-shrink-0
        flex-grow-0

        min-w-[150px]
        sm:min-w-[200px]
        md:min-w-[216px]
        lg:min-w-[222px]

        sm:min-h-[315px]
        md:min-h-auto
        lg:min-h-[290px]

        max-w-[100%]
        max-h-[290px]

        gap-4
        shadow-sm
        bg-white
        rounded-md
        overflow-hidden
      "
    >
      <div className="card-image relative w-full h-[450px] bg-gray-300 overflow-hidden">
        <div className="absolute inset-0 w-full h-full animate-shimmer bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300"></div>
      </div>
      <div className="card-body h-full flex flex-col pb-4 px-4 space-y-2">
        <div className="h-6 bg-gray-300 rounded relative overflow-hidden">
          <div className="absolute inset-0 w-full h-full animate-shimmer bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300"></div>
        </div>
        <div className="h-4 bg-gray-300 rounded relative overflow-hidden">
          <div className="absolute inset-0 w-full h-full animate-shimmer bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300"></div>
        </div>
        <div className="h-4 bg-gray-300 rounded relative overflow-hidden">
          <div className="absolute inset-0 w-full h-full animate-shimmer bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300"></div>
        </div>
        <div className="h-6 bg-gray-300 rounded mt-auto relative overflow-hidden">
          <div className="absolute inset-0 w-full h-full animate-shimmer bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300"></div>
        </div>
      </div>
    </div>
  );
};

export default PlaceholderCard;
