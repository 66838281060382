// src/components/CookieBanner.js

import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import event from "./assets/site-images/eventfullsunday.png";
import "./CookieBanner.css"; // Import the CSS file

const CookieBanner = ({ onAccept }) => {
    const [gaInitialized, setGaInitialized] = useState(false);

    const handleCookieAccept = () => {
        localStorage.setItem("cookie-consent", "accepted");
        onAccept();
        if (!gaInitialized) {
            setGaInitialized(true);
        }
    };

    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            enableDeclineButton
            onAccept={handleCookieAccept}
            onDecline={() => localStorage.setItem("cookie-consent", "declined")}
            containerClasses="cookie-banner"
            buttonClasses="cookie-button"
            declineButtonClasses="cookie-decline-button"
            contentStyle={{ margin: "0" }}
        >
            <div className="cookie-content">
                <img
                    src={event}
                    alt="Event Icon"
                    className="cookie-image"
                />
                <div>
                    <p className="cookie-text bold">
                        We use cookies to improve your experience!
                    </p>
                    <p className="cookie-text">
                        By continuing to use our website, you agree to our cookie policy.{" "}
                        <a
                            href="https://events.eventfullsunday.co.uk/privacy-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cookie-link">
                            Learn More
                        </a>
                    </p>
                </div>
            </div>
        </CookieConsent>
    );
};

export default CookieBanner;
