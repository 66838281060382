// src/components/layout/Navbar/Search/SearchForm.jsx

import { Field, Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { IoClose, IoFilter, IoSearch } from 'react-icons/io5';
import FilterModal from './FilterModal';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, resetFilters } from '../../../../redux/auth/action/filtersActions';

function SearchForm() {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [placeholderText, setPlaceholderText] = useState('Search events, communities, browse #hashtags');

    const dispatch = useDispatch();

    const filters = useSelector((state) => state.filters);

    // Updated filtersApplied logic
    const filtersApplied =
        filters.name.trim() !== "" ||
        filters.category.trim() !== "" ||
        (filters.dateRange.start !== null || filters.dateRange.end !== null) ||
        filters.startTime.trim() !== "" ||
        filters.endTime.trim() !== "" ||
        filters.location.trim() !== "" ||
        filters.ticketPriceMin.trim() !== "" ||
        filters.ticketPriceMax.trim() !== "" ||
        filters.isFreeTicket ||
        filters.capacityMin.trim() !== "" ||
        filters.capacityMax.trim() !== "";

    useEffect(() => {
        console.log(filters);
    }, [filters]); // This effect runs whenever 'filters' changes

    useEffect(() => {
        const updatePlaceholderText = () => {
            if (window.innerWidth >= 1024) {
                setPlaceholderText('Search events, communities, browse #hashtags');
            } else {
                setPlaceholderText('Search events');
            }
        };

        // Initial check
        updatePlaceholderText();

        // Add event listener
        window.addEventListener('resize', updatePlaceholderText);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updatePlaceholderText);
        };
    }, []);

    const handleSearchSubmit = (values, { resetForm }) => {
        dispatch(setFilters({ name: values.search }));
        resetForm();
    };

    const handleResetFilters = () => {
        dispatch(resetFilters());
    };

    return (
        <>
            <Formik initialValues={{ search: '' }} onSubmit={handleSearchSubmit}>
                {({ getFieldProps }) => (
                    <Form className="flex items-center w-full max-w-2xl relative">
                        <IoSearch className="absolute left-4 text-gray-500 w-5 h-5" />
                        <Field
                            type="text"
                            {...getFieldProps('search')}
                            placeholder={placeholderText}
                            className="w-full p-2 px-12 border-2 border-sunday_primary rounded-full focus:outline-none font-poppins"
                        />
                        {/* Filter/Clear Button */}
                        <button
                            type="button"
                            onClick={filtersApplied ? handleResetFilters : () => setIsFilterOpen(true)}
                            className="absolute right-4 text-gray-500 hover:text-sunday_secondary transition duration-150 focus:outline-none"
                            aria-label={filtersApplied ? 'Clear Filters' : 'Filter Events'}
                        >
                            {filtersApplied ? <IoClose size={20} /> : <IoFilter size={20} />}
                        </button>
                    </Form>
                )}
            </Formik>

            {/* Filter Modal */}
            <FilterModal
                isOpen={isFilterOpen}
                onClose={() => setIsFilterOpen(false)}
            />
        </>
    );
}

export default SearchForm;
