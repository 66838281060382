// VenueSelectField.jsx

import React, { useState, useEffect, useRef } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { IoSearch } from 'react-icons/io5';

const VenueSelectField = () => {
  const { setFieldValue, values } = useFormikContext();
  const [address, setAddress] = useState(values.line_1 || '');
  const [isScriptLoaded, setIsScriptLoaded] = useState(!!window.google);
  const suggestionsRef = useRef(null); // To handle clicks outside suggestions

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  useEffect(() => {
    if (window.google) {
      setIsScriptLoaded(true);
    }
  }, []);

  const handleSelect = async (value) => {
    setAddress(value);
    setFieldValue('line_1', value); // Update Formik field

    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);

      // Extract address components
      const addressComponents = results[0].address_components;

      // Initialize fields
      let line_2 = '';
      let postcode = '';

      addressComponents.forEach((component) => {
        const types = component.types;

        if (types.includes('postal_code')) {
          postcode = component.long_name;
        } else if (types.includes('route')) {
          line_2 = component.long_name;
        } else if (types.includes('street_number')) {
          line_2 = component.long_name + ' ' + line_2;
        }
      });

      // Update Formik fields
      setFieldValue('line_2', line_2);
      setFieldValue('postcode', postcode);

      // Optionally, set latitude and longitude if needed
      setFieldValue('latitude', latLng.lat);
      setFieldValue('longitude', latLng.lng);

      setShowAdditionalFields(true); // Show additional fields after selection
    } catch (error) {
      console.error('Error fetching address details:', error);
    }
  };

  // Show additional fields if something is typed in the main venue field
  useEffect(() => {
    if (address.trim() !== '') {
      setShowAdditionalFields(true);
    } else {
      setShowAdditionalFields(false);
    }
  }, [address]);

  return (
    <div className="mb-4">
      <div className="flex items-center justify-between">
        <p className="font-medium text-base tracking-wider">
          Venue <span className="text-red-500">*</span>
        </p>
      </div>
      <div className="relative flex flex-col">
        {!isScriptLoaded && (
          <div>Loading Google Maps API...</div>
        )}

        {isScriptLoaded ? (
          <PlacesAutocomplete
            value={address}
            onChange={(value) => {
              setAddress(value);
              setFieldValue('line_1', value); // Update Formik field
            }}
            onSelect={handleSelect}
            searchOptions={{
              // Optionally restrict search results
              // types: ['address'],
              // componentRestrictions: { country: 'uk' },
            }}
            debounce={100}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="relative">
                <input
                  {...getInputProps({
                    placeholder: 'Start typing your venue name',
                    className:
                      'w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2 focus:border-sunday_primary focus:outline-none',
                  })}
                />
                <IoSearch className="absolute right-4 text-gray-500 w-7 h-7 bottom-2 items-center flex justify-center text-center" />

                {/* Adjusted condition to render dropdown when loading or suggestions are present */}
                {(suggestions.length > 0 || loading) && (
                  <div
                    ref={suggestionsRef}
                    className="absolute z-10 bg-white border border-gray-300 mt-1 w-full rounded-lg shadow-lg max-h-60 overflow-y-auto"
                  >
                    {loading && <div className="p-2">Loading locations...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'p-2 bg-gray-200 cursor-pointer'
                        : 'p-2 cursor-pointer';

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}

                          key={suggestion.placeId} // Use a unique key from the suggestion object
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        ) : (
          <input
            type="text"
            placeholder="Loading venue field..."
            disabled
            className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2 focus:border-sunday_primary focus:outline-none bg-gray-100"
          />
        )}
        <ErrorMessage
          name="line_1"
          component="div"
          className="text-red-500 text-sm ml-2"
        />

        {/* Additional Fields */}
        {showAdditionalFields && (
          <div>
            <Field
              type="text"
              id="line_2"
              name="line_2"
              placeholder="Venue Address"
              className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
            />
            <ErrorMessage
              name="line_2"
              component="div"
              className="text-red-500 text-sm ml-2"
            />
            <Field
              type="text"
              id="postcode"
              name="postcode"
              placeholder="Postcode"
              className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
            />
            <ErrorMessage
              name="postcode"
              component="div"
              className="text-red-500 text-sm ml-2"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VenueSelectField;
