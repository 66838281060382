// src/redux/createEvent/CreateSubCategory/CreateSubCategoryActions.js

import {
  FETCH_SUBCATEGORIES_REQUEST,
  FETCH_SUBCATEGORIES_SUCCESS,
  FETCH_SUBCATEGORIES_FAILURE,
} from "../constant/subCategoryConstants";
import apiClient from "../../../apiClient/apiClient";

// Action to fetch subcategories
export const fetchSubcategories = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SUBCATEGORIES_REQUEST });
    try {

      const response = await apiClient.get("/sub-category/");

      console.log("[DEBUG] Sub-Category response: ", response);

      dispatch({
        type: FETCH_SUBCATEGORIES_SUCCESS,
        payload: response.data, // Assuming response.data is an array of subcategories
      });
    } catch (error) {
      dispatch({
        type: FETCH_SUBCATEGORIES_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
};
