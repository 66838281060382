import { GOOGLE_LOGIN_FAILURE, GOOGLE_LOGIN_SUCCESS } from "../constant/googleLoginConstants";

const initialState = {
    isAuthenticated: false,
    accessToken: (localStorage.getItem('access') || sessionStorage.getItem('access')) || null,
    refreshToken: (localStorage.getItem('refresh') || sessionStorage.getItem('refresh')) || null,
    error: null,
}

const googleAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case GOOGLE_LOGIN_SUCCESS:
            localStorage.setItem('access', action.payload.access);
            localStorage.setItem('refresh', action.payload.refresh);

            return {
                ...state,
                access: action.payload.access,
                refresh: action.payload.refresh,
                error: null,
            };
        case GOOGLE_LOGIN_FAILURE:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}

export default googleAuthReducer;