// src/components/EventCard.jsx

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { FaEllipsisV } from 'react-icons/fa';

const EventCard = ({ event, tickets, onSelectEvent, onDeleteEvent }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleCardClick = () => {
    onSelectEvent(event);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setMenuOpen(false);
    onDeleteEvent(event);
  };

  const handleViewEvent = () => {
    setMenuOpen(false);
    navigate(`/event/${event.event_id}/`);
  };

  const handleEditEvent = () => {
    setMenuOpen(false);
    navigate(`/editForm/${event.event_id}/`);
  };

  const handleCreateTickets = () => {
    setMenuOpen(false);
    navigate(`/create-ticket/${event.event_id}/`);
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  const eventDateTime =
    event.start_date && event.start_time
      ? new Date(`${event.start_date}T${event.start_time}`)
      : null;

  // Calculate total quantity of tickets for the event
  const totalQuantity = tickets.reduce(
    (sum, ticket) => sum + Number(ticket.total_quantity),
    0
  );

  //const sold = 0; // Placeholder since we don't have sold data
  const sold = tickets[0]?.ticket_sold;
  const soldPercentage = totalQuantity > 0 ? (sold / totalQuantity) * 100 : 0;
  const gross = sold * tickets[0]?.price;

  return (
    <li className="eds-card-list__item" onClick={handleCardClick}>
      <div className="eds-card eds-card--neutral hover:bg-gray-50 transition cursor-pointer">
        <div className="flex justify-between items-center p-4">
          {/* Event Info */}
          <div className="flex items-center w-1/2">
            {/* Date Thumbnail */}
            <div className="hidden 2xl:block text-center mr-4">
              {eventDateTime && (
                <div className="date-thumbnail font-poppins">
                  <p className="text-primary-brand text-sm text-sunday_primary font-bold">
                    {format(eventDateTime, 'MMM').toUpperCase()}
                  </p>
                  <p className="text-gray-700 text-lg font-bold">
                    {format(eventDateTime, 'dd')}
                  </p>
                </div>
              )}
            </div>
            {/* Event Thumbnail */}
            <div className="hidden 2xl:block mr-4">
              <img
                src={event.thumbnail_path || '/path/to/default-image.jpg'}
                alt={event.event_name}
                className="w-16 h-16 min-w-16 min-h-16 object-cover"
              />
            </div>
            {/* Event Details */}
            <div>
              <p className="text-blue-600 font-medium line-clamp-1">{event.event_name}</p>
              <p className="text-gray-600 text-sm">
                {eventDateTime
                  ? format(eventDateTime, "EEEE, dd MMMM yyyy 'at' HH:mm 'GMT'")
                  : 'Date not available'}
              </p>
              <p className="text-gray-600 text-sm">
                {event.venue_name || 'Venue not available'}
              </p>

              {/* Sold Info on small screens */}
              <div className={`block lg:hidden ${totalQuantity > 0 ? 'my-2' : 'my-1'} `}>
                {totalQuantity > 0 ? (
                  <div>
                    <p>{`${sold} / ${totalQuantity}`}</p>
                    <div className="w-full bg-gray-200 h-1 mt-1">
                      <div
                        className="bg-green-500 h-full"
                        style={{ width: `${soldPercentage}%` }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <p>No Tickets</p>
                )}
              </div>

              {/* Gross Placeholder on small screens */}
              <div className="block lg:hidden">
                <p>{gross ? `£${gross}` : `£0.00`}</p>
              </div>

            </div>
          </div>
          {/* Sold Info on medium and larger screens */}
          <div className="hidden lg:flex w-0 lg:w-1/6 justify-end px-3">
            {totalQuantity > 0 ? (
              <div className="w-full text-right">
                <p>{`${sold} / ${totalQuantity}`}</p>
                <div className="w-full bg-gray-200 h-1 mt-1">
                  <div
                    className="bg-green-500 h-full"
                    style={{ width: `${soldPercentage}%` }}
                  ></div>
                </div>
              </div>
            ) : (
              <p className="text-right">No Tickets</p>
            )}
          </div>
          {/* Gross Placeholder on large screens */}
          <div className="hidden lg:flex w-1/6 justify-end">
            <p>{gross ? `£${gross}` : `£0.00`}</p>
          </div>
          {/* Action Button */}
          <div className="relative w-1/6 flex justify-end">
            <button
              onClick={toggleMenu}
              className={`px-2 py-2 hover:bg-sunday_secondary focused:bg-sunday_secondary rounded-lg transition duration-150 text-sunday_info focus:outline-none
                ${menuOpen ? 'bg-sunday_secondary' : ''}
              `}
            >
              <FaEllipsisV />
            </button>
            {/* Dropdown Menu */}
            {menuOpen && (
              <div
                ref={menuRef}
                className="absolute right-0 mt-8 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50"
              >
                <ul className="py-1">
                  <li>
                    <button
                      onClick={handleViewEvent}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      View Event
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handleEditEvent}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Edit Event
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handleCreateTickets}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Create Tickets
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handleDeleteClick}
                      className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full text-left"
                    >
                      Delete Event
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  tickets: PropTypes.array.isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
};

export default EventCard;
