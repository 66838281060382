// src/redux/tickets/ticketConstants.js

export const TICKET_FETCH_ALL_REQUEST = "TICKET_FETCH_ALL_REQUEST";
export const TICKET_FETCH_ALL_SUCCESS = "TICKET_FETCH_ALL_SUCCESS";
export const TICKET_FETCH_ALL_FAIL = "TICKET_FETCH_ALL_FAIL";

export const TICKET_FETCH_DETAILS_REQUEST = "TICKET_FETCH_DETAILS_REQUEST";
export const TICKET_FETCH_DETAILS_SUCCESS = "TICKET_FETCH_DETAILS_SUCCESS";
export const TICKET_FETCH_DETAILS_FAIL = "TICKET_FETCH_DETAILS_FAIL";

export const TICKET_DELETE_REQUEST = "TICKET_DELETE_REQUEST";
export const TICKET_DELETE_SUCCESS = "TICKET_DELETE_SUCCESS";
export const TICKET_DELETE_FAIL = "TICKET_DELETE_FAIL";

export const TICKET_UPDATE_REQUEST = "TICKET_UPDATE_REQUEST";
export const TICKET_UPDATE_SUCCESS = "TICKET_UPDATE_SUCCESS";
export const TICKET_UPDATE_FAIL = "TICKET_UPDATE_FAIL";