import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventsList from './YourEventsSection/EventsList';
import EventTickets from './YourEventsSection/EventTickets'; // New component
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import apiClient from '../../../../apiClient/apiClient';
import { toast } from 'react-toastify';
import { fetchOrganiserEvents } from '../../../../redux/OrganiserEvent/organiserEventAction/GetOrganiserEventAction';
import TicketUpdateModal from './TicketUpdateModal';
import {
  fetchAllTickets,
  resetTicketUpdate,
  updateTicket,
} from '../../../../redux/tickets/ticketActions';
import ReactGA from 'react-ga4';

function ManageEvents() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const yourEvents = useSelector((state) => state.getOrganiserEvent);
  const ticketFetchAll = useSelector((state) => state.tickets);
  const { loading: loadingTickets, tickets = [], error: errorTickets } = ticketFetchAll;
  const ticketUpdate = useSelector((state) => state.ticketUpdate);
  const { success: successUpdate, error: errorUpdate } = ticketUpdate;
  const profileState = useSelector((state) => state.profile);

  const [ticketToUpdate, setTicketToUpdate] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // New state variable

  // Group tickets by event ID
  const ticketsByEvent = tickets.reduce((acc, ticket) => {
    const eventID = ticket.event;
    if (!acc[eventID]) {
      acc[eventID] = [];
    }
    acc[eventID].push(ticket);
    return acc;
  }, {});

  const handleOpenUpdateModal = (ticket) => {
    setTicketToUpdate(ticket);
    setIsUpdateModalOpen(true);
  };

  const handleDeleteEventClick = (event) => {
    setEventToDelete(event);
    setIsDeleteModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
    setTicketToUpdate(null);
  };

  useEffect(() => {
    if (errorTickets) {
      toast.error(errorTickets);
    }
    if (errorUpdate) {
      toast.error(errorUpdate);
    }
    if (successUpdate) {
      toast.success('Ticket updated successfully.');
      dispatch(resetTicketUpdate());
      ReactGA.event({
        category: 'User',
        action: 'Updated Ticket',
      });
    }
  }, [errorTickets, errorUpdate, successUpdate, dispatch]);

  const handleUpdateTicket = async (values) => {
    const { event, ticket_category, price, total_quantity, ticket_description } = values;

    try {
      await dispatch(
        updateTicket(ticketToUpdate.id, {
          event,
          ticket_category,
          price,
          total_quantity,
          ticket_description,
        })
      );
      dispatch(fetchAllTickets());
    } catch (error) {
      // Error handled in useEffect
    }
  };

  const handleDeleteEvent = async () => {
    if (!eventToDelete) return;

    try {
      await apiClient.delete(`/events/${eventToDelete.event_id}`);
      toast.success(`Event "${eventToDelete.event_name}" has been deleted.`);
      dispatch(fetchOrganiserEvents(profileState.profile.id));
    } catch (error) {
      console.error('Delete Event Error:', error);
      toast.error('Failed to delete the event. Please try again.');
    } finally {
      setIsDeleteModalOpen(false);
      setEventToDelete(null);
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const handleBackToEvents = () => {
    setSelectedEvent(null);
  };

  return (
    <div>
      {selectedEvent ? (
        <EventTickets
          event={selectedEvent}
          tickets={ticketsByEvent[selectedEvent.event_id] || []}
          loadingTickets={loadingTickets}
          onEditTicket={handleOpenUpdateModal}
          onCreateTicket={(eventId) => navigate(`/create-ticket/${eventId}`)}
          onBack={handleBackToEvents}
        />
      ) : (
        <div className="bg-white p-6 mb-4 rounded-lg shadow-lg w-full xl:w-[90%]">
          <h2 className="text-lg font-semibold mb-4">Your Events</h2>
          {yourEvents && yourEvents.data && yourEvents.data.length > 0 ? (
            <EventsList
              events={yourEvents.data}
              ticketsByEvent={ticketsByEvent}
              onSelectEvent={handleSelectEvent}
              onDeleteEvent={handleDeleteEventClick}
            />
          ) : (
            <p className="text-gray-500 text-center">You have no events.</p>
          )}
        </div>
      )}

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteEvent}
        eventName={eventToDelete ? eventToDelete.event_name : ''}
      />

      {/* Ticket Update Modal */}
      <TicketUpdateModal
        isOpen={isUpdateModalOpen}
        onRequestClose={handleCloseUpdateModal}
        onSubmit={handleUpdateTicket}
        initialValues={
          ticketToUpdate
            ? {
              event: ticketToUpdate.event,
              eventName:
                yourEvents.data.find((event) => event.event_id === ticketToUpdate.event)
                  ?.event_name || 'N/A',
              ticket_category: ticketToUpdate.ticket_category,
              price: ticketToUpdate.price,
              total_quantity: ticketToUpdate.total_quantity,
              ticket_description: ticketToUpdate.ticket_description,
            }
            : {
              event: '',
              eventName: '',
              ticket_category: '',
              price: '',
              total_quantity: '',
              ticket_description: '',
            }
        }
      />
    </div>
  );
}

export default ManageEvents;
