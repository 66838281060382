import React from "react";
import logo from "../../../assets/site-images/eventfullsunday.png";

const LeftSection = () => {
    return (
        <div className="flex justify-center items-center w-full lg:w-1/2 bg-[#f5f5f5] p-8 lg:p-16 font-poppins">
  <div className="w-full max-w-lg bg-white rounded-lg shadow-lg p-8 fade-in-top">
    <a title="Go to the Home Page" href="https://eventfullsunday.co.uk/">
      <img src={logo} alt="EventFullSunday Logo" className="w-32 h-32 mx-auto mb-6" />
    </a>
    <h1 className="text-4xl font-extrabold text-center text-[#23576A] leading-tight">
      Welcome to the Organizer Portal
    </h1>
    <p className="text-center text-lg text-[#558451] mt-4 italic">
      <span className="font-medium">Celebrate Diversity, Connect Communities, Create Memories</span>
    </p>
  
          <div className="border-t border-gray-200 mt-6 pt-6">
            <h2 className="text-2xl font-semibold text-[#F6B32D] mb-4">
              Why Choose EventfullSunday.co.uk?
            </h2>
            <ul className="space-y-4">
              <li className="flex items-start">
                <svg
                  className="h-6 w-6 text-[#FB8B4E] flex-shrink-0 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 00-1.414 1.414L9 14.414l8-8a1 1 0 00-1.414-1.414l-7 7z" />
                </svg>
                <p className="text-[#23576A]">
                  Reach the right audience with ease.
                </p>
              </li>
    
              <li className="flex items-start">
                <svg
                  className="h-6 w-6 text-[#FB8B4E] flex-shrink-0 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 00-1.414 1.414L9 14.414l8-8a1 1 0 00-1.414-1.414l-7 7z" />
                </svg>
                <p className="text-[#23576A]">
                  Seamless ticketing and event management.
                </p>
              </li>
              <li className="flex items-start">
                <svg
                  className="h-6 w-6 text-[#FB8B4E] flex-shrink-0 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 00-1.414 1.414L9 14.414l8-8a1 1 0 00-1.414-1.414l-7 7z" />
                </svg>
                <p className="text-[#23576A]">
                  AI-driven recommendations for better visibility.
                </p>
              </li>
              {/* <li className="flex items-start">
                <svg
                  className="h-6 w-6 text-[#FB8B4E] flex-shrink-0 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 00-1.414 1.414L9 14.414l8-8a1 1 0 00-1.414-1.414l-7 7z" />
                </svg>
                <p className="text-[#23576A]">
                  Secure and reliable platform for organizers.
                </p>
              </li> */}
            </ul>
          </div>
  
          {/* <div className="mt-8 text-center">
            <a
              href="https://organizer.eventfullsunday.co.uk/"
              className="inline-block px-8 py-3 text-base font-medium text-white bg-[#F6B32D] rounded-full hover:bg-[#F6476D] transition-colors"
            >
              Access Organizer Portal
            </a>
          </div> */}
        </div>
      </div>
    );
  };
  
  export default LeftSection;