// src/components/TicketCard.jsx

import React from 'react';
import PropTypes from 'prop-types';

const TicketCard = ({ ticket, onEdit }) => {
  // Placeholder values for Sold and Gross
  //const sold = 'N/A';
  //const gross = 'N/A';
  const sold = ticket?.ticket_sold;
  const gross = ticket?.price * sold;

  return (
    <div
      onClick={() => onEdit(ticket)}
      className="cursor-pointer p-4 border rounded bg-white hover:bg-gray-50 transition flex justify-between items-center"
    >
      <div>
        <p className="font-semibold">{ticket.ticket_category}</p>
        <p className="text-gray-600">Price: £{ticket.price}</p>
        <p className="text-gray-600">Total Quantity: {ticket.total_quantity}</p>
      </div>
      <div className="text-right">
        <p>Sold: {sold}</p>
        <p>Gross: £{gross}</p>
      </div>
    </div>
  );
};

TicketCard.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ticket_category: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    total_quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default TicketCard;
