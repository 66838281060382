import React from 'react';
import { ErrorMessage } from 'formik';
import { eventTypeChoices } from '../../../../assets/static/eventTypes';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

function EventSegmentField({
  eventSegmentDropdownRef,
  showEventSegmentDropdown,
  setFieldValue,
  profileState,
  setShowEventTypeDropdown,
  selectedEventSegment,
  setSelectedEventSegment,
}) {

  return (
    <div className="mb-4 relative" ref={eventSegmentDropdownRef}>
      <p className="font-medium text-base tracking-wider">
        Event Segment <span className="text-red-500">*</span>
      </p>
      <div className="relative">
        <div
          onClick={() => setShowEventTypeDropdown(!showEventSegmentDropdown)}
          tabIndex="0"
          className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border bg-white border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2 cursor-pointer flex justify-between items-center"
        >
          <span className={selectedEventSegment ? 'text-black' : 'text-gray-400'}>
            {selectedEventSegment
              ? eventTypeChoices.find((choice) => choice.key === selectedEventSegment)?.label
              : 'Select the event segment'}
          </span>
          {showEventSegmentDropdown ? (
            <IoChevronUpOutline size={20} />
          ) : (
            <IoChevronDownOutline size={20} />
          )}
        </div>
        {showEventSegmentDropdown && (
          <div className="absolute z-10 bg-white border border-gray-300 mt-1 w-full rounded-lg shadow-lg max-h-60 overflow-y-auto">
            {eventTypeChoices.map((choice) => (
              <div
                key={choice.key}
                onClick={() => {
                  setSelectedEventSegment(choice.key);
                  setFieldValue('event_segment', choice.key);
                  setShowEventTypeDropdown(false);
                }}
                className="p-2 hover:bg-gray-200 cursor-pointer"
              >
                {choice.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <ErrorMessage name="event_segment" component="div" className="text-red-500 text-sm ml-2" />
    </div>
  );
}

export default EventSegmentField;
