import axios from "axios";
import { refreshAccessToken } from "../redux/auth/action/LoginAction";
import store from "../redux/store";

// Define public endpoints that don't require Authorization headers
const publicEndpoints = [
  "api/events/",
  "subscription/",
];

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_CLIENT_BASE_URL,
});

// Request interceptor to attach Authorization header conditionally
apiClient.interceptors.request.use(
  (config) => {
    const access = localStorage.getItem("access") || sessionStorage.getItem("access");
    const isPublic = publicEndpoints.some((url) => config.url.includes(url));    

    if (access && !isPublic) {
      config.headers.Authorization = `Bearer ${access}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors and refresh token
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const status = error.response ? error.response.status : null;

    // Prevent infinite loop by checking if the request has been retried
    if (originalRequest._retry) {
      return Promise.reject(error);
    }

    // Check if the request was to a public endpoint
    const isPublic = publicEndpoints.some((url) => originalRequest.url.includes(url));

    if (status === 401 && !isPublic && !originalRequest.url.includes("login/")) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh the token
        const newAccessToken = await store.dispatch(refreshAccessToken());

        // Update the Authorization header with the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        // Retry the original request with the new token
        return apiClient(originalRequest);
      } catch (refreshError) {
        // If the token refresh failed, handle logout or other actions
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
