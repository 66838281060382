// src/components/PurchaseLogs.jsx

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPurchaseLogs } from '../../../../redux/auth/action/purchaseLogsActions';
import { fetchOrganiserEvents } from '../../../../redux/OrganiserEvent/organiserEventAction/GetOrganiserEventAction';
import { FaEllipsisV, FaSort, FaSortUp, FaSortDown, FaFileCsv, FaFileExcel, FaFilePdf, FaDownload } from 'react-icons/fa';
import { format, subDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Shimmer from '../../../Layout/Shimmer';
import Modal from '../../../Layout/Modal';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

/**
 * PurchaseLogs Component
 * Displays a list of purchase logs with filtering and sorting capabilities.
 */
const PurchaseLogs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Redux selectors
    const profileState = useSelector((state) => state.profile);
    const yourEvents = useSelector((state) => state.getOrganiserEvent);
    const purchaseLogsState = useSelector((state) => state.purchaseLogs);

    // Local state for filtering, sorting, and view mode
    const [selectedEventId, setSelectedEventId] = useState('all');
    const [sortConfig, setSortConfig] = useState({ key: 'ticket_purchase', direction: 'descending' });
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [isDetailedView, setIsDetailedView] = useState(false);

    // Add new state for date filtering
    const [dateRange, setDateRange] = useState({
        startDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'), // Last 30 days by default
        endDate: format(new Date(), 'yyyy-MM-dd')
    });

    // Add summary metrics state
    const [summaryMetrics, setSummaryMetrics] = useState({
        totalTickets: 0,
        checkedIn: 0,
        notCheckedIn: 0,
        totalRevenue: 0,
        recentSales: 0
    });

    useEffect(() => {
        // Fetch organizer events on component mount
        if (profileState?.profile?.id) {
            dispatch(fetchOrganiserEvents(profileState.profile.id));
        }
    }, [dispatch, profileState?.profile?.id]);

    useEffect(() => {
        // Fetch purchase logs for each event after events are fetched
        if (yourEvents?.data && yourEvents.data.length > 0) {
            yourEvents.data.forEach((event) => {
                dispatch(fetchPurchaseLogs(event.event_id));
            });
        }
    }, [dispatch, yourEvents]);

    useEffect(() => {
        // Handle errors
        if (purchaseLogsState.error) {
            toast.error(purchaseLogsState.error);
        }
    }, [purchaseLogsState.error]);

    useEffect(() => {
        // Prepare and sort filtered logs based on selected event and sort configuration
        let allLogs = [];

        if (selectedEventId === 'all') {
            // Combine logs from all events
            allLogs = Object.values(purchaseLogsState.logs).flatMap((log) => log.tickets);
        } else {
            // Filter logs for the selected event
            const eventLog = purchaseLogsState.logs[selectedEventId];
            if (eventLog) {
                allLogs = [...eventLog.tickets];
            }
        }

        // Sorting logic
        if (sortConfig !== null) {
            allLogs.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle nested objects and formatting
                if (sortConfig.key === 'checked_in_at') {
                    aValue = new Date(aValue);
                    bValue = new Date(bValue);
                }

                if (typeof aValue === 'string') {
                    aValue = aValue.toLowerCase();
                    bValue = bValue.toLowerCase();
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        setFilteredLogs(allLogs);
    }, [purchaseLogsState.logs, selectedEventId, sortConfig]);

    // Add this function to calculate filtered metrics
    const calculateFilteredMetrics = (logs) => {
        const startDate = new Date(dateRange.startDate);
        const endDate = new Date(dateRange.endDate);
        endDate.setHours(23, 59, 59); // Include the entire end date

        let totalTickets = 0;
        let checkedIn = 0;
        let notCheckedIn = 0;
        let totalRevenue = 0;
        let recentSales = 0;

        Object.values(logs).forEach(eventLog => {
            eventLog.tickets.forEach(ticket => {
                const ticketDate = new Date(ticket.checked_in_at);
                
                // Only count tickets within the date range
                if (ticketDate >= startDate && ticketDate <= endDate) {
                    totalTickets++;
                    totalRevenue += ticket.paid_amount;
                    
                    if (ticket.checked_in_status) {
                        checkedIn++;
                    } else {
                        notCheckedIn++;
                    }

                    // Count recent sales (last 24 hours)
                    if (ticketDate >= subDays(new Date(), 1)) {
                        recentSales++;
                    }
                }
            });
        });

        setSummaryMetrics({
            totalTickets,
            checkedIn,
            notCheckedIn,
            totalRevenue,
            recentSales
        });
    };

    // Update useEffect to include summary calculation
    useEffect(() => {
        if (purchaseLogsState.logs) {
            calculateFilteredMetrics(purchaseLogsState.logs);
        }
    }, [purchaseLogsState.logs, dateRange]);

    // Add date range handler
    const handleDateRangeChange = (e) => {
        const { name, value } = e.target;
        setDateRange(prev => ({
            ...prev,
            [name]: value
        }));
    };

    /**
     * Handles the change in event selection from the dropdown.
     * @param {Object} e - Event object
     */
    const handleEventChange = (e) => {
        setSelectedEventId(e.target.value);
    };

    /**
     * Handles sorting when a column header is clicked.
     * @param {string} key - The key to sort by
     */
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    /**
     * Handles the toggle of Detailed View.
     */
    const handleDetailedViewToggle = () => {
        setIsDetailedView(!isDetailedView);
    };

    // Define column configurations based on view mode
    const defaultColumns = [
        { label: 'Purchase ID', key: 'ticket_purchase', width: 'w-48' }, // 192px
        { label: 'Customer', key: 'user_name', width: 'w-48' }, // 192px
        { label: 'Event', key: 'event_name', width: 'w-48' }, // 192px
        { label: 'Total', key: 'paid_amount', width: 'w-40' }, // 160px
        { label: 'Date', key: 'checked_in_at', width: 'w-48' }, // 192px
    ];

    const detailedColumns = [
        { label: 'Purchase ID', key: 'ticket_purchase', width: 'w-32' }, // 128px
        { label: 'Customer', key: 'user_name', width: 'w-48' }, // 192px
        { label: 'Email', key: 'user_email', width: 'w-64' }, // 256px
        { label: 'Event', key: 'event_name', width: 'w-48' }, // 192px
        { label: 'Quantity', key: 'ticket_quantity', width: 'w-32' }, // 128px
        { label: 'Total', key: 'paid_amount', width: 'w-40' }, // 160px
        { label: 'Checked In', key: 'checked_in_status', width: 'w-32' }, // 128px
        { label: 'Date', key: 'checked_in_at', width: 'w-48' }, // 192px
        { label: 'Scanned By', key: 'scanned_by_details.name', width: 'w-48' }, // 256px
    ];

    const columns = isDetailedView ? detailedColumns : defaultColumns;

    // Function to format date consistently
    const formatDate = (date) => {
        return date ? format(new Date(date), 'dd MMM yyyy, HH:mm') + ' GMT' : 'N/A';
    };

    // Function to download XLS with all fields
    const downloadXLS = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data.map(ticket => ({
            'Purchase ID': ticket.ticket_purchase,
            'Customer Name': ticket.user_name,
            'Email': ticket.user_email,
            'Event Name': ticket.event_name,
            'Ticket Quantity': ticket.ticket_quantity,
            'Total Amount': `£${ticket.paid_amount.toFixed(2)}`,
            'Check-in Status': ticket.checked_in_status ? 'Checked In' : 'Not Checked In',
            'Check-in Date': formatDate(ticket.checked_in_at),
            'Scanned By': ticket.scanned_by_details?.name || 'N/A'
        })));

        // Set column widths
        const columnWidths = [
            { wch: 15 },  // Purchase ID
            { wch: 20 },  // Customer Name
            { wch: 30 },  // Email
            { wch: 25 },  // Event Name
            { wch: 15 },  // Ticket Quantity
            { wch: 15 },  // Total Amount
            { wch: 15 },  // Check-in Status
            { wch: 25 },  // Check-in Date
            { wch: 20 },  // Scanned By
        ];
        worksheet['!cols'] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchase Logs');
        
        // Generate buffer
        const excelBuffer = XLSX.write(workbook, { 
            bookType: 'xlsx', 
            type: 'array',
            bookSST: false,
            cellStyles: true
        });
        const blob = new Blob([excelBuffer], { 
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });
        saveAs(blob, 'purchase_logs.xlsx');
    };

    // Function to download PDF with all fields
    const downloadPDF = (data) => {
        const doc = new jsPDF('landscape', 'pt', 'a4');
        
        // Add title
        doc.setFontSize(18);
        doc.text('Purchase Logs', 40, 40);

        // Define the columns
        const columns = [
            { header: 'Purchase ID', dataKey: 'purchaseId' },
            { header: 'Customer', dataKey: 'customer' },
            { header: 'Email', dataKey: 'email' },
            { header: 'Event', dataKey: 'event' },
            { header: 'Qty', dataKey: 'quantity' },
            { header: 'Total', dataKey: 'total' },
            { header: 'Status', dataKey: 'status' },
            { header: 'Check-in Date', dataKey: 'date' },
            { header: 'Scanned By', dataKey: 'scannedBy' }
        ];

        // Prepare the data
        const tableData = data.map(ticket => ({
            purchaseId: ticket.ticket_purchase,
            customer: ticket.user_name,
            email: ticket.user_email,
            event: ticket.event_name,
            quantity: ticket.ticket_quantity,
            total: `£${ticket.paid_amount.toFixed(2)}`,
            status: ticket.checked_in_status ? 'Checked In' : 'Not Checked In',
            date: formatDate(ticket.checked_in_at),
            scannedBy: ticket.scanned_by_details?.name || 'N/A'
        }));

        // Add the table
        doc.autoTable({
            startY: 60,
            columns: columns,
            body: tableData,
            theme: 'grid',
            styles: {
                fontSize: 8,
                cellPadding: 5,
                overflow: 'linebreak',
                halign: 'left'
            },
            headStyles: {
                fillColor: [66, 139, 202],
                textColor: 255,
                fontSize: 9,
                fontStyle: 'bold',
                halign: 'center'
            },
            columnStyles: {
                purchaseId: { cellWidth: 70 },
                customer: { cellWidth: 80 },
                email: { cellWidth: 120 },
                event: { cellWidth: 100 },
                quantity: { cellWidth: 40 },
                total: { cellWidth: 60 },
                status: { cellWidth: 70 },
                date: { cellWidth: 90 },
                scannedBy: { cellWidth: 80 }
            },
            alternateRowStyles: {
                fillColor: [245, 245, 245]
            },
            margin: { top: 60, left: 40, right: 40, bottom: 40 }
        });

        doc.save('purchase_logs.pdf');
    };

    // Function to download CSV with all fields
    const downloadCSV = (data) => {
        const headers = [
            'Purchase ID',
            'Customer Name',
            'Email',
            'Event Name',
            'Ticket Quantity',
            'Total Amount',
            'Check-in Status',
            'Check-in Date',
            'Scanned By'
        ];

        const csvData = data.map(ticket => [
            ticket.ticket_purchase,
            ticket.user_name,
            ticket.user_email,
            ticket.event_name,
            ticket.ticket_quantity,
            `£${ticket.paid_amount.toFixed(2)}`,
            ticket.checked_in_status ? 'Checked In' : 'Not Checked In',
            formatDate(ticket.checked_in_at),
            ticket.scanned_by_details?.name || 'N/A'
        ]);
        
        const csvContent = [
            headers.join(','),
            ...csvData.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'purchase_logs.csv');
    };

    // Add this summary section component
    const SummarySection = () => (
        <div className="mb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
            {/* Total Tickets */}
            <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-sm font-medium text-gray-500">Total Tickets</h3>
                <div className="mt-2 flex items-baseline">
                    <p className="text-2xl font-semibold text-gray-900">
                        {summaryMetrics.totalTickets}
                    </p>
                    <p className="ml-2 text-sm text-gray-500">tickets</p>
                </div>
            </div>

            {/* Check-in Status */}
            <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-sm font-medium text-gray-500">Check-in Status</h3>
                <div className="mt-2">
                    <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600">Checked In</span>
                        <span className="text-sm font-semibold text-green-600">
                            {summaryMetrics.checkedIn}
                        </span>
                    </div>
                    <div className="flex justify-between items-center mt-1">
                        <span className="text-sm text-gray-600">Not Checked In</span>
                        <span className="text-sm font-semibold text-red-600">
                            {summaryMetrics.notCheckedIn}
                        </span>
                    </div>
                </div>
            </div>

            {/* Total Revenue */}
            <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-sm font-medium text-gray-500">Total Revenue</h3>
                <div className="mt-2 flex items-baseline">
                    <p className="text-2xl font-semibold text-gray-900">
                        £{summaryMetrics.totalRevenue.toFixed(2)}
                    </p>
                </div>
            </div>

            {/* Recent Sales */}
            <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-sm font-medium text-gray-500">Recent Sales (24h)</h3>
                <div className="mt-2 flex items-baseline">
                    <p className="text-2xl font-semibold text-gray-900">
                        {summaryMetrics.recentSales}
                    </p>
                    <p className="ml-2 text-sm text-gray-500">tickets</p>
                </div>
            </div>

            {/* Date Range Filter */}
            <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-sm font-medium text-gray-500 mb-2">Date Range</h3>
                <div className="space-y-2">
                    <div>
                        <label className="text-xs text-gray-500">Start Date</label>
                        <input
                            type="date"
                            name="startDate"
                            value={dateRange.startDate}
                            onChange={handleDateRangeChange}
                            className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md text-sm"
                        />
                    </div>
                    <div>
                        <label className="text-xs text-gray-500">End Date</label>
                        <input
                            type="date"
                            name="endDate"
                            value={dateRange.endDate}
                            onChange={handleDateRangeChange}
                            className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md text-sm"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="p-4 sm:p-6 bg-white rounded-lg shadow-lg w-full">
            {/* Add Summary Section at the top */}
            <SummarySection />

            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
                <div className="flex items-center gap-2">
                    <h2 className="text-xl sm:text-2xl font-semibold">Purchase Logs</h2>
                    <div className="relative group">
                        <button
                            className="p-2 hover:bg-gray-100 rounded-full"
                            title="Download Options"
                        >
                            <FaDownload className="text-gray-600" />
                        </button>
                        {/* Dropdown for download options */}
                        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50 hidden group-hover:block">
                            <div className="py-1">
                                <button
                                    onClick={() => downloadCSV(filteredLogs)}
                                    className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                                >
                                    <FaFileCsv className="text-blue-600" />
                                    <span>Download CSV</span>
                                </button>
                                <button
                                    onClick={() => downloadXLS(filteredLogs)}
                                    className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                                >
                                    <FaFileExcel className="text-green-600" />
                                    <span>Download Excel</span>
                                </button>
                                <button
                                    onClick={() => downloadPDF(filteredLogs)}
                                    className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                                >
                                    <FaFilePdf className="text-red-600" />
                                    <span>Download PDF</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Filter and View Toggle */}
            <div className="mb-6 flex items-center justify-between">
                {/* Filter Dropdown */}
                <div className="flex items-center">
                    <label htmlFor="eventFilter" className="mr-2 font-medium">
                        Filter by Event:
                    </label>
                    <select
                        id="eventFilter"
                        value={selectedEventId}
                        onChange={handleEventChange}
                        className="border border-gray-300 rounded px-3 py-2"
                    >
                        <option value="all">All Events</option>
                        {yourEvents?.data &&
                            yourEvents.data.map((event) => (
                                <option key={event.event_id} value={event.event_id}>
                                    {event.event_name}
                                </option>
                            ))}
                    </select>
                </div>

                {/* Detailed View Toggle */}
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="detailedView"
                        checked={isDetailedView}
                        onChange={handleDetailedViewToggle}
                        className="mr-2"
                    />
                    <label htmlFor="detailedView" className="font-medium">
                        Detailed View
                    </label>
                </div>
            </div>

            {/* Loading State */}
            {purchaseLogsState.loading && (
                <div className='flex flex-col gap-y-2'>
                    <Shimmer width="100%" height="80px" />
                    {[...Array(2)].map((_, index) => <Shimmer key={index} width="100%" height="112px" />)}
                </div>
            )}

            {/* Add horizontal scrolling for the detailed view */}
            <div className={`overflow-x-auto ${isDetailedView ? 'pb-4' : ''}`}>
                <div className={`min-w-full ${isDetailedView ? 'w-max' : ''}`}>
                    <div className={`grid ${
                        isDetailedView 
                            ? 'grid-flow-col auto-cols-auto grid-cols-none'
                            : 'grid-cols-2 sm:grid-cols-3 lg:grid-cols-[repeat(5,1fr)_1.5rem]'
                        } gap-4 p-4 bg-gray-100 sticky top-0 z-10 rounded-t-lg`}
                    >
                        {columns.map((column) => (
                            <div
                                key={column.label}
                                className={`flex items-center ${column.key ? 'cursor-pointer' : ''} 
                                    ${isDetailedView ? column.width : 'flex-1'}
                                    ${!isDetailedView && column.label === 'Date' ? 'hidden lg:flex' : ''}
                                    ${!isDetailedView && column.label === 'Customer' ? 'hidden sm:flex' : ''}
                                `}
                                onClick={() => column.key && handleSort(column.key)}
                            >
                                <span className="font-bold text-sm whitespace-nowrap flex items-center">
                                    {column.label}
                                    {column.key && (
                                        <span className="ml-1">
                                            {sortConfig.key === column.key ? (
                                                sortConfig.direction === 'ascending' ? (
                                                    <FaSortUp className="ml-1" />
                                                ) : (
                                                    <FaSortDown className="ml-1" />
                                                )
                                            ) : (
                                                <FaSort className="ml-1" />
                                            )}
                                        </span>
                                    )}
                                </span>
                            </div>
                        ))}
                        {/* Empty Column for Action Menu */}
                        <div className="flex items-center justify-end w-6 min-w-[1.5rem]">
                            {/* Placeholder to align with action buttons */}
                        </div>
                    </div>

                    {/* Purchase Logs List */}
                    <ul className="divide-y divide-gray-200">
                        {filteredLogs.map((ticket) => (
                            <PurchaseLogCard
                                key={ticket.ticket_purchase}
                                ticket={ticket}
                                isDetailedView={isDetailedView}
                                columns={columns}
                            />
                        ))}
                    </ul>
                </div>
            </div>

            <ToastContainer />
        </div>
    )
};

/**
 * PurchaseLogCard Component
 * Displays individual purchase log details in a card format.
 */
const PurchaseLogCard = ({ ticket, isDetailedView, columns }) => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * Toggles the action menu.
     * @param {Object} e - Event object
     */
    const toggleMenu = (e) => {
        e.stopPropagation();
        setMenuOpen(!menuOpen);
    };

    /**
     * Closes the menu when clicking outside.
     */
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setMenuOpen(false);
            }
        };
        if (menuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    /**
     * Handles navigation to the detailed view of the ticket or user.
     */
    const handleViewDetails = () => {
        setIsModalOpen(true);
        setMenuOpen(false);
    };

    const handleCheckIn = () => {
        // Implement logic as needed with /check-in/validate endpoint
        setMenuOpen(false);
    };

    // Helper function to safely access nested properties
    const getNestedProperty = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    return (
        <li className={`py-4 ${isDetailedView ? 'w-max' : 'w-full'}`}>
            <div
                className={`grid ${isDetailedView
                    ? 'grid-flow-col auto-cols-auto grid-cols-none w-max'
                    : 'grid-cols-[repeat(5,1fr)_1.5rem] w-full'
                    } gap-4 items-center hover:bg-gray-50 transition p-4 rounded`}
            >
                {/* Purchase ID - Make it clickable */}
                <div 
                    className={`text-gray-800 ${isDetailedView ? 'w-32' : 'flex-1'} cursor-pointer`}
                    onClick={handleViewDetails} // Add onClick handler
                >
                    {ticket.ticket_purchase}
                </div>
                {/* Customer */}
                <div className={`text-gray-700 ${isDetailedView ? 'w-48' : 'flex-1'}`}>
                    {ticket.user_name}
                </div>
                {/* Email (Detailed View Only) */}
                {isDetailedView && (
                    <div className="text-gray-700 break-words w-64">
                        {ticket.user_email}
                    </div>
                )}
                {/* Event */}
                <div className={`text-gray-700 ${isDetailedView ? 'w-48' : 'flex-1'}`}>
                    {ticket.event_name}
                </div>
                {/* Quantity (Detailed View Only) */}
                {isDetailedView && (
                    <div className="text-gray-700 w-32">
                        {ticket.ticket_quantity}
                    </div>
                )}
                {/* Total */}
                <div className={`text-gray-700 ${isDetailedView ? 'w-40' : 'flex-1'}`}>
                    £{ticket.paid_amount.toFixed(2)}
                </div>
                {/* Checked In (Detailed View Only) */}
                {isDetailedView && (
                    <div className="w-32">
                        <span className={`font-semibold ${ticket.checked_in_status ? 'text-green-600' : 'text-red-600'}`}>
                            {ticket.checked_in_status ? 'Yes' : 'No'}
                        </span>
                    </div>
                )}
                {/* Date */}
                <div
                    className={`text-gray-700 ${isDetailedView
                        ? 'w-48'
                        : 'flex-1 whitespace-normal break-words'
                        }`}
                >
                    {ticket.checked_in_at
                        ? format(new Date(ticket.checked_in_at), 'dd MMM, HH:mm') + ' GMT'
                        : 'N/A'}
                </div>
                {/* Scanned By (Detailed View Only) */}
                {isDetailedView && (
                    <div className="text-gray-700 w-48">
                        {getNestedProperty(ticket, 'scanned_by_details.name') || 'N/A'}
                    </div>
                )}

                {/* Action Menu */}
                <div className="flex items-center justify-end w-6 min-w-[1.5rem]">
                    <div className="relative">
                        <button
                            onClick={toggleMenu}
                            className={`p-2 hover:bg-gray-200 focus:bg-gray-200 rounded-lg transition duration-150 text-gray-600 focus:outline-none
                                ${menuOpen ? 'bg-gray-200' : ''}
                            `}
                        >
                            <FaEllipsisV />
                        </button>
                        {/* Dropdown Menu */}
                        {menuOpen && (
                            <div
                                ref={menuRef}
                                className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50"
                            >
                                <ul className="py-1">
                                    <li>
                                        <button
                                            onClick={handleViewDetails}
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                        >
                                            View Ticket
                                        </button>
                                        {isDetailedView && (
                                            <button
                                                onClick={handleCheckIn}
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                            >
                                                Check-in
                                            </button>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Add Modal here */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Ticket Details"
            >
                <div className="p-4 sm:p-6 bg-white rounded-lg">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Left Column */}
                        <div className="space-y-6">
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-xs uppercase font-medium text-gray-500 mb-2">Purchase ID</h3>
                                <p className="text-base sm:text-lg font-semibold text-gray-900 break-all">
                                    {ticket.ticket_purchase}
                                </p>
                            </div>

                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-xs uppercase font-medium text-gray-500 mb-2">Customer</h3>
                                <p className="text-base sm:text-lg font-semibold text-gray-900">
                                    {ticket.user_name}
                                </p>
                            </div>

                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-xs uppercase font-medium text-gray-500 mb-2">Email</h3>
                                <p className="text-base sm:text-lg font-semibold text-gray-900 break-all">
                                    {ticket.user_email}
                                </p>
                            </div>

                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-xs uppercase font-medium text-gray-500 mb-2">Event</h3>
                                <p className="text-base sm:text-lg font-semibold text-gray-900">
                                    {ticket.event_name}
                                </p>
                            </div>
                        </div>

                        {/* Right Column */}
                        <div className="space-y-6">
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-xs uppercase font-medium text-gray-500 mb-2">Quantity</h3>
                                <p className="text-base sm:text-lg font-semibold text-gray-900">
                                    {ticket.ticket_quantity}
                                </p>
                            </div>

                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-xs uppercase font-medium text-gray-500 mb-2">Total Paid</h3>
                                <p className="text-base sm:text-lg font-semibold text-gray-900">
                                    £{ticket.paid_amount.toFixed(2)}
                                </p>
                            </div>

                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-xs uppercase font-medium text-gray-500 mb-2">Check-in Status</h3>
                                <div className={`inline-flex items-center px-3 py-1 rounded-full ${
                                    ticket.checked_in_status 
                                        ? 'bg-green-100 text-green-800' 
                                        : 'bg-red-100 text-red-800'
                                }`}>
                                    <span className="text-sm font-medium">
                                        {ticket.checked_in_status ? 'Checked In' : 'Not Checked In'}
                                    </span>
                                </div>
                            </div>

                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h3 className="text-xs uppercase font-medium text-gray-500 mb-2">Purchase Date</h3>
                                <p className="text-base sm:text-lg font-semibold text-gray-900">
                                    {ticket.checked_in_at
                                        ? format(new Date(ticket.checked_in_at), 'dd MMM yyyy, HH:mm') + ' GMT'
                                        : 'N/A'}
                                </p>
                            </div>

                            {ticket.scanned_by_details && (
                                <div className="bg-gray-50 p-4 rounded-lg">
                                    <h3 className="text-xs uppercase font-medium text-gray-500 mb-2">Scanned By</h3>
                                    <p className="text-base sm:text-lg font-semibold text-gray-900">
                                        {getNestedProperty(ticket, 'scanned_by_details.name') || 'N/A'}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    
                    {/* Modal Actions */}
                    <div className="mt-8 flex justify-end space-x-4 border-t pt-4">
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="px-6 py-2.5 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors duration-200"
                        >
                            Close
                        </button>
                        {!ticket.checked_in_status && (
                            <button
                                onClick={handleCheckIn}
                                className="px-6 py-2.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
                            >
                                Check In
                            </button>
                        )}
                    </div>
                </div>
            </Modal>
        </li>
    )
};

PurchaseLogCard.propTypes = {
    ticket: PropTypes.object.isRequired,
    isDetailedView: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
};

export default PurchaseLogs;
