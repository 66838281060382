import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { sendResetPassword } from '../../redux/auth/action/ForgotPasswordAction';
import { Link } from "react-router-dom";
import user from "../../assets/site-images/user.png";
import logo from "../../assets/site-images/eventfullsunday.png";
import { IoPersonCircle } from 'react-icons/io5';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LeftSection from "../../components/Layout/LeftSection/LeftSection"

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const handleSubmitForgotPassword = (values, { setSubmitting }) => {
    dispatch(sendResetPassword(values.email, true))
      .catch((error) => {
        console.error('Failed to send password reset link:', error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <ToastContainer position="top-right" autoClose={99999} />

      {/* Left Section */}
      <LeftSection
        logo={logo}
        mainImage={user}
        mainText="Eventfull Sundays"
      />

      {/* Right Section: Forgot Password Form */}
      <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8">
        <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-md">
          <Formik
            initialValues={{ email: '' }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmitForgotPassword}
          >
            {({ isSubmitting }) => (
              <Form className="w-full">
                <p className="text-2xl font-semibold text-center mb-6">Forgot Password</p>
                <div className="mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium">Email</label>
                  <div className="relative">
                    <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      placeholder="nepalieventorg@gmail.com"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                  </div>
                  <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div className="flex justify-center mb-4">
                  <button
                    type="submit"
                    className="w-full p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
                <div className="text-center">
                  <p className="text-sm">
                    Just remembered password? <Link to="/login" className="text-blue-600 underline">Login Here</Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
