// Venue and Time display ath the top of event details page

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CiTimer, CiShare2, CiCalendarDate, CiEdit } from 'react-icons/ci'
import Shimmer from '../../Layout/Shimmer';
import { toast } from 'react-toastify';
import { fetchOrganiserEvents } from '../../../redux/OrganiserEvent/organiserEventAction/GetOrganiserEventAction';
import { getUserProfile } from '../../../redux/auth/action/userProfileActions';

function VenueTime({ eventItemsLoading, data, short = "N/A", duration = "N/A", id }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileState = useSelector((state) => state.profile);
  const yourEvents = useSelector((state) => state.getOrganiserEvent);
  const [isUserEvent, setIsUserEvent] = useState(false);

  useEffect(() => {
    dispatch(getUserProfile());

    if (profileState?.profile?.id) {
      dispatch(fetchOrganiserEvents(profileState.profile.id));
    }

  }, [dispatch, profileState?.profile?.id])

  useEffect(() => {
    console.log("[DEBUG] Organizer events: ", yourEvents);
  }, [yourEvents]);

  useEffect(() => {
    if (yourEvents && yourEvents.data && Array.isArray(yourEvents.data) && id) {
      const isOwner = yourEvents.data.some(event => String(event.event_id) === String(id));
      setIsUserEvent(isOwner);
    }
  }, [yourEvents, id]);

  /**
 * Handles the sharing of event details using the Web Share API.
 */
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this awesome event!",
          text: `Join me at ${data?.event_name}!`,
          url: data?.share_link || window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      if (!toast.isActive(8, "eventDetails")) {
        toast.warn("Sharing is not supported in your browser.");
      }
    }
  };

  /**
   * Formats the event date string into a more readable format.
   * @param {string} dateString - The date string to format.
   * @returns {string} - The formatted date string.
   */

  // Old format date
  // const formatDate = (dateString) => {
  //   if (!dateString) return "N/A";
  //   const date = new Date(dateString);
  //   const options = { weekday: "short", day: "numeric", month: "long", year: "numeric" };
  //   return new Intl.DateTimeFormat("en-GB", options).format(date);
  // };

  const formatDateShort = (startDateStr) => {
    if (!startDateStr) return "N/A"; // Handle undefined or null dates

    const startDate = new Date(startDateStr);

    // Extract individual components
    const weekday = startDate.toLocaleDateString('en-GB', { weekday: 'long' });
    const day = startDate.getDate();
    const month = startDate.toLocaleDateString('en-GB', { month: 'long' });

    // Combine components with a comma after the weekday
    return `${weekday}, ${day} ${month}`;
  };

  function formatDateAndTime(data) {

    const { start_date, end_date, start_time, end_time } = data;


    const optionsDate = { month: 'long', day: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };

    const start = new Date(`${start_date}T${start_time}`);
    const end = new Date(`${end_date}T${end_time}`);

    const formattedStartDate = start.toLocaleDateString('en-US', optionsDate);
    const formattedEndDate = end.toLocaleDateString('en-US', optionsDate);

    const formattedStartTime = start.toLocaleTimeString('en-US', optionsTime).replace(':00 ', ' ');
    const formattedEndTime = end.toLocaleTimeString('en-US', optionsTime).replace(':00 ', ' ');

    return `${formattedStartDate} · ${formattedStartTime} - ${formattedEndDate} · ${formattedEndTime} GMT`;
  }

  function calculateEventDuration(data) {
    const { start_date, end_date, start_time, end_time } = data;

    const start = new Date(`${start_date}T${start_time}`);
    const end = new Date(`${end_date}T${end_time}`);

    let diffInMs = end - start;

    if (diffInMs < 0) return "Invalid event duration";

    const msInHour = 1000 * 60 * 60;
    const msInDay = msInHour * 24;

    const days = Math.floor(diffInMs / msInDay);
    diffInMs %= msInDay;

    const hours = Math.floor(diffInMs / msInHour);

    let duration = "Event lasts ";
    if (days > 0) duration += `${days} day${days > 1 ? 's' : ''} `;
    if (hours > 0) duration += `${hours} hour${hours > 1 ? 's' : ''}`;
    if (days === 0 && hours === 0) duration += "less than an hour";

    return duration.trim();
  }

  if (eventItemsLoading) {
    return (
      <div className="flex flex-row gap-2 w-full md:w-auto">
        <Shimmer width="150px" height="20px" borderRadius="rounded" />
      </div>
    );
  }

  if (!data) return null;

  return (
    <div className="relative flex flex-row justify-between">
      {/* Venue and Time */}
      <div className={`flex flex-row justify-between items-center ${short === true ? 'mt-4 mb-2' : duration === true ? 'mt-2 mb-3' : ''} pr-0`}>

        {eventItemsLoading && (
          <div className="flex flex-row gap-2 w-full md:w-auto">
            <Shimmer width="150px" height="20px" borderRadius="rounded" />
          </div>
        )}

        {/* Short date and time */}
        {(!eventItemsLoading && short === true) && (
          <div className="flex flex-row">
            <p className="m-0 flex justify-center items-center">
              {formatDateShort(data.start_date)}
            </p>
          </div>
        )}

        {/* Long date and time */}
        {(!eventItemsLoading && short === false) && (
          <div className="flex flex-row justify-center items-center">
            <CiCalendarDate className="mr-2 min-w-[25px]" size={25} />
            <div>
              <p className="text-center mt-[3px]">
                {formatDateAndTime(data)}
              </p>
            </div>
          </div>
        )}

        {/* Event duration */}
        {(!eventItemsLoading && duration === true) && (
          <div className="flex flex-row justify-center items-center">
            <CiTimer className="mr-2 min-w-[25px]" size={25} />
            <div>
              <p className="text-center mt-[3px]">
                {calculateEventDuration(data)}
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Updated Buttons Container */}
      <div className="flex justify-end space-x-2 mt-2 md:absolute md:right-2 md:bottom-1">
        {(eventItemsLoading && short === true) && (
          <Shimmer width="21px" height="21px" borderRadius="rounded-full" />
        )}

        {(!eventItemsLoading && short === true) && (
          <button className="p-1 rounded-lg button-hover-effect" onClick={handleShare} title="Share Event">
            <CiShare2 size={21} className="text-black" />
          </button>
        )}

        {(!eventItemsLoading && short === true && isUserEvent) && (
          <button
            className="p-1 rounded-lg button-hover-effect"
            onClick={() => navigate(`/editForm/${data.event_id}`)}
            title="Edit Event"
          >
            <CiEdit size={21} className="text-black" />
          </button>
        )}
      </div>

    </div>
  )
}

export default VenueTime
