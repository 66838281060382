import { ErrorMessage } from 'formik';
import React from 'react'
import ReactQuill from 'react-quill';
import "./ReactQuillCustom.css";

function DescriptionField({ values, setFieldValue }) {

  // React Quill modules and formats for toolbar customization
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "list",
    "bullet",
    "color",
    "background",
    "link",
  ];

  return (
    <div className="mb-4">
      <p className="font-normal text-base tracking-wider mb-2">
        Description <span className="text-red-500">*</span>
      </p>
      <ReactQuill
        theme="snow"
        value={values.description}
        onChange={(content, delta, source, editor) => {
          setFieldValue("description", editor.getHTML());
        }}
        className="custom-react-quill" // Apply custom class
        modules={modules}
        formats={formats}
      />
      <ErrorMessage
        name="description"
        component="div"
        className="text-red-500 text-sm ml-2"
      />
    </div>
  )
}

export default DescriptionField
