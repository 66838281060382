// src/components/pages/CreateTicketForm.jsx

import React, { useEffect, useState } from 'react';
import Navbar from '../../../Layout/Navbar/Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EventGetItems } from '../../../../redux/createEvent/createEventAction/EventDetailAction';
import { createTicket } from '../../../../redux/CreateTicket/createTicketAction/createTicketAction';
import { Field, Form, Formik } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../../../Layout/Footer/Footer';
import Shimmer from '../../../Layout/Shimmer';
import ReactGA from 'react-ga4';
import * as Yup from 'yup'; // Import Yup

function CreateTicketForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux State Selectors
  const eventItem = useSelector((state) => state.eventItems);
  const { data, loading, error } = eventItem;

  // Local State
  const [ticketPrice, setTicketPrice] = useState(data?.ticket_price);
  const [totalQuantity, setTotalQuantity] = useState(data?.capacity);
  const [availableQuantity, setAvailableQuantity] = useState(data?.capacity);
  const [ticketSold, setTicketSold] = useState('0');

  // Fetch event data on mount and when ID changes
  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));
    }
  }, [dispatch, id]);

  // Update local states when event data changes
  useEffect(() => {
    if (data) {
      setTicketPrice(data.ticket_price);
      setTotalQuantity(data.capacity);
      setAvailableQuantity(data.capacity);
    }
  }, [data]);

  // Define Yup validation schema
  const validationSchema = Yup.object().shape({
    event_name: Yup.string().required('Event Name is required'),
    ticket_price: Yup.number()
      .typeError('Ticket Price must be a number')
      .required('Ticket Price is required'),
    total_quantity: Yup.number()
      .typeError('Total Quantity must be a number')
      .integer('Total Quantity must be an integer')
      .min(1, 'Total Quantity must be at least 1')
      .required('Total Quantity is required'),
    available_quantity: Yup.number()
      .typeError('Available Quantity must be a number')
      .integer('Available Quantity must be an integer')
      .required('Available Quantity is required'),
    ticket_sold: Yup.number()
      .typeError('Tickets Sold must be a number')
      .integer('Tickets Sold must be an integer')
      .required('Tickets Sold is required'),
    ticket_category: Yup.string().required('Ticket Category is required'),
    ticket_description: Yup.string().required('Ticket Description is required'),
  });

  // Handle form submission
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    formData.append('event', id);
    formData.append('event_name', values.event_name);
    formData.append('price', values.ticket_price);
    formData.append('total_quantity', values.total_quantity);
    formData.append('available_quantity', values.available_quantity);
    formData.append('ticket_sold', values.ticket_sold);
    formData.append('ticket_category', values.ticket_category);
    formData.append('ticket_description', values.ticket_description);

    dispatch(createTicket(formData))
      .then(() => {
        // Navigate to dashboard after successful ticket creation
        navigate('/dashboard');

        // Fire GA event for successful ticket creation
        ReactGA.event({
          category: 'Ticket',
          action: 'Create Ticket',
          label: values.ticket_category, // Ensure no PII is included
        });

        // Optionally, reset the form
        resetForm();
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error creating ticket.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (loading) {
    return (
      <div className="w-full h-auto font-poppins text-[#565656] bg-[#f2f4f7]">
        <ToastContainer position="top-right" autoClose={5000} />
        <Navbar linkTo="/home" title="Create Ticket" />
        <div className="max-w-lg mx-auto space-y-4 bg-white shadow-md rounded-lg my-10">
          <Shimmer width="536px" height="711px" />
        </div>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-auto font-poppins text-[#565656] bg-[#f2f4f7]">
        <ToastContainer position="top-right" autoClose={5000} />
        <Navbar linkTo="/home" title="Create Ticket" />
        <div className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg my-10">
          <p className="text-red-500">{error}</p>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="w-full h-auto font-poppins text-[#565656] bg-[#f2f4f7]">
      <ToastContainer position="top-right" autoClose={5000} />
      <Navbar linkTo="/home" title="Create Ticket" />

      <div className="max-w-lg mx-auto p-6 space-y-4 bg-white shadow-md rounded-lg my-10">
        <Formik
          enableReinitialize
          initialValues={{
            event_name: data?.event_name || '',
            ticket_price: ticketPrice || '',
            total_quantity: totalQuantity || '',
            available_quantity: availableQuantity || '',
            ticket_sold: ticketSold || '',
            ticket_category: '',
            ticket_description: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            isSubmitting,
            handleSubmit,
          }) => (
            <Form className="flex flex-col">
              {/* Event Name */}
              <div className="mb-4">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Event Name<span className="text-red-500">*</span>
                </p>
                <Field
                  id="event_name"
                  name="event_name"
                  type="text"
                  placeholder="Event title goes here"
                  className={`w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border ${errors.event_name && touched.event_name
                    ? 'border-red-500'
                    : 'border-sunday_secondary'
                    }`}
                  disabled
                />
                {errors.event_name && touched.event_name && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.event_name}
                  </div>
                )}
              </div>

              {/* Ticket Price */}
              <div className="mb-4">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Ticket Price<span className="text-red-500">*</span>
                </p>
                <Field
                  id="ticket_price"
                  name="ticket_price"
                  type="number"
                  min="0"
                  placeholder="Ticket Price"
                  className={`w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border ${errors.ticket_price && touched.ticket_price
                    ? 'border-red-500'
                    : 'border-sunday_secondary'
                    }`}
                />
                {errors.ticket_price && touched.ticket_price && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.ticket_price}
                  </div>
                )}
              </div>

              {/* Total Quantity */}
              <div className="mb-4">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Total Quantity<span className="text-red-500">*</span>
                </p>
                <Field
                  id="total_quantity"
                  name="total_quantity"
                  type="number"
                  min="0"
                  placeholder="Total Tickets"
                  className={`w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border ${errors.total_quantity && touched.total_quantity
                    ? 'border-red-500'
                    : 'border-sunday_secondary'
                    }`}
                />
                {errors.total_quantity && touched.total_quantity && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.total_quantity}
                  </div>
                )}
              </div>

              {/* Available Quantity */}
              <div className="mb-4">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Available Quantity<span className="text-red-500">*</span>
                </p>
                <Field
                  id="available_quantity"
                  name="available_quantity"
                  type="number"
                  min="0"
                  placeholder="Available Tickets"
                  className={`w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border ${errors.available_quantity && touched.available_quantity
                    ? 'border-red-500'
                    : 'border-sunday_secondary'
                    }`}
                />
                {errors.available_quantity && touched.available_quantity && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.available_quantity}
                  </div>
                )}
              </div>

              {/* Tickets Sold */}
              <div className="mb-4">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Tickets Sold<span className="text-red-500">*</span>
                </p>
                <Field
                  id="ticket_sold"
                  name="ticket_sold"
                  type="number"
                  min="0"
                  placeholder="Tickets Sold"
                  className={`w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border ${errors.ticket_sold && touched.ticket_sold
                    ? 'border-red-500'
                    : 'border-sunday_secondary'
                    }`}
                />
                {errors.ticket_sold && touched.ticket_sold && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.ticket_sold}
                  </div>
                )}
              </div>

              {/* Ticket Category */}
              <div className="mb-4">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Ticket Category<span className="text-red-500">*</span>
                </p>
                <Field name="ticket_category">
                  {({ field, form: { values } }) => (
                    <select
                      {...field}
                      className={`w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border ${errors.ticket_category && touched.ticket_category
                        ? 'border-red-500'
                        : 'border-sunday_secondary'
                        }`}
                    >
                      <option value="">---------</option>
                      <option
                        value="free"
                        disabled={parseFloat(values.ticket_price) > 0}
                        style={{
                          color: parseFloat(values.ticket_price) > 0 ? 'text-gray-300' : 'text-gray-500',
                          cursor: parseFloat(values.ticket_price) > 0 ? 'not-allowed' : 'pointer',
                        }}
                      >
                        Free
                      </option>
                      <option value="basic">Basic</option>
                      <option value="standard">Standard</option>
                      <option value="premium">Premium</option>
                    </select>
                  )}
                </Field>
                {errors.ticket_category && touched.ticket_category && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.ticket_category}
                  </div>
                )}
              </div>


              {/* Ticket Description */}
              <div className="mb-4">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Ticket Description<span className="text-red-500">*</span>
                </p>
                <Field
                  id="ticket_description"
                  name="ticket_description"
                  as="textarea"
                  placeholder="Ticket Description"
                  className={`w-full text-sm p-3 rounded-lg pl-4 font-poppins tracking-wide border ${errors.ticket_description && touched.ticket_description
                    ? 'border-red-500'
                    : 'border-sunday_secondary'
                    }`}
                />
                {errors.ticket_description && touched.ticket_description && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.ticket_description}
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div className="flex justify-center items-center mb-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`h-10 bg-sunday_primary rounded-full cursor-pointer w-36 p-2 font-poppins text-base tracking-wide text-white ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#005bb5]'
                    }`}
                >
                  {isSubmitting ? 'Creating...' : 'Create Ticket'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default CreateTicketForm;
