import React from 'react'
import { Helmet } from 'react-helmet'

function HomeHelmet() {
  return (
    <Helmet>
        <title>EventfullSunday.co.uk : Celebrate Diversity, Connect Communities, Create Memories - Bringing Together in the UK.</title>

        <meta
          name="description"
          content="Promote and manage your events with ease on EventfullSunday.co.uk, the platform that connects communities across the UK. Whether you’re organizing live music, cultural festivals, sports events, or theatre performances, our platform helps you reach the right audience, maximize revenue with dynamic pricing, and ensure seamless ticketing and event management. "
        />

        <meta
          name="keywords"
          content="event organizers, organize events in UK, cultural festivals, live music events, sports event promotion, theatre event management, community events, ticketing platform for organizers, UK event promotion, dynamic pricing for events, grow your event audience"
        />
        <meta property="og:url" content="https://organizer.eventfullsunday.co.uk/" />

        <link rel="canonical" href="https://organizer.eventfullsunday.co.uk/" />
      </Helmet>
  )
}

export default HomeHelmet
