// src/components/EventTickets.jsx

import React from 'react';
import PropTypes from 'prop-types';
import TicketCard from './TicketCard';
import { FaArrowLeft } from 'react-icons/fa';

const EventTickets = ({
  event,
  tickets,
  loadingTickets,
  onEditTicket,
  onCreateTicket,
  onBack,
}) => {
  return (
    <div className="bg-white p-6 mb-4 rounded-lg shadow-lg w-full xl:w-[90%]">
      <button
        onClick={onBack}
        className="flex items-center mt-2 mb-4 text-xl text-sunday_info hover:text-sunday_primary duration-100 transition"
      >
        <FaArrowLeft className="mr-2" /> Back
      </button>
      <h2 className="text-lg font-semibold mb-4">{event.event_name} - Tickets</h2>
      {/* Tickets for this Event */}
      <div className="mb-3">
        {loadingTickets ? (
          <p>Loading tickets...</p>
        ) : tickets && tickets.length > 0 ? (
          <div className="space-y-2">
            {tickets.map((ticket) => (
              <TicketCard key={ticket.id} ticket={ticket} onEdit={onEditTicket} />
            ))}
          </div>
        ) : (
          <p className="text-gray-500">No tickets available for this event.</p>
        )}
      </div>
      {/* Action Buttons */}
      <div className="flex space-x-2 mt-auto">
        <button
          onClick={() => onCreateTicket(event.event_id)}
          className="text-blue-500 hover:text-blue-700 underline"
        >
          Create Tickets
        </button>
      </div>
    </div>
  );
};

EventTickets.propTypes = {
  event: PropTypes.object.isRequired,
  tickets: PropTypes.array.isRequired,
  loadingTickets: PropTypes.bool.isRequired,
  onEditTicket: PropTypes.func.isRequired,
  onCreateTicket: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default EventTickets;
