import {
    PASSWORD_UPDATE_REQUEST,
    PASSWORD_UPDATE_SUCCESS,
    PASSWORD_UPDATE_FAILURE
} from '../constant/ResetConstant';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiClient from '../../../apiClient/apiClient';

export const resetPassword = (uid, token, newPassword, confirmNewPassword) => async (dispatch) => {
    try {
        dispatch({ type: PASSWORD_UPDATE_REQUEST });

        console.log(uid, token, newPassword, confirmNewPassword);

        const { data } = await apiClient.post(`/password-reset-confirm/${uid}/${token}/`, {
            uid: uid,
            token: token,
            new_password: newPassword,
            confirm_new_password: newPassword,
        });

        dispatch({ type: PASSWORD_UPDATE_SUCCESS, payload: data });

        toast.success(<p className="m-2">You've successfully updated your password, you'll be redirected to the login page shortly.</p>);
        
        setTimeout(() => {
            window.location.href = "/login"; // Redirect to the provided URL
        }, 3000);
    } catch (error) {

        toast.error(<p className="m-2">This reset password link has expired. Please request a new one.</p>);

        dispatch({
            type: PASSWORD_UPDATE_FAILURE,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};
