import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import user from "../../assets/site-images/user.png";
import logo from "../../assets/site-images/eventfullsunday.png";
import LeftSection from "../../components/Layout/LeftSection/LeftSection"
import { useDispatch } from 'react-redux';
import { sendResetPassword } from '../../redux/auth/action/ForgotPasswordAction';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmailConfirmation = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email'); // Get the email from query parameters

    const [isCooldown, setIsCooldown] = useState(false);

    const handleResendConfirmationEmail = () => {
        if (email && !isCooldown) {    
            dispatch(sendResetPassword(email, false));
            toast.success(<p className="m-2">You've successfully requested a new password reset email.</p>);
            setIsCooldown(true);
        }
        if (!email) {
            toast.error(<p className="m-2">No email provided. Please go back to the "Forgot Password" page and try again.</p>);
        }
    };

    useEffect(() => {
        let timer;
        if (isCooldown) {
            timer = setTimeout(() => {
                setIsCooldown(false);
            }, 10000); // 10 seconds cooldown
        }
        return () => clearTimeout(timer); // Cleanup on unmount
    }, [isCooldown]);

    return (
        <div className="flex flex-col lg:flex-row h-screen">
            <ToastContainer position="top-right" autoClose={3000}/>
            
            {/* Left Section */}
            <LeftSection
                logo={logo}
                mainImage={user}
                mainText="Eventfull Sundays"
            />

            {/* Right Section: Email Confirmation Message */}
            <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8">
                <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-md">
                    <div className="text-center">
                        <p className="text-2xl font-semibold mb-6">Password Reset Confirmation</p>
                        <p className="text-lg text-gray-700 mb-4">
                            We have sent a password reset link to your email. Please check your inbox and click the link to reset your password.
                        </p>
                        <p className="text-sm text-gray-600 mb-6">
                            If you did not receive the email, please check your spam folder or try again.
                        </p>
                        <div className="mb-4">
                            <Link
                                to="/login"
                                className="block p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 w-full text-center"
                            >
                                Go to Login
                            </Link>
                        </div>
                        <div className="text-sm text-center">
                            <p>
                                Still didn’t get the email?
                                <button
                                    onClick={handleResendConfirmationEmail}
                                    className={`text-blue-600 underline ml-1 ${isCooldown ? 'cursor-not-allowed text-gray-400' : ''}`}
                                    disabled={isCooldown} // Disable button during cooldown
                                >
                                    Resend Confirmation Email
                                </button>
                            </p>
                            {isCooldown && (
                                <p className="text-red-500 text-xs mt-2">You can request a new email in 10 seconds.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailConfirmation;
