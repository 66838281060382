// Shimmer.jsx
import React from 'react';

const Shimmer = ({ 
  width = '100%',    
  height = '100%',   
  borderRadius = 'rounded-md', 
  className = '' 
}) => {
  return (
    <div
      className={`relative overflow-hidden bg-gray-300 ${borderRadius} ${className}`}
      style={{ width, height }}
    >
      <div className="absolute inset-0 w-full h-full animate-shimmer bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300"></div>
    </div>
  );
};

export default Shimmer;
