import { ErrorMessage, Field } from 'formik';
import React, { useState } from 'react';

function TicketPriceField({ values, setFieldValue }) {
  const [displayMinValue, setDisplayMinValue] = useState(values.min_ticket_price ? `£${values.min_ticket_price}` : "");
  const [displayMaxValue, setDisplayMaxValue] = useState(values.max_ticket_price ? `£${values.max_ticket_price}` : "");

  const handleMinChange = (e) => {
    let inputValue = e.target.value.replace("£", "");
    // Limit input value
    if (Number(inputValue) > 10000) {
      inputValue = "10000";
    }
    setDisplayMinValue(`£${inputValue}`);
    setFieldValue("min_ticket_price", inputValue);
  };

  const handleMaxChange = (e) => {
    let inputValue = e.target.value.replace("£", "");
    // Limit input value
    if (Number(inputValue) > 10000) {
      inputValue = "10000";
    }
    setDisplayMaxValue(`£${inputValue}`);
    setFieldValue("max_ticket_price", inputValue);
  };

  return (
    <div className="mb-4">
      <p className="font-medium text-base tracking-wider">
        Ticket Price Range <span className="text-red-500">*</span>
      </p>
      <div className="flex gap-2 w-full">
        <div className="flex flex-col">
          <label htmlFor="min_ticket_price" className="text-sm">Min Price</label>
          <Field
            name="min_ticket_price"
            placeholder="£0.00"
            className="w-24 text-center text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
            value={displayMinValue}
            onChange={handleMinChange}
          />

        </div>
        <div className="flex flex-col">
          <label htmlFor="max_ticket_price" className="text-sm">Max Price</label>
          <Field
            name="max_ticket_price"
            placeholder="£0.00"
            className="w-24 text-center text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
            value={displayMaxValue}
            onChange={handleMaxChange}
          />

        </div>
        {/* Optional Free Ticket Button */}
        <Field
          type="button"
          name="free_ticket"
          value="Free Ticket"
          className={`w-32 text-center text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-6 transition duration-150 hover:brightness-95 ${values.min_ticket_price === "0" && values.max_ticket_price === "0"
            ? "bg-blue-100"
            : "bg-white"
            }`}
          onClick={() => {
            if (values.min_ticket_price !== "0" || values.max_ticket_price !== "0") {
              setFieldValue("min_ticket_price", "0");
              setDisplayMinValue("£0.00");
              setFieldValue("max_ticket_price", "0");
              setDisplayMaxValue("£0.00");
            } else {
              setFieldValue("min_ticket_price", "");
              setDisplayMinValue("");
              setFieldValue("max_ticket_price", "");
              setDisplayMaxValue("");
            }
          }}
        />

      </div>
      <div>
        <ErrorMessage
          name="min_ticket_price"
          component="div"
          className="text-red-500 text-sm ml-2"
        />
        <ErrorMessage
          name="max_ticket_price"
          component="div"
          className="text-red-500 text-sm ml-2"
        />
      </div>
    </div>
  );
}

export default TicketPriceField;
