import { toast } from "react-toastify";
import apiClient from "../../../apiClient/apiClient";
import { EDIT_EVENT_CONSTANT_FAILURE, EDIT_EVENT_CONSTANT_REQUEST, EDIT_EVENT_CONSTANT_SUCCESS } from "./EditEventConstant"



export const editEventCreate = (id, data) => async (dispatch) => {
  dispatch({ type: EDIT_EVENT_CONSTANT_REQUEST });
  try {

    // Display the key/value pairs
    for (var pair of data.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }


    const token = localStorage.getItem('access') || sessionStorage.getItem('access');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': "multipart/form-data",
      },
    };
    const response = await apiClient.put(`api/events/${id}/`, data, config);
    dispatch({
      type: EDIT_EVENT_CONSTANT_SUCCESS,
      payload: response.data,
    });

    if (!toast.isActive(1, "createEventForm")) {
      toast.success("Event updated successfully!", {
        toastId: 5
      })
    }

    setTimeout(() => {
      window.location.href = '/';
    }, 2000);

  } catch (error) {
    dispatch({
      type: EDIT_EVENT_CONSTANT_FAILURE,
      payload: error.message,
    });

    if (!toast.isActive(1, "editEventForm")) {
      toast.error(`${error.message}`, {
        toastId: 1
      })
    }

  }
};