// src/components/pages/OrganizerRegister.jsx

import React, { useState } from "react";
import organizer from "../../assets/site-images/organizer.png"; // Unique image for Organizer End
import logo from '../../assets/site-images/eventfullsunday.png'; // Organizer-specific logo if needed
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  IoPersonCircle,
  IoEyeOutline,
  IoEyeOffOutline,
  IoLockClosedOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { LiaUserCogSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/auth/action/RegisterAction";
// Import additional actions if needed
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import LeftSection from '../../components/Layout/LeftSection/LeftSection';
import ReactGA from 'react-ga4';
import PhoneInput from 'react-phone-number-input'

const OrganizerRegister = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const dispatch = useDispatch();

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword(!confirmPassword);
  };

  // Handle form submission
  const handleRegister = (values, { setSubmitting }) => {
    // Transform values if necessary, e.g., handle optional fields
    const transformedValues = {
      ...values,
      phone_number: values.phone_number === "" ? null : values.phone_number
    };
    console.log("Registering Organizer:", transformedValues);

    dispatch(registerUser(transformedValues))
      .then(() => {
        // Fire GA event for successful registration
        ReactGA.event({
          category: 'Organizer',
          action: 'Registered',
        });
      })
      .catch((error) => {
        // Handle registration errors
        console.error("Registration Error:", error);
        //toast.error("Registration failed. Please try again.");
      });
    setSubmitting(false);
  };

  // Form validation schema with additional fields
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^\+?[1-9]\d{9,14}$/, "Phone number must be between 10 and 15 digits, starting with a '+'")
      .required("Phone number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    role: Yup.string().required("Role is required"),
  });

  return (
    <>
      <ToastContainer
        position="top-right"
        containerId="register"
        autoClose={5000}
        style={{ position: 'fixed', top: '1rem', right: '1rem', zIndex: 1000 }}
      />
      <div className="flex flex-col lg:flex-row min-h-screen">
        {/* Left Section */}
        <LeftSection
          logo={logo} // Organizer-specific logo
          mainImage={organizer} // Organizer-specific main image
          mainText="Welcome to the Organizer Portal" // Organizer-specific text
        />

        {/* Right Section: Register Form */}
        <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8 fade-in-bottom">
          <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-md overflow-y-auto">
            <Formik
              initialValues={{
                full_name: "",
                email: "",
                phone_number: "",
                password: "",
                confirm_password: "",
                role: "", // Additional field
              }}
              validationSchema={validationSchema}
              onSubmit={handleRegister}
            >
              {({ values, isSubmitting, errors, touched, setFieldValue }) => (
                <Form className="w-full">
                  {/* Form Title */}
                  <p className="text-2xl font-semibold text-center mb-6">Register</p>

                  {/* Full Name Field */}
                  <div className="mb-4">
                    <label htmlFor="full_name" className="block mb-2 text-sm font-medium">Full Name</label>
                    <div className="relative">
                      <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                      <Field
                        id="full_name"
                        name="full_name"
                        type="text"
                        placeholder="Joshuwa Mason"
                        className={`w-full p-2 pl-10 border rounded-full focus:outline-none focus:ring-2 ${errors.full_name && touched.full_name ? 'border-red-500 focus:ring-red-500' : 'border-blue-600 focus:ring-blue-600'
                          }`}
                      />
                    </div>
                    <ErrorMessage name="full_name" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Email Field */}
                  <div className="mb-4">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium">Email</label>
                    <div className="relative">
                      <MdOutlineMailOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                      <Field
                        type="email"
                        name="email"
                        placeholder="joshuwamason@gmail.com"
                        className={`w-full p-2 pl-10 border rounded-full focus:outline-none focus:ring-2 ${errors.email && touched.email ? 'border-red-500 focus:ring-red-500' : 'border-blue-600 focus:ring-blue-600'
                          }`}
                      />
                    </div>
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Phone Number Field */}
                  <div className="mb-4">
                    <label htmlFor="phone_number" className="block mb-2 text-sm font-medium">Phone Number</label>
                    <div className="relative flex items-center">
                      {/*<IoCallOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <span className="absolute left-10 top-1/2 transform -translate-y-1/2 text-gray-500">
                      +44
                    </span>
                    <Field
                      type="tel"
                      name="phone_number"
                      placeholder="075********"
                      className={`w-full p-2 pl-16 border rounded-full focus:outline-none focus:ring-2 ${errors.phone_number && touched.phone_number ? 'border-red-500 focus:ring-red-500' : 'border-blue-600 focus:ring-blue-600'
                        }`}
                      style={{ paddingLeft: '70px' }}
                    />*/}
                      <PhoneInput
                        placeholder="Phone Number"
                        defaultCountry="GB"
                        value={values.phone_number}
                        international
                        countryCallingCodeEditable={false}
                        onChange={phone => setFieldValue('phone_number', phone)}
                        className={`w-full p-2 border rounded-full focus:outline-none focus:ring-2 ${errors.phone_number && touched.phone_number ? 'border-red-500 focus:ring-red-500' : 'border-blue-600 focus:ring-blue-600'
                          }`} />
                    </div>
                    <ErrorMessage name="phone_number" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Password Field */}
                  <div className="mb-4">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium">Password</label>
                    <div className="relative">
                      <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Iampassword123"
                        className={`w-full p-2 pl-10 border rounded-full focus:outline-none focus:ring-2 ${errors.password && touched.password ? 'border-red-500 focus:ring-red-500' : 'border-blue-600 focus:ring-blue-600'
                          }`}
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="text-gray-500 cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2 focus:outline-none"
                      >
                        {showPassword ? (
                          <IoEyeOffOutline className="w-5 h-5" />
                        ) : (
                          <IoEyeOutline className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Confirm Password Field */}
                  <div className="mb-4">
                    <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium">Confirm Password</label>
                    <div className="relative">
                      <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                      <Field
                        type={confirmPassword ? "text" : "password"}
                        name="confirm_password"
                        placeholder="Iampassword123"
                        className={`w-full p-2 pl-10 border rounded-full focus:outline-none focus:ring-2 ${errors.confirm_password && touched.confirm_password ? 'border-red-500 focus:ring-red-500' : 'border-blue-600 focus:ring-blue-600'
                          }`}
                      />
                      <button
                        type="button"
                        onClick={toggleConfirmPasswordVisibility}
                        className="text-gray-500 cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2 focus:outline-none"
                      >
                        {confirmPassword ? (
                          <IoEyeOffOutline className="w-5 h-5" />
                        ) : (
                          <IoEyeOutline className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                    <ErrorMessage name="confirm_password" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Role Field (Additional for Organizer End) */}
                  <div className="mb-4">
                    <label htmlFor="role" className="block mb-2 text-sm font-medium">Role</label>
                    <div className="relative">
                      <LiaUserCogSolid className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                      <Field
                        as="select"
                        id="role"
                        name="role"
                        className={`w-full p-2 pl-10 pr-12 border rounded-full focus:outline-none focus:ring-2 ${errors.role && touched.role ? 'border-red-500 focus:ring-red-500' : 'border-blue-600 focus:ring-blue-600'
                          } appearance-none bg-transparent`}
                      >
                        <option value="" label="Select role" />
                        <option value="organizer" label="Organizer" />
                        <option value="user" label="User" />
                      </Field>
                      {/* Custom Dropdown Arrow */}
                      <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                        <IoChevronDownOutline className="w-5 h-5 text-gray-500" />
                      </div>
                    </div>
                    <ErrorMessage name="role" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Register Button */}
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="w-full p-3 bg-sunday_primary text-white rounded-full hover:brightness-95 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-600"
                      disabled={isSubmitting}
                    >
                      Register
                    </button>
                  </div>

                  {/* Divider */}
                  <div className="flex items-center my-4">
                    <hr className="flex-grow border-t border-gray-300" />
                  </div>

                  {/* Login Link */}
                  <div className="text-center">
                    <p className="text-sm">
                      Already have an account?{" "}
                      <Link to="/login" className="text-blue-600 underline">
                        Login Here
                      </Link>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizerRegister;
