// src/redux/reducers/purchaseLogsReducer.js

import {
    FETCH_PURCHASE_LOGS_REQUEST,
    FETCH_PURCHASE_LOGS_SUCCESS,
    FETCH_PURCHASE_LOGS_FAILURE,
} from '../constant/purchaseLogsConstants';

const initialState = {
    loading: false,
    error: null,
    logs: {}, // Structure: { [eventId]: { event_summary, tickets } }
};

/**
 * Reducer to handle purchase logs state.
 * @param {Object} state - Current state.
 * @param {Object} action - Dispatched action.
 */
export const purchaseLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PURCHASE_LOGS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_PURCHASE_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                logs: {
                    ...state.logs,
                    [action.payload.eventId]: action.payload.data,
                },
            };
        case FETCH_PURCHASE_LOGS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};
