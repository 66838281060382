// src/redux/createEvent/EventDetailAction.jsx

import apiClient from "../../../apiClient/apiClient";
import {
  GET_CREATE_EVENT_DETAILS_FAILURE,
  GET_CREATE_EVENT_DETAILS_REQUEST,
  GET_CREATE_EVENT_DETAILS_SUCCESS,
  RESET_EVENT_DETAILS,
} from "../createConstant/EventDetailConstant";

// Action to reset event details
export const resetEventDetails = () => ({
  type: RESET_EVENT_DETAILS,
});

// Action to fetch event details along with category and subcategory names
export const EventGetItems = (id) => async (dispatch) => {
  dispatch({
    type: GET_CREATE_EVENT_DETAILS_REQUEST,
  });

  try {
    
    // Fetch event details
    const eventResponse = await apiClient.get(`/events/${id}/`);

    // Extract the category ID from the event response
    const categoryId = eventResponse.data.category;

    // Fetch category details based on category ID
    const categoryResponse = await apiClient.get(`/category/${categoryId}/`);

    const categoryName = categoryResponse.data.category_name;

    // Initialize modifiedResponse with event data and category_name
    const modifiedResponse = {
      ...eventResponse.data,
      category_name: categoryName, // Add the category_name key
    };


    // Check if the event has a subcategory_id
    if (eventResponse.data.subcategory) {
      // Fetch subcategory details based on subcategory_id
      const subcategoryResponse = await apiClient.get(
        `/sub-category/${eventResponse.data.subcategory}/`
      );

      const subcategoryName = subcategoryResponse.data.name;

      // Add subcategory_name to modifiedResponse
      modifiedResponse.subcategory_name = subcategoryName;
    }

    // console.log(modifiedResponse);
    

    // Dispatch success action with the modifiedResponse
    dispatch({
      type: GET_CREATE_EVENT_DETAILS_SUCCESS,
      payload: modifiedResponse,
    });
  } catch (error) {
    // Handle errors and dispatch failure action
    dispatch({
      type: GET_CREATE_EVENT_DETAILS_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};
