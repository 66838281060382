import React from 'react'
import { Link } from 'react-router-dom'

function AuthLinks() {
  return (
    <div className="flex items-center space-x-3 ms-2">
      <a
        href="https://events.eventfullsunday.co.uk/"
        target="_blank"
        rel="noopener noreferrer"
        className="font-poppins text-base text-black font-semibold"
      >
        ORGANIZER GUIDE
      </a>
      <Link
        to="/register"
        className="font-poppins text-base text-black font-semibold"
      >
        SIGNUP
      </Link>
      <Link
        to="/login"
        className="font-poppins text-base text-black font-semibold"
      >
        LOGIN
      </Link>
    </div>
  )
}

export default AuthLinks
