// src/redux/actions/purchaseLogsActions.js

import apiClient from '../../../apiClient/apiClient';
import {
    FETCH_PURCHASE_LOGS_REQUEST,
    FETCH_PURCHASE_LOGS_SUCCESS,
    FETCH_PURCHASE_LOGS_FAILURE,
} from '../constant/purchaseLogsConstants';

/**
 * Action to fetch purchase logs for a specific event.
 * @param {string} eventId - The ID of the event.
 */
export const fetchPurchaseLogs = (eventId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PURCHASE_LOGS_REQUEST });

        const response = await apiClient.get(`api/check-in/logs/${eventId}`);

        dispatch({
            type: FETCH_PURCHASE_LOGS_SUCCESS,
            payload: { eventId, data: response.data },
        });
    } catch (error) {
        dispatch({
            type: FETCH_PURCHASE_LOGS_FAILURE,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};
