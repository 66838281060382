// src/components/pages/Profile.jsx

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUserProfile,
  sendResetVerificationEmail,
} from "../../../../redux/auth/action/userProfileActions";
import { fetchOrganiserEvents } from "../../../../redux/OrganiserEvent/organiserEventAction/GetOrganiserEventAction";
import { fetchAllTickets, resetTicketUpdate } from "../../../../redux/tickets/ticketActions";
import { VscUnverified, VscVerified } from "react-icons/vsc";
import ProfileUpdateModal from "./ProfileUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import EventsList from "./YourEventsSection/EventsList";
import AdditionalInfo from "./AdditionalInfo";
import ProfileInfo from "./ProfileInfo";
import Shimmer from "../../../Layout/Shimmer";
import { FaUser, FaBriefcase, FaMapMarkerAlt, FaInfoCircle } from "react-icons/fa";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux State Selectors
  const profileState = useSelector((state) => state.profile);
  const resetVerificationState = useSelector((state) => state.resetVerification);

  // Local State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [hasDisplayedToast, setHasDisplayedToast] = useState(true);

  // Fetch profile data and all tickets on mount and when cooldown changes
  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(fetchAllTickets());

    let timer;
    if (isCooldown) {
      timer = setTimeout(() => {
        setIsCooldown(false);
      }, 10000); // 10 seconds cooldown
    }
    return () => clearTimeout(timer); // Cleanup on unmount
  }, [dispatch, isCooldown]);

  // Fetch user's events when profile data is available
  useEffect(() => {
    if (profileState && profileState.profile && profileState.profile.id) {
      dispatch(fetchOrganiserEvents(profileState.profile.id));
    }
  }, [dispatch, profileState]);

  // Handle verification email toast
  useEffect(() => {
    if (
      resetVerificationState.data &&
      !resetVerificationState.loading &&
      !hasDisplayedToast
    ) {
      console.log(resetVerificationState.data);

      if (!toast.isActive(1, "profileComponent")) {
        toast.success(
          "You've successfully requested an account verification email!", {
          toastId: 1
        }
        );
      }

      setHasDisplayedToast(true);
    }
    if (
      resetVerificationState.error &&
      !hasDisplayedToast
    ) {
      if (!toast.isActive(2, "profileComponent")) {
        toast.error(
          "There was a problem with sending a verification email. Please try again later.", {
          toastId: 2
        }
        );
      }
      setHasDisplayedToast(true);
    }
  }, [
    resetVerificationState.data,
    resetVerificationState.error,
    resetVerificationState.loading,
    hasDisplayedToast,
  ]);

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!localStorage.getItem("access") && !sessionStorage.getItem("access")) {
      navigate("/login");
      dispatch(resetTicketUpdate()); // Reset the success flag after handling
    }
  }, [dispatch, navigate]);

  const {
    full_name,
    email,
    is_verified,
    company_name,
    description,
    business_email,
    business_phone,
    flat_house_number,
    street_address,
    city_town,
    county,
    company_logo,
    country,
    post_code,
  } = profileState.profile || {};

  // Helper function to safely assign values or default to empty string
  const safeValue = (value) => (value === null || value === undefined ? "" : value);

  const initialValuesFull = {
    full_name: safeValue(full_name),
    email: safeValue(email),
    phone_number: safeValue(profileState?.profile?.phone_number),
    company_name: safeValue(company_name),
    description: safeValue(description),
    business_email: safeValue(business_email),
    business_phone: safeValue(business_phone),
    flat_house_number: safeValue(flat_house_number),
    street_address: safeValue(street_address),
    city_town: safeValue(city_town),
    county: safeValue(county),
    post_code: safeValue(post_code),
    country: safeValue(country),
    company_logo: company_logo || "",
  };

  // Function to close the profile update modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Function to resend verification email
  const handleResendVerificationEmail = () => {
    dispatch(sendResetVerificationEmail(email));
    setHasDisplayedToast(false);
    setIsCooldown(true);
  };

  // Function to determine verification status
  const getVerifiedStatus = () => {
    if (profileState.loading) {
      return <span className="text-gray-500">Loading...</span>;
    } else {
      if (is_verified === true) {
        return (
          <div className="flex items-center px-3 py-1 bg-green-50 rounded-full">
            <span className="text-green-600 text-sm font-medium">Verified</span>
            <VscVerified size={16} className="ms-1 text-green-600" />
          </div>
        );
      } else if (is_verified === false) {
        return (
          <div className="flex flex-col items-center gap-1">
            <button
              onClick={handleResendVerificationEmail}
              disabled={isCooldown}
              title="Click to resend a verification link to your email"
              className={`flex items-center px-3 py-1 rounded-full ${
                isCooldown 
                  ? "bg-gray-50 cursor-not-allowed" 
                  : "bg-red-50 hover:bg-red-100"
              }`}
            >
              <span className={`text-sm font-medium ${
                isCooldown ? "text-gray-500" : "text-red-600"
              }`}>
                Unverified
              </span>
              <VscUnverified size={16} className="ms-1" />
            </button>
            {isCooldown && (
              <span className="text-red-500 text-xs">
                Wait 10s to resend
              </span>
            )}
          </div>
        );
      } else {
        return <span className="text-gray-500">N/A</span>;
      }
    }
  };

  return (
    <div className="max-w-7xl mx-auto">
      <ToastContainer position="top-right" containerId="profileComponent" autoClose={3000} />

      {!profileState.profile && (
        <Shimmer width="100%" height="290px" borderRadius="rounded-md" />
      )}

      {profileState.profile && (
        <>
          {/* Header Section */}
          <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6 mb-6">
            <div className="flex flex-col sm:flex-row gap-6">
              {/* Left Column: Profile Image and Verification */}
              <div className="flex flex-col items-center sm:items-start gap-3">
                {company_logo ? (
                  <img
                    src={company_logo}
                    alt="Profile"
                    className="w-20 h-20 sm:w-24 sm:h-24 rounded-full object-cover border-4 border-sunday_primary"
                  />
                ) : (
                  <div className="w-20 h-20 sm:w-24 sm:h-24 rounded-full bg-gray-200 flex items-center justify-center">
                    <span className="text-2xl sm:text-3xl text-gray-400">
                      {full_name?.charAt(0)?.toUpperCase()}
                    </span>
                  </div>
                )}
                <div className="flex items-center justify-center">
                  {getVerifiedStatus()}
                </div>
              </div>
              
              {/* Right Column: Profile Info and Buttons */}
              <div className="flex-grow">
                {/* Profile Info */}
                <div className="text-center sm:text-left mb-4">
                  <h1 className="text-xl sm:text-2xl font-bold text-gray-800">{full_name}</h1>
                  <p className="text-gray-600 text-sm sm:text-base">{email}</p>
                  {company_name && (
                    <p className="text-sunday_primary font-medium mt-1 text-sm sm:text-base">{company_name}</p>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="flex flex-row justify-center sm:justify-start gap-2 sm:gap-3">
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="px-3 py-1.5 sm:px-4 sm:py-2 bg-sunday_primary text-white text-sm rounded-md hover:bg-sunday_secondary transition duration-200 w-auto"
                  >
                    Update Profile
                  </button>
                  <button
                    onClick={() => navigate("/forgotpassword")}
                    className="px-3 py-1.5 sm:px-4 sm:py-2 border border-gray-300 text-gray-700 text-sm rounded-md hover:bg-gray-50 transition duration-200 w-auto"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Personal Information Card */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <FaUser className="mr-2 text-sunday_primary" />
                Personal Information
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="text-sm text-gray-500">Full Name</label>
                  <p className="font-medium">{full_name}</p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Email</label>
                  <p className="font-medium">{email}</p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Phone</label>
                  <p className="font-medium">{profileState?.profile?.phone_number || 'Not provided'}</p>
                </div>
              </div>
            </div>

            {/* Business Information Card */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <FaBriefcase className="mr-2 text-sunday_primary" />
                Business Details
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="text-sm text-gray-500">Company Name</label>
                  <p className="font-medium">{company_name || 'Not provided'}</p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Business Email</label>
                  <p className="font-medium">{business_email || 'Not provided'}</p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Business Phone</label>
                  <p className="font-medium">{business_phone || 'Not provided'}</p>
                </div>
              </div>
            </div>

            {/* Address Information Card */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <FaMapMarkerAlt className="mr-2 text-sunday_primary" />
                Address
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="text-sm text-gray-500">Street Address</label>
                  <p className="font-medium">
                    {flat_house_number && `${flat_house_number}, `}
                    {street_address}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">City & County</label>
                  <p className="font-medium">
                    {city_town}
                    {county && `, ${county}`}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Post Code & Country</label>
                  <p className="font-medium">
                    {post_code}
                    {country && `, ${country}`}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Description Card - Full Width */}
          {description && (
            <div className="bg-white rounded-lg shadow-sm p-6 mt-6">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <FaInfoCircle className="mr-2 text-sunday_primary" />
                About
              </h2>
              <p className="text-gray-700 whitespace-pre-wrap">{description}</p>
            </div>
          )}

          {/* Profile Update Modal */}
          <ProfileUpdateModal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            initialValues={initialValuesFull}
          />
        </>
      )}
    </div>
  );
};

export default Profile;
