import { combineReducers } from "redux";
import loginReducer from "./auth/reducer/LoginReducer";
import registerReducer from "./auth/reducer/RegisterReducer";
import passwordReducer from "./auth/reducer/ForgotPasswordReducer";
import { profileReducer, sendResetVerificationEmailReducer } from "./auth/reducer/ProfileReducer";
import organizationReducer from "./auth/reducer/OrganizationProfileReducer";
import passwordResetReducer from "./auth/reducer/ResetReducer";
// import CreateCategoryReducer from "./createEvent/CreateCategoryReducer/CreateCategoryReducer";
// import CreateSubCategoryReducer from "./createEvent/CreateSubCategoryReducer/CreateSubCategoryReducer";
// import subCategoryReducer from "./createEvent/CreateSubCategoryReducer/CreateSubCategoryReducer";
import getCreateEventReducer from "./createEvent/createEventReducer/GetCreateEventReducer";
import eventReducer from "./createEvent/createEventReducer/EventDetailReducer";

import createEventReducer from "./createEvent/EditEvent/EditEventReducer";
import getOrganiserEventReducer from "./OrganiserEvent/organiserEventReducer/GetOrganiserEventReducer";
import createTicketReducer from "./CreateTicket/ticketReducer/createTicketReducer";
import getEventTicketReducer from "./CreateTicket/ticketReducer/getTicketReducer";
import googleAuthReducer from "./auth/reducer/googleLoginReducer";
import activationLinkReducer from "./auth/reducer/ResendLinkReducer";
import categoryReducer from "./auth/reducer/categoryReducer";
import subCategoryReducer from "./auth/reducer/subCategoryReducer";
import { subscriptionReducer } from "./auth/reducer/SubscriptionReducer";
import { ticketFetchAllReducer, ticketFetchDetailsReducer, ticketDeleteReducer, ticketUpdateReducer } from "./tickets/ticketReducer";
import { fetchTicketReducer } from "./auth/reducer/fetchTicketsReducer";
import selectedTicketReducer from "./auth/reducer/selectedTicketReducer";
import filtersReducer from "./auth/reducer/filtersReducer";
import { purchaseLogsReducer } from "./auth/reducer/purchaseLogsReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  register: registerReducer,
  // event:eventReducer
  profile: profileReducer,
  resetVerification: sendResetVerificationEmailReducer,
  organization: organizationReducer,
  getEvent: getCreateEventReducer,
  eventItems: eventReducer,
  update: createEventReducer,
  getOrganiserEvent: getOrganiserEventReducer,
  addTicket: createTicketReducer,
  getTickets: getEventTicketReducer,
  googleAuth: googleAuthReducer,
  resendLink: activationLinkReducer,
  passwordForgot: passwordReducer,
  passwordResetDetail: passwordResetReducer,
  category: categoryReducer,
  subcategory: subCategoryReducer,
  subscriptionDetail: subscriptionReducer,
  tickets: ticketFetchAllReducer, // For fetching all tickets
  ticketDetails: ticketFetchDetailsReducer, // For fetching ticket details
  ticketDelete: ticketDeleteReducer, // For deleting a ticket
  ticketUpdate: ticketUpdateReducer, // For updating a ticket
  ticketsByEventId: fetchTicketReducer, // This is written as "fetchTicket: fetchTicketReducer" in user end
  selectedTicket: selectedTicketReducer,
  filters: filtersReducer,
  purchaseLogs: purchaseLogsReducer,
});

export default rootReducer;
