// Profile.jsx
import React from "react";

const Updates = () => {
  return (
    <div className="w-full bg-white p-6 rounded-lg shadow-lg">
      <div className="flex flex-col items-center">
        <h2 className="text-lg font-semibold">This page is still work in progress!</h2>

      </div>
    </div>
  );
};

export default Updates;
