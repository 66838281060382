// src/components/CreateEventForm.jsx

import React, { useEffect, useState, useRef, useCallback } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { format, startOfToday } from "date-fns";
import { createEvent } from "../../../redux/createEvent/createEventAction/createEventAction";
import { fetchSubcategories } from "../../../redux/auth/action/subCategoryActions";
import { fetchCategories } from "../../../redux/auth/action/categoryActions";
import { getProfile } from "../../../redux/auth/action/Profile";
import { FaArrowLeft } from "react-icons/fa";
import Navbar from "../../Layout/Navbar/Navbar";
import Footer from "../../Layout/Footer/Footer";
import "react-quill/dist/quill.snow.css";
import ReactGA from 'react-ga4';
import Shimmer from "../../Layout/Shimmer"
import EventImageSection from "./EventImageSection";
import CountrySelectField from "./EventDetailFields/CountrySelectField";
import VenueSelectField from "./EventDetailFields/VenueSelectField";
import TicketPriceField from "./EventDetailFields/TicketPriceField";
import DescriptionField from "./EventDetailFields/DescriptionField";
import EventSegmentField from "./EventDetailFields/EventSegmentField";
import OrganizerCompanyField from "./EventDetailFields/OrganizerCompanyField";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { EventGetItems } from "../../../redux/createEvent/createEventAction/EventDetailAction";
import { editEventCreate } from "../../../redux/createEvent/EditEvent/EditEventAction";
import DatePicker from "react-datepicker";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Main style file
import 'react-date-range/dist/theme/default.css'; // Theme CSS
import { useFormikContext } from 'formik';

const CreateEventForm = () => {

  const { id } = useParams();

  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [showEventSegmentDropdown, setShowEventTypeDropdown] = useState(false); // New state variable 
  const [selectedEventSegment, setSelectedEventSegment] = useState("");
  const countryDropdownRef = useRef(null); // Reference for Country Dropdown
  const eventSegmentDropdownRef = useRef(null); // Reference for Event Type Dropdown
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { data } = useSelector((state) => state.eventItems);
  const [existingThumbnail, setExistingThumbnail] = useState(!!data?.thumbnail_path);

  // Date picker variables
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef(null);
  const dateInputRef = useRef(null);

  // Date picker useEffect
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target) &&
        dateInputRef.current &&
        !dateInputRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fetch event data
  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));
    }
  }, [dispatch, id]);

  // Form validation using Yup
  const validationSchema = Yup.object().shape({
    //thumbnail_path: Yup.mixed().required("Thumbnail image is required"),
    thumbnail_path: Yup.mixed()
      .when(["id", "existingThumbnail"], {
        is: (id, existingThumbnail) => !id && !existingThumbnail, // Only required when creating events or when there is no existing thumbnail
        then: (schema) => schema.required("Thumbnail image is required"),
        otherwise: (schema) => schema.nullable(),
      })
      .test("fileSize", "File size exceeds 5MB", (value) => {
        if (!value) return true; // No new image uploaded
        if (!(value instanceof File)) return true;
        return value.size <= 5 * 1024 * 1024; // 5MB limit
      })
      .test("fileType", "Unsupported File Format", (value) => {
        if (!value) return true; // No new image uploaded
        if (!(value instanceof File)) return true;
        return ["image/jpeg", "image/png", "image/gif"].includes(value.type);
      }),
    event_name: Yup.string().required("Event name is required"),

    organizer_company: Yup.string().when("event_type", ([eventType], schema) => {
      if (eventType !== "Internal") {
        return schema
          .required("Organizer company is required")
      }
    }),
    description: Yup.string().required("Description is required"),
    start_date: Yup.date()
      .nullable()
      .required("Start date is required")
      .min(startOfToday(), "Start date cannot be in the past"),
    end_date: Yup.date()
      .nullable()
      .required("End date is required")
      .min(
        Yup.ref("start_date"),
        "End date cannot be before the start date"
      ),
    start_time: Yup.string().required("Start time is required"),
    end_time: Yup.string().required("End time is required")
    .test("is-after-start-time", "End time must be after start time on the same day", function (value) {
      const { start_time, start_date, end_date } = this.parent;
      if (!start_time || !start_date || !end_date || !value) return true;

      // Only validate if start and end dates are the same
      const isSameDay = start_date.getTime() === end_date.getTime();
      if (!isSameDay) return true;

      // Compare start and end times
      return new Date(`1970-01-01T${start_time}`) < new Date(`1970-01-01T${value}`);
    }),  
    event_origin_country: Yup.string().required("Country is required"),
    category_id: Yup.number()
      .typeError("Category is required")
      .required("Category is required"),
    subcategory_id: Yup.number().nullable(),
    capacity: Yup.number()
      .typeError("Capacity must be a number")
      .required("Capacity is required")
      .integer("Capacity must be an integer")
      .min(1, "Capacity must be at least 1"),

    min_ticket_price: Yup.number()
      .typeError("Minimum ticket price must be a number")
      .required("Minimum ticket price is required")
      .min(0, "Minimum ticket price cannot be negative")
      .max(10000, "Minimum ticket price cannot be more than £10000")
      .test(
        "minLessThanOrEqualMax",
        "Minimum price must be less than or equal to maximum price",
        function (value) {
          const { max_ticket_price } = this.parent;
          if (max_ticket_price !== undefined && value !== undefined && max_ticket_price !== "") {
            return value <= max_ticket_price;
          }
          return true; // Skip validation if max_ticket_price is undefined or empty
        }
      ),
    max_ticket_price: Yup.number()
      .typeError("Maximum ticket price must be a number")
      .required("Maximum ticket price is required")
      .min(0, "Maximum ticket price cannot be negative")
      .max(10000, "Maximum ticket price cannot be more than £10000")
      .test(
        "maxGreaterThanOrEqualMin",
        "Maximum price must be greater than or equal to minimum price",
        function (value) {
          const { min_ticket_price } = this.parent;
          if (min_ticket_price !== undefined && value !== undefined && min_ticket_price !== "") {
            return value >= min_ticket_price;
          }
          return true; // Skip validation if min_ticket_price is undefined or empty
        }
      ),

    line_1: Yup.string().required("Address line 1 is required"),
    postcode: Yup.string().required("Postcode is required"),
    share_link: Yup.string()
      .url("Any external link must be a valid URL")
      .nullable(),
    event_type: Yup.string().required("Event type is required"),
    event_segment: Yup.string().required("Event Segment is required"),

    external_url: Yup.string().when("event_type", ([eventType], schema) => {
      if (eventType === "External") {
        return schema
          .required("External URL is required for selected event type")
          .url("External URL must be a valid URL");
      }
      return schema.url("External URL must be a valid URL").nullable();
    }),
  });

  // Form submission handler
  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    console.log('Submitting values:', values);

    const formData = new FormData();

    // Format dates before appending
    const formattedStartDate = values.start_date
      ? format(values.start_date, "yyyy-MM-dd")
      : "";
    const formattedEndDate = values.end_date
      ? format(values.end_date, "yyyy-MM-dd")
      : "";

    // Add form data to FormData object    
    if (values.category_id) {
      formData.append('category', values.category_id); // Append single category ID
    }

    if (values.subcategory_id) {
      formData.append('subcategory', values.subcategory_id);
    }

    formData.append("event_origin_country", values.event_origin_country);

    if (selectedImage1) {
      formData.append("thumbnail_path", selectedImage1);
    }

    if (selectedImage2) {
      formData.append("extra_image", selectedImage2);
    }

    if (selectedImage3) {
      formData.append("extra_image2", selectedImage3);
    }

    formData.append("event_type", values.event_type);         // New Event Type: Internal/External
    formData.append("event_segment", values.event_segment);

    formData.append("venue_name", values.line_1);
    formData.append("event_name", values.event_name);
    formData.append("organizer_company", values.organizer_company);
    formData.append("description", values.description);
    formData.append("start_date", formattedStartDate);
    formData.append("end_date", formattedEndDate);
    formData.append("start_time", values.start_time);
    formData.append("end_time", values.end_time);
    formData.append("min_ticket_price", values.min_ticket_price);
    formData.append("max_ticket_price", values.max_ticket_price);
    formData.append("capacity", values.capacity);
    formData.append("share_link", values.share_link);
    formData.append("external_url", values.external_url);
    formData.append("venue_address", values.line_2);
    formData.append("venue_post_code", values.postcode);

    // Logging form data
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    if (id) {
      dispatch(editEventCreate(id, formData))
        .then(() => {
          // if (!toast.isActive(1, "createEventForm")) {
          //   toast.success("Event updated successfully!", {
          //     toastId: 6
          //   })
          // }

          setIsSubmitted(true)

        })
        .catch((error) => {
          console.error(error);

          // Handle error and re-enable the button
          setSubmitting(false);

          // Testing handling this in createEventForm.jsx
          // if (!toast.isActive(1, "editEventForm")) {
          //   toast.error("Error updating event.", {
          //     toastId: 1
          //   })
          // }

        })
    } else {
      dispatch(createEvent(formData))
        .then(() => {
          // Testing not resetting the form after submitting for better visuals..
          // resetForm();
          setSelectedImage1(null);
          setSelectedImage2(null);
          setSelectedImage3(null);
          setSelectedCategory("");
          setSelectedSubCategory("");

          // Trying to test handling this in the createEventAction.jsx file
          // if (!toast.isActive(1, "createEventForm")) {
          //   toast.success("Event created successfully!", {
          //     toastId: 5
          //   })
          // }


          // Fire GA event for successful event creation
          ReactGA.event({
            category: 'Event',
            action: 'Create Event',
            label: values.event_name,
          });

          setIsSubmitted(true)

        })
        .catch((error) => {
          console.error(error);

          setIsSubmitted(false)

          // Testing handling this in createEventForm.jsx
          // if (!toast.isActive(1, "editEventForm")) {
          //   toast.error("Error updating event.", {
          //     toastId: 1
          //   })
          // }

        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        countryDropdownRef.current &&
        !countryDropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
      if (
        eventSegmentDropdownRef.current &&
        !eventSegmentDropdownRef.current.contains(event.target)
      ) {
        setShowEventTypeDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  // Handle image uploads
  const onImageChange = useCallback((e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  }, []);

  // Fetch subcategories
  const { subcategories, loading: subcategoryLoading } = useSelector((state) => state.subcategory);
  useEffect(() => {
    dispatch(fetchSubcategories());
  }, [dispatch]);

  // Fetch categories
  const { categories, loading: categoryLoading } = useSelector((state) => state.category);
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const profileState = useSelector((state) => state.profile);
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  // Handle category selection
  const handleCategoryClick = (category, setFieldValue) => {
    const newSelectedCategory = category.category_id === selectedCategory ? null : category.category_id;
    setSelectedCategory(newSelectedCategory);
    setFieldValue("category_id", newSelectedCategory);

    // Reset subcategory selection when category changes
    setSelectedSubCategory(null);
    setFieldValue("subcategory_id", null);
  };

  // Handle subcategory selection
  const handleSubcategoryClick = (subcategory, setFieldValue) => {
    const newSelectedSubcategory = subcategory.subcategory_id === selectedSubCategory ? null : subcategory.subcategory_id;
    setSelectedSubCategory(newSelectedSubcategory);
    setFieldValue("subcategory_id", newSelectedSubcategory);
  };

  // Filter subcategories based on selected category
  const filteredSubcategories = subcategories
    ? subcategories.filter((sub) => sub.category === selectedCategory)
    : [];

  // Set category/sub-category states
  useEffect(() => {
    if (data) {
      setSelectedCategory(id ? data.category : null);
      setSelectedSubCategory(id ? data.subcategory : null);
      // Optionally, set Formik's field values if not handled by initialValues
    }

  }, [data, categories, subcategories, id]);

  // Initial values for Formik
  const initialValues = {
    event_name: id ? data?.event_name : "",
    organizer_company: id ? data?.organizer_company : "",
    event_type: id ? data?.event_type : "",
    event_segment: id ? data?.event_segment : "",
    category_id: id ? data?.category : null,
    subcategory_id: id ? data?.subcategory : null,
    description: id ? data?.description : "",
    start_date: id && data?.start_date ? new Date(data.start_date) : null,
    end_date: id && data?.end_date ? new Date(data.end_date) : null,
    start_time: id ? data?.start_time : "",
    end_time: id ? data?.end_time : "",
    event_origin_country: id ? data?.event_origin_country : "United Kingdom",
    min_ticket_price: id ? data?.min_ticket_price : "",
    max_ticket_price: id ? data?.max_ticket_price : "",
    capacity: id ? data?.capacity : "",
    venue_name: "",
    share_link: id ? data?.share_link : "",
    external_url: id ? data?.external_url : "",
    line_1: id ? data?.venue_name : "",
    post_town: "",
    line_2: id ? data?.venue_address : "",
    postcode: id ? data?.venue_post_code : "",
    thumbnail_path: id? data?.thumbnail_path : null,
    extra_image: id ? data?.extra_image : null,
    extra_image2: id ? data?.extra_image2 : null
  };


  const ValidationLogger = () => {
    const { errors } = useFormikContext();

    useEffect(() => {
      if (errors) {
        console.log('Validation Errors:', errors);
      } else {
        console.log('No validation errors.');
      }
    }, [errors]);

    return null;
  };


  return (
    <div className="w-full h-full font-poppins text-[#565656] bg-[#f2f4f7]">
      <Navbar linkTo="/home" title="New Event Creation" />
      <div className="flex-col mt-5">
        <ToastContainer position="top-right" containerId="createEventForm" autoClose={5000} />
        <div className="w-full min-w-0">
          <div className="flex flex-col w-full">

            {/* Main Form */}
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, setFieldTouched, isSubmitting, errors, touched }) => {

                // // Define dateRange based on Formik's values
                // const dateRange = [
                //   {
                //     startDate: values.start_date || startOfToday(),
                //     endDate: values.end_date || addDays(startOfToday(), 1),
                //     key: 'selection',
                //   },
                // ];


                return (
                  <Form className="flex flex-col justify-center items-center mx-auto sm:w-[622px] lg:w-[800px]">

                    <ValidationLogger />

                    {/* Container for Back Button and Form Content */}
                    <div className="flex w-full px-5">
                      {/* Back Button */}
                      <button
                        onClick={() => navigate("/dashboard", { state: { selectedSection: "manage-events" } })}
                        className="flex items-center text-xl text-sunday_info hover:text-sunday_primary duration-100 transition"
                      >
                        <FaArrowLeft className="mr-2" /> Back
                      </button>

                      {/* Spacer to center the content */}
                      <div className="flex-1"></div>
                    </div>

                    {/* Left Section: Image Uploads */}
                    <EventImageSection
                      selectedImage1={selectedImage1}
                      setSelectedImage1={setSelectedImage1}
                      selectedImage2={selectedImage2}
                      setSelectedImage2={setSelectedImage2}
                      selectedImage3={selectedImage3}
                      setSelectedImage3={setSelectedImage3}
                      onImageChange={onImageChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      existingThumbnail={existingThumbnail}
                      setExistingThumbnail={setExistingThumbnail} />

                    {/* Right Section: Form Fields */}
                    <div className="w-full px-5 pb-5">
                      {/* Event Name */}
                      <div className="mb-4">
                        <p className="font-medium text-base tracking-wider">
                          Name of the Event <span className="text-red-500">*</span>
                        </p>
                        <Field
                          type="text"
                          name="event_name"
                          placeholder="Event title goes here"
                          className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
                        />
                        <ErrorMessage name="event_name" component="div" className="text-red-500 text-sm ml-2" />
                      </div>

                      {/* Organizer Company */}
                      <OrganizerCompanyField profileState={profileState} />

                      {/* Description */}
                      <DescriptionField values={values} setFieldValue={setFieldValue} />

                      {/* Country Selection */}
                      <CountrySelectField
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                        countryDropdownRef={countryDropdownRef}
                        setFieldValue={setFieldValue}
                        values={values}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry} />

                      {/* New Event Type */}
                      <div className="mb-4">
                        <p className="font-medium text-base tracking-wider">
                          Event Type <span className="text-red-500">*</span>
                        </p>
                        <div className="flex flex-wrap gap-2">
                          <button
                            type="button"
                            onClick={() => setFieldValue("event_type", "Internal")}
                            className={`py-2 px-3 m-1 border rounded-full flex justify-center transition duration-200 hover:brightness-90 hover:cursor-pointer ${values.event_type === "Internal"
                              ? "border-white bg-[#0875E1] text-white"
                              : "border-[#0875E1] focus:border-sunday_primary focus:outline-none bg-white text-gray-600"
                              }`}
                          >
                            Internal
                          </button>
                          <button
                            type="button"
                            onClick={() => setFieldValue("event_type", "External")}
                            className={`py-2 px-3 m-1 border rounded-full flex justify-center transition duration-200 hover:brightness-90 hover:cursor-pointer ${values.event_type === "External"
                              ? "border-white bg-[#0875E1] text-white"
                              : "border-[#0875E1] focus:border-sunday_primary focus:outline-none bg-white text-gray-600"
                              }`}
                          >
                            External
                          </button>
                        </div>
                        <ErrorMessage name="event_type" component="div" className="text-red-500 text-sm ml-2" />
                      </div>

                      {/* Event Segment */}
                      <EventSegmentField
                        eventSegmentDropdownRef={eventSegmentDropdownRef}
                        showEventSegmentDropdown={showEventSegmentDropdown}
                        setFieldValue={setFieldValue}
                        profileState={profileState}
                        setShowEventTypeDropdown={setShowEventTypeDropdown}
                        selectedEventSegment={selectedEventSegment}
                        setSelectedEventSegment={setSelectedEventSegment} />

                      {/* Event Category Selection */}
                      <div className="mb-3">
                        <p className={`font-medium text-base tracking-wider ${filteredSubcategories.length ? "mb-0" : "mb-2"}`}>
                          Pick an Event Category <span className="text-red-500">*</span>
                        </p>
                        <div className="flex flex-wrap gap-2">

                          {(categoryLoading || subcategoryLoading) && (
                            <div className="m-1">
                              <Shimmer width="80px" height="42px" borderRadius="rounded-full" />
                            </div>
                          )}

                          {categories && categories.length > 0 ? (
                            <>
                              {/* Placeholders test / Do not delete */}
                              {/* <div className="m-1">
                              <Shimmer width={"80px"} height={"42px"} />
                            </div> */}

                              {categories.map((category) => (
                                <div key={category.category_id}>
                                  <p
                                    type="button"
                                    onClick={() =>
                                      handleCategoryClick(category, setFieldValue)
                                    }
                                    className={`py-2 px-3 m-1 border rounded-full flex justify-center transition duration-200 hover:brightness-90 hover:cursor-pointer ${selectedCategory === category.category_id
                                      ? "border-white bg-[#0875E1] text-white"
                                      : "border-[#0875E1] bg-white text-gray-600"
                                      }`}
                                  >
                                    {category.category_name}
                                  </p>
                                </div>
                              ))}
                            </>
                          ) : (
                            <p>No categories available.</p>
                          )}
                        </div>
                        <ErrorMessage name="category_id" component="div" className="text-red-500 text-sm ml-2" />
                      </div>

                      {/* Event Subcategory Selection */}
                      <div className="mb-4">
                        <p className={`font-medium text-base tracking-wider ${filteredSubcategories.length ? "mb-0" : "mb-2"}`}>
                          Pick an Event Subcategory
                        </p>
                        {filteredSubcategories.length > 0 ? (
                          <div className="flex flex-wrap gap-2">
                            {filteredSubcategories.map((subcategory) => (
                              <p
                                type="button"
                                onClick={() => handleSubcategoryClick(subcategory, setFieldValue)}
                                className={`py-2 px-3 m-1 border rounded-full flex justify-center transition duration-200 hover:brightness-90 hover:cursor-pointer ${selectedSubCategory === subcategory.subcategory_id
                                  ? "border-white bg-[#0875E1] text-white"
                                  : "border-[#0875E1] focus:border-sunday_primary focus:outline-none bg-white text-gray-600"
                                  }`}
                                key={subcategory.subcategory_id}
                              >
                                {subcategory.name}
                              </p>
                            ))}
                          </div>
                        ) : selectedCategory ? (
                          <p className="text-gray-500">No subcategories available for the selected category.</p>
                        ) : (
                          <p className="text-gray-500">Please select a category first.</p>
                        )}
                        <ErrorMessage name="subcategory_id" component="div" className="text-red-500 text-sm ml-2" />
                      </div>

                      {/* Dates and Times */}
                      <div className="flex flex-col xl:flex-row xl:gap-6">

                        <div className="mb-4">
                          <p className="font-medium text-base tracking-wider">
                            Event Dates <span className="text-red-500">*</span>
                          </p>
                          <div className="relative">
                            <input
                              type="text"
                              ref={dateInputRef}
                              readOnly
                              value={
                                values.start_date && values.end_date && values.start_date.getTime() !== values.end_date.getTime()
                                  ? `${format(values.start_date, "MMM dd, yyyy")} - ${format(values.end_date, "MMM dd, yyyy")}`
                                  : values.start_date
                                    ? format(values.start_date, "MMM dd, yyyy")
                                    : ""
                              }
                              onClick={() => setIsDatePickerOpen(true)}
                              className="w-full sm:w-64 text-center text-sm p-2 rounded-full border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
                              placeholder="Select event dates"
                            />
                            {isDatePickerOpen && (
                              <div ref={datePickerRef} className="absolute z-10 mt-2 shadow-lg rounded-lg">
                                <DateRange
                                  editableDateInputs={true}
                                  onChange={(ranges) => {
                                    const { selection } = ranges;
                                    setFieldValue('start_date', selection.startDate);
                                    setFieldValue('end_date', selection.endDate);
                                  }}
                                  moveRangeOnFirstSelection={false}
                                  ranges={[
                                    {
                                      startDate: values.start_date || startOfToday(),
                                      endDate: values.end_date || values.start_date || startOfToday(),
                                      key: 'selection',
                                    },
                                  ]}
                                  minDate={startOfToday()}
                                  // sunday primary color
                                  rangeColors={['#D9A8C0']}
                                  color="green"
                                />
                              </div>
                            )}
                          </div>
                          <ErrorMessage
                            name="start_date"
                            component="div"
                            className="text-red-500 text-sm ml-2"
                          />
                          <ErrorMessage
                            name="end_date"
                            component="div"
                            className="text-red-500 text-sm ml-2"
                          />
                        </div>

                        {/* Times */}
                        <div className="flex flex-row gap-4 items-center mb-4">
                          {/* Start Time */}
                          <div className="flex flex-col">
                            <p className="font-medium text-base tracking-wider">
                              Start Time <span className="text-red-500">*</span>
                            </p>
                            <DatePicker
                              selected={
                                values.start_time ? new Date(`1970-01-01T${values.start_time}`) : null
                              }
                              onChange={(time) =>
                                setFieldValue("start_time", format(time, "HH:mm"))
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              timeFormat="HH:mm" // 24 hour format
                              placeholderText="Select time"
                              className="w-32 text-center text-sm p-2 rounded-full border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
                            />
                            <ErrorMessage
                              name="start_time"
                              component="div"
                              className="text-red-500 text-sm ml-2"
                            />
                          </div>

                          {/* End Time */}
                          <div className="flex flex-col">
                            <p className="font-medium text-base tracking-wider">
                              End Time <span className="text-red-500">*</span>
                            </p>
                            <DatePicker
                              selected={
                                values.end_time ? new Date(`1970-01-01T${values.end_time}`) : null
                              }
                              onChange={(time) =>
                                setFieldValue("end_time", format(time, "HH:mm"))
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              timeFormat="HH:mm" // 24 hour format
                              placeholderText="Select time"
                              className="w-32 text-center text-sm p-2 rounded-full border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
                            />
                            <ErrorMessage
                              name="end_time"
                              component="div"
                              className="text-red-500 text-sm ml-2"
                            />
                          </div>
                        </div>

                      </div>

                      {/* Venue */}
                      <VenueSelectField />

                      {/* Ticket Price */}
                      <TicketPriceField values={values} setFieldValue={setFieldValue} />

                      {/* Capacity */}
                      <div className="mb-4">
                        <p className="font-medium text-base tracking-wider">
                          Capacity <span className="text-red-500">*</span>
                        </p>
                        <Field
                          type="number"
                          name="capacity"
                          min="0"
                          placeholder="0"
                          className="w-[96px] text-center text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0) {
                              // Update Formik field value if non-negative
                              setFieldValue("capacity", value);
                            } else {
                              // Reset to 0 if negative
                              setFieldValue("capacity", 0);
                            }
                          }}
                        />
                        <ErrorMessage name="capacity" component="div" className="text-red-500 text-sm ml-2" />
                      </div>

                      {/* External Links - Shown when Event Type is Internal */}
                      {values.event_type === "Internal" && (
                        <div className="mb-4">
                          <p className="font-medium text-base tracking-wider">
                            Any External Links
                          </p>
                          <div className="flex items-center justify-between gap-4 mt-2">
                            <Field
                              type="text"
                              name="share_link"
                              placeholder="Any external link"
                              className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none"
                            />
                          </div>
                          <ErrorMessage name="share_link" component="div" className="text-red-500 text-sm ml-2" />
                        </div>
                      )}

                      {/* External URL - Shown when Event Type is External */}
                      {values.event_type === "External" && (
                        <div className="mb-4">
                          <p className="font-medium text-base tracking-wider">
                            External URL <span className="text-red-500">*</span>
                          </p>
                          <Field
                            type="text"
                            name="external_url"
                            placeholder="Enter external URL"
                            className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2"
                          />
                          <ErrorMessage name="external_url" component="div" className="text-red-500 text-sm ml-2" />
                        </div>
                      )}

                      {/* Submit Button */}
                      <div className="flex justify-center items-center mb-4">
                        <button
                          type="submit"
                          className={`h-10 bg-sunday_primary rounded-full cursor-pointer w-36 p-2 text-base tracking-wide text-white transition duration-150 hover:brightness-95 ${isSubmitting || isSubmitted ? "opacity-50" : ""}`}
                          disabled={isSubmitting || isSubmitted}
                        >
                          Publish
                        </button>
                      </div>

                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
      {/* Unused but might be useful for testing toast container properties */}

      {/* <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        containerId={"createEventForm"}
      /> */}
    </div>
  );
};

export default CreateEventForm;
