// src/components/layout/Navbar/Navbar.jsx

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import event from "../../../assets/site-images/eventfullsunday.png";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from '../../../redux/auth/action/Profile';
import { AnimatePresence } from "framer-motion";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthLinks from "./MenuItems/AuthLinks";
import MenuNav from "./MenuItems/MenuNav";
import ProfileLinks from "./MenuItems/ProfileLinks";
import MobileMenu from "./MenuItems/MobileMenu";
import SearchForm from "./Search/SearchForm";
import CategoryNav from "./CategoryNav";
import apiClient from "../../../apiClient/apiClient";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [categories, setCategories] = useState([]); // State for categories

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileState = useSelector((state) => state.profile);
  const firstName = profileState?.profile?.full_name.split(" ")[0];
  const token = localStorage.getItem("access") || sessionStorage.getItem("access");

  const linkTo = window.location.hostname === "localhost" ? "/" : "https://eventfullsunday.co.uk/";

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await dispatch(getProfile());
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    if (token) {
      fetchProfile();
    }

    // Fetch categories
    const fetchCategories = async () => {
      try {
        const response = await apiClient.get("/category/");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [dispatch, token]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Render the Create button
  const handleCreate = () => {
    // If you have additional checks, include them here
    if (profileState?.profile?.company_name === "") {
      toast.error("Complete your profile to start creating events");
    } else {
      navigate("/createEventForm");
    }
  };

  return (
    <nav className="relative bg-white shadow-md">
      <div className="flex max-w-[1200px] mx-auto px-4 justify-between items-center py-4 min-h-[120px] sm:min-h-auto">
        <Link to={linkTo} className="min-w-[80px] mr-4">
          <img src={event} className="w-20 h-20 rounded-md" alt="Event" />
        </Link>

        {/* Middle section */}
        <div className="hidden md:flex flex-grow flex-col items-center w-full md:w-auto lg:w-auto">
          <div className="flex items-center justify-center w-full max-w-2xl">
            <SearchForm />
          </div>
          <CategoryNav categories={categories} />
        </div>

        {/* Search form for mobile view */}
        <div className="flex-grow mx-4 md:hidden">
          <SearchForm />
        </div>

        <MenuNav toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

        <div className="hidden md:flex items-center gap-6">
          {localStorage.getItem("access") || sessionStorage.getItem("access")
            ? <ProfileLinks handleCreate={handleCreate} firstName={firstName} />
            : <AuthLinks />}
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isMenuOpen && <MobileMenu categories={categories} handleCreate={handleCreate} firstName={firstName} />}
      </AnimatePresence>

      {/* Toast notifications */}
      <ToastContainer position="top-right" autoClose={5000} />
    </nav>
  );
};

export default Navbar;
