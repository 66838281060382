// Home.jsx
import React, { useEffect } from "react";
import CardSlider from '../../Layout/Cards/CardSlider';
import { fetchGetItems } from "../../../redux/createEvent/createEventAction/GetEventAction";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Layout/Navbar/Navbar";
import { fetchOrganiserEvents } from "../../../redux/OrganiserEvent/organiserEventAction/GetOrganiserEventAction";
import Footer from "../../Layout/Footer/Footer";
import { eventTypeChoices } from "../../../assets/static/eventTypes";
import HomeHelmet from "./HomeHelmet";
import { getFilteredEvents } from "../../../redux/auth/action/filterSelector";
import SlickBanner from "./SlickBanner";

const Home = () => {
  const dispatch = useDispatch();

  const events = useSelector((state) => state.getEvent);
  const { data, loading: eventsLoading, error } = events;

  const profileState = useSelector((state) => state.profile);

  const yourEvents = useSelector((state) => state.getOrganiserEvent);
  const { data: yourEventsData, loading: yourEventsLoading } = yourEvents;

  // Ensure 'data' is always an array
  const allEvents = Array.isArray(data) ? data : [];

  // Get filters from Redux store
  const filters = useSelector((state) => state.filters);

  const filtersApplied = Object.values(filters).some(
    (value) => value !== "" && value !== false
  );

  // Get filtered events using selector
  const filteredData = useSelector(getFilteredEvents);

  useEffect(() => {
    if (error) { console.log("[ERROR] ", error) }
  }, [error]);

  useEffect(() => {
    dispatch(fetchGetItems()); // Fetch all events
  }, [dispatch]);

  useEffect(() => {
    if (profileState) {
      console.log("[PROFILE DEBUG] Profile state: ", profileState);
      dispatch(fetchOrganiserEvents(profileState?.profile?.id)); // Fetch user's events
    }
  }, [dispatch, profileState]);

  // Calculate the date three days ago from today
  const threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

  // Filter events created in the last 3 days
  const newListings = allEvents.filter((event) => {
    const eventDate = new Date(event.created_at);
    return eventDate >= threeDaysAgo;
  });

  // Calculate total events in event types
  const totalEventsInTypes = eventTypeChoices.reduce((sum, type) => {
    const eventsOfType = allEvents.filter(event => event.event_segment === type.key);
    return sum + eventsOfType.length;
  }, 0);

  // Check if there are no new listings
  const noNewListings = newListings.length === 0;

  // Determine if there are no events to show
  const noEventsToShow = totalEventsInTypes === 0 && noNewListings && !eventsLoading;

  // Combine loading states
  const isLoading = eventsLoading || yourEventsLoading;

  return (
    <div className="min-h-screen flex flex-col font-poppins bg-[#f2f4f7]">
      <HomeHelmet />

      {/* Navbar */}
      <Navbar />

      {/* Banner */}
      <SlickBanner />

      {/* Main Content */}
      <main className="flex flex-col flex-grow">
        {/* Container for max-width and centered alignment */}
        <div className="flex flex-col flex-grow max-w-[1200px] mx-auto px-4">
          {filtersApplied && filteredData !== data ? (
            <>
              <p className="font-poppins text-lg font-semibold mt-7">SEARCH RESULTS</p>
              {filteredData.length > 0 ? (
                <CardSlider data={filteredData} loading={eventsLoading} />
              ) : (
                <p className="mt-2 text-sm opacity-80 text-center"><i>No events match your filters.</i></p>
              )}
            </>
          ) : (
            <>
              {profileState.profile && (
                <>
                  {yourEventsData && yourEventsData.length > 0 ? (
                    <>
                      <p className="font-poppins text-lg font-semibold mt-7">YOUR EVENTS</p>
                      <CardSlider className="relative" data={yourEventsData} loading={yourEventsLoading} />
                    </>
                  ) : null}
                </>
              )}

              {/* New Listings Section */}
              {newListings.length > 0 ? (
                <div>
                  <p
                    className="font-poppins text-lg font-semibold uppercase inline-block mt-7"
                    title="Events added in the last 3 days"
                  >
                    New Listings
                  </p>
                  <CardSlider data={newListings} loading={eventsLoading} />
                </div>
              ) : null}

              {/* Check if there are no events to show */}
              {noEventsToShow && !isLoading ? (
                <div className="flex flex-col flex-grow items-center justify-center">
                  <p className="text-center text-gray-500 text-xl">
                    No events available at the moment. Please check back later.
                  </p>
                </div>
              ) : (
                /* Dynamic Event Type Sections */
                eventTypeChoices.map((type) => {
                  const eventsOfType = allEvents.filter(event => event.event_segment === type.key);
                  if (eventsOfType.length === 0) return null; // Skip if no events of this type
                  return (
                    <div key={type.key}>
                      <p className="font-poppins text-lg font-semibold mt-7 uppercase">{type.label}</p>
                      <CardSlider className="relative" data={eventsOfType} loading={eventsLoading} />
                    </div>
                  );
                })
              )}
            </>
          )}
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
