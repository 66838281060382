import { ErrorMessage } from 'formik';
import React, { useState } from 'react';
import { IoCloudUploadSharp } from 'react-icons/io5';
import { FaChevronUp, FaChevronDown, FaEdit, FaRegTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

function EventImageSection({
  selectedImage1,
  setSelectedImage1,
  selectedImage2,
  setSelectedImage2,
  selectedImage3,
  setSelectedImage3,
  onImageChange,
  setFieldValue,
  values,
  existingThumbnail,
  setExistingThumbnail,
}) {
  const [isExpanded, setIsExpanded] = useState(true);

  // State variables to track drag-over status for styling
  const [isDragging1, setIsDragging1] = useState(false);
  const [isDragging2, setIsDragging2] = useState(false);
  const [isDragging3, setIsDragging3] = useState(false);

  // Function to handle dropped files
  const handleDrop = (e, setImage, fieldName) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size exceeds 5MB. Please upload a smaller file.");
      } else {
        setImage(file);
        setFieldValue(fieldName, file);
      }
    }
    // Reset dragging state
    if (fieldName === "thumbnail_path") setIsDragging1(false);
    if (fieldName === "extra_image") setIsDragging2(false);
    if (fieldName === "extra_image2") setIsDragging3(false);
  };

  return (
    <div className={`w-full px-5 pt-5 ${isExpanded ? "pb-10" : "pb-3"}`}>
      {/* Expand/Collapse Header */}
      <div
        className="flex justify-between items-center cursor-pointer mb-2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <p className="font-medium text-base tracking-wider">
          Images <span className="text-red-500">*</span>
        </p>
        {isExpanded ? (
          <FaChevronUp size={20} />
        ) : (
          <FaChevronDown size={20} />
        )}
      </div>

      {/* Thumbnail Image with Drag-and-Drop */}
      <div
        className={`relative flex justify-center items-center mb-4 h-0 overflow-hidden shadow-md w-full pb-[56.25%] rounded-lg ${isDragging1 ? 'border-2 border-dashed border-blue-500' : 'bg-gray-300 hover:brightness-90 transition duration-150'}`}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragging1(true);
        }}
        onDragLeave={() => setIsDragging1(false)}
        onDrop={(e) => handleDrop(e, setSelectedImage1, "thumbnail_path")}
      >
        {(isExpanded || !isExpanded) && (
          <label
            htmlFor="file-upload-1"
            className="absolute inset-0 cursor-pointer"
          >
            <input
              id="file-upload-1"
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file && file.size > 5 * 1024 * 1024) {
                  if (!toast.isActive(2, "createEventForm")) {
                    toast.error("File size exceeds 5MB. Please upload a smaller file.", {
                      toastId: 2
                    });
                  }
                  e.target.value = null;
                  setFieldValue("thumbnail_path", null);
                  setSelectedImage1(null);
                  setExistingThumbnail(false);
                  return;
                }
                onImageChange(e, setSelectedImage1);
                setFieldValue("thumbnail_path", file);
                setExistingThumbnail(true);
              }}
              style={{ display: "none" }}
            />

            {selectedImage1 || values?.thumbnail_path ? (
              <div
                className="absolute inset-0 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${selectedImage1
                    ? URL.createObjectURL(selectedImage1)
                    : values?.thumbnail_path
                    })`,
                }}
              >
                <div className="absolute inset-0 opacity-0 hover:opacity-30 bg-black flex items-center justify-center transition-opacity">
                  <FaEdit className="text-white p-2" size={50} />
                  <FaRegTrashAlt className="text-white p-2" size={50} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedImage1(null);
                    setFieldValue("thumbnail_path", null);
                    setExistingThumbnail(false);
                    }}/>
                </div>
              </div>
            ) : (
              <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
                <IoCloudUploadSharp className="text-sunday_info" size={50} />
                <p className="font-medium text-base leading-7 mt-2">
                  Drag and drop an image
                </p>
              </div>
            )}
          </label>
        )}
      </div>
      <ErrorMessage
        name="thumbnail_path"
        component="div"
        className="text-red-500 text-base ml-2"
      />
      {isExpanded && (
        <>
          {/* Info Section */}
          <p className="text-sm text-gray-600 mt-2 text-center">
            Maximum file size: 2 MB. Supported file types: JPG, PNG, GIF.
          </p>

          {/* Additional Images */}
          {(selectedImage1 || values?.thumbnail_path) && (
            <>
              <p className="font-medium text-base text-center tracking-wider mb-5 mt-7">
                Upload More Images
              </p>
              <div className="flex flex-col md:flex-row gap-4 justify-between md:items-center md:justify-center">
                {/* Extra Image 1 with Drag-and-Drop */}
                <div
                  className={`relative flex justify-center items-center mb-4 shadow-md w-full md:w-[250px] rounded-lg overflow-hidden ${isDragging2 ? 'border-2 border-dashed border-blue-500' : 'bg-gray-300 hover:brightness-90 transition duration-150'}`}
                  onDragOver={(e) => {
                    e.preventDefault();
                    setIsDragging2(true);
                  }}
                  onDragLeave={() => setIsDragging2(false)}
                  onDrop={(e) => handleDrop(e, setSelectedImage2, "extra_image")}
                >
                  <label
                    htmlFor="file-upload-2"
                    className="cursor-pointer w-full h-full"
                  >
                    <input
                      id="file-upload-2"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.size > 5 * 1024 * 1024) {
                          if (!toast.isActive(3, "createEventForm")) {
                            toast.error("File size exceeds 5MB. Please upload a smaller file.", {
                              toastId: 3
                            });
                          }
                          e.target.value = null;
                          setSelectedImage2(null);
                          return;
                        }
                        onImageChange(e, setSelectedImage2);
                      }}
                      style={{ display: "none" }}
                    />

                    <div className="relative w-full h-0 pb-[56.25%]">
                      {selectedImage2 || values?.extra_image ? (
                        <div
                          className="absolute inset-0 bg-cover bg-center"
                          style={{
                            backgroundImage: `url(${selectedImage2
                              ? URL.createObjectURL(selectedImage2)
                              : values?.extra_image
                              })`,
                          }}
                        >
                          <div className="absolute inset-0 opacity-0 hover:opacity-30 bg-black flex items-center justify-center transition-opacity">
                            <FaEdit className="text-white p-2" size={50} />
                            <FaRegTrashAlt className="text-white p-2" size={50} onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setSelectedImage2(null);
                              setFieldValue("extra_image", null);}}/>
                          </div>
                        </div>
                      ) : (
                        <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
                          <IoCloudUploadSharp className="text-sunday_info" size={50} />
                          <p className="font-medium text-base leading-7 mt-2">
                            Drag and drop an image
                          </p>
                        </div>
                      )}
                    </div>
                  </label>
                </div>

                {/* Extra Image 2 with Drag-and-Drop */}
                <div
                  className={`relative flex justify-center items-center mb-4 shadow-md w-full md:w-[250px] rounded-lg overflow-hidden ${isDragging3 ? 'border-2 border-dashed border-blue-500' : 'bg-gray-300 hover:brightness-90 transition duration-150'}`}
                  onDragOver={(e) => {
                    e.preventDefault();
                    setIsDragging3(true);
                  }}
                  onDragLeave={() => setIsDragging3(false)}
                  onDrop={(e) => handleDrop(e, setSelectedImage3, "extra_image2")}
                >
                  <label
                    htmlFor="file-upload-3"
                    className="cursor-pointer w-full h-full"
                  >
                    <input
                      id="file-upload-3"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.size > 5 * 1024 * 1024) {
                          if (!toast.isActive(4, "createEventForm")) {
                            toast.error("File size exceeds 5MB. Please upload a smaller file.", {
                              toastId: 4
                            });
                          }
                          e.target.value = null;
                          setSelectedImage3(null);
                          return;
                        }
                        onImageChange(e, setSelectedImage3);
                      }}
                      style={{ display: "none" }}
                    />

                    <div className="relative w-full h-0 pb-[56.25%]">
                      {selectedImage3 || values?.extra_image2 ? (
                        <div
                          className="absolute inset-0 bg-cover bg-center"
                          style={{
                            backgroundImage: `url(${selectedImage3
                              ? URL.createObjectURL(selectedImage3)
                              : values?.extra_image2
                              })`,
                          }}
                        >
                          <div className="absolute inset-0 opacity-0 hover:opacity-30 bg-black flex items-center justify-center transition-opacity">
                            <FaEdit className="text-white p-2" size={50} />
                            <FaRegTrashAlt className="text-white p-2" size={50} onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setSelectedImage3(null);
                              setFieldValue("extra_image2", null);}}/>
                          </div>
                        </div>
                      ) : (
                        <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
                          <IoCloudUploadSharp className="text-sunday_info" size={50} />
                          <p className="font-medium text-base leading-7 mt-2">
                            Drag and drop an image
                          </p>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              {/* Info Section for Additional Images */}
              <p className="text-sm text-gray-600 mt-2 text-center">
                Maximum file size: 2 MB. Supported file types: JPG, PNG, GIF.
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default React.memo(EventImageSection);
