import axios from 'axios';
import { ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

function CountrySelectField({ showDropdown, setShowDropdown, countryDropdownRef, setFieldValue, values }) {
  const [countries, setCountries] = useState([]);

  // Fetch countries for the country dropdown
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countriesData = response.data.map((country) => ({
          name: country.name.common,
          areaCode: country.cca2,
        }));
        setCountries(countriesData);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Handle country selection
  const handleSelectCountry = (country) => {
    setShowDropdown(false);
    setFieldValue('event_origin_country', country.name);
  };

  return (
    <div className="mb-4 relative" ref={countryDropdownRef}>
      <p className="font-medium text-base tracking-wider">
        Event Origin Country <span className="text-red-500">*</span>
      </p>
      <div className="relative flex items-center">
        <input
          onClick={() => setShowDropdown(!showDropdown)}
          type="text"
          name="event_origin_country"
          placeholder="Choose your country"
          value={values.event_origin_country}
          onChange={(e) => {
            setFieldValue('event_origin_country', e.target.value);
          }}
          className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] focus:border-sunday_primary focus:outline-none mt-2 cursor-pointer"
        />
        {showDropdown ? (
          <IoChevronUpOutline
            className="absolute right-4 text-gray-500 w-5 h-5 top-5 cursor-pointer"
            onClick={() => setShowDropdown(!showDropdown)}
          />
        ) : (
          <IoChevronDownOutline
            className="absolute right-4 text-gray-500 w-5 h-5 top-5 cursor-pointer"
            onClick={() => setShowDropdown(!showDropdown)}
          />
        )}
      </div>
      {showDropdown && (
        <div className="absolute z-10 bg-white border border-gray-300 focus:border-sunday_primary focus:outline-none mt-1 w-full rounded-lg max-h-60 overflow-y-auto">
          {countries.length === 0 && <div className="p-2">Loading countries...</div>}
          {countries.length > 0 && (() => {
            // Filter countries based on input
            const inputValue = values.event_origin_country.toLowerCase();

            let filteredCountries;

            if (inputValue === 'united kingdom') {
              // Show all countries when input is "United Kingdom"
              filteredCountries = countries;
            } else {
              filteredCountries = countries.filter((country) =>
                country.name.toLowerCase().includes(inputValue)
              );
            }

            // Separate United Kingdom from other countries
            const ukCountry = filteredCountries.find((country) => country.name === 'United Kingdom');
            const otherCountries = filteredCountries.filter((country) => country.name !== 'United Kingdom');

            // Sort other countries alphabetically
            otherCountries.sort((a, b) => a.name.localeCompare(b.name));

            return (
              <>
                {/* Render United Kingdom at the top if it matches the filter */}
                {ukCountry && (
                  <div
                    key={ukCountry.name}
                    className="cursor-pointer p-2 hover:bg-gray-200"
                    onClick={() => handleSelectCountry(ukCountry)}
                  >
                    {ukCountry.name}
                  </div>
                )}

                {/* Render divider if United Kingdom is displayed and there are other countries */}
                {ukCountry && otherCountries.length > 0 && (
                  <hr className="border-t border-gray-300 my-1" />
                )}

                {/* Render other countries */}
                {otherCountries.map((country) => (
                  <div
                    key={country.name}
                    className="cursor-pointer p-2 hover:bg-gray-200"
                    onClick={() => handleSelectCountry(country)}
                  >
                    {country.name}
                  </div>
                ))}
              </>
            );
          })()}
        </div>
      )}
      <ErrorMessage
        name="event_origin_country"
        component="div"
        className="text-red-500 text-sm ml-2"
      />
    </div>
  );
}

export default CountrySelectField;
