import React from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import image1 from '../../../assets/site-images/organizer.png';
import image2 from '../../../assets/site-images/organiser_banner.png';
import './SlickBanner.css';


function SlickBanner() {

    const settings = {
        dots: true,
        infinite: true,         // Auto-loop
        speed: 1000,            // Transition speed
        slidesToShow: 1,        // One image at a time
        slidesToScroll: 1,
        autoplay: true,         // Enables auto-sliding
        autoplaySpeed: 3000,    // Slide every 3 seconds
        centerMode: true,       // Centers the image
        centerPadding: '0',  // Padding on both sides to adjust width
      };

      const ctaButtons = [
        {
            text: 'Organizer Guide',
            image: image1,
            link: `https://events.eventfullsunday.co.uk/`
        },
        {
            text: 'Blogs',
            image: image2,
            link: `https://events.eventfullsunday.co.uk/blogs/`
        }
    ];

  return (
    <div className="banner-wrapper">
      <div className="banner-container">
        <Slider {...settings}>
          {ctaButtons.map((image, index) => (
            <div key={index} className="banner-slide">
              <div className="banner-content">
                {index === 0 && (
                  <>
                    <h1 className="banner-title">
                      Connecting Communities, Celebrating Traditions
                    </h1>
                    <p className="banner-subtitle">
                      From Food Fests to Traditional Dance, Find It Here
                    </p>
                    <button 
                      className="banner-cta-button"
                      
                    >
                      Join the Celebration!
                    </button>
                  </>
                )}
                {index === 1 && (
                  <>
                    <h1 className="banner-title">
                      Discover Live Music
                    </h1>
                    <p className="banner-subtitle">
                      Experience Artists From Around the World
                    </p>
                    <button 
                      className="banner-cta-button"
                      
                    >
                      Get Tickets Now!
                    </button>
                  </>
                )}
                
                
              </div>
              <div className="banner-image-container">
                <img 
                  src={image.image} 
                  alt={`Event banner ${index + 1}`} 
                  className="banner-image"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default SlickBanner;
