import apiClient from "../../../apiClient/apiClient";

import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE
} from "../constant/ProfileConstant"

// Action to update organizer profile
export const updateOrganizerProfile = (formData) => async (dispatch) => {
  dispatch({ type: GET_PROFILE_REQUEST });

  try {

    console.log("[ORG PROFILE UPDATE TEST 2]: formData: ", [...formData]);


    const token = localStorage.getItem("access") || sessionStorage.getItem("access");

    const configUser = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const configFormData = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const { data: user } = await apiClient.get("api/user/", configUser);
    const { data: updatedOrganizerProfile } = await apiClient.put(
      "api/organizer-profile/",
      formData,
      configFormData
    );

    const userMerged = { ...user, ...updatedOrganizerProfile }

    // dispatch({
    //   type: ORGANIZATION_PROFILE_RESET_SUCCESS,
    //   payload: userMerged,
    // });

    // Dispatch a payload to default profile state instead of organizer profile state with the updated profile
    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: userMerged,
    });

  } catch (error) {
    dispatch({
      type: GET_PROFILE_FAILURE,
      payload: error.message,
    });
  }
};
