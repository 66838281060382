// src/redux/auth/reducer/LoginReducer.jsx

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from "../constant/LoginConstant";

const initialState = {
  loading: false,
  data: null, // Will hold { access, refresh }
  error: null,
};

const loginReducer = (state = initialState, action) => {
  switch(action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null, // Reset errors
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload, // { access, refresh }
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGOUT:
      return initialState; // Reset state on logout
    default:
      return state;
  }
};

export default loginReducer;
