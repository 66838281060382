// src/App.js

import React, { useEffect, useState, useCallback } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import Login from "./auth/Login/Login";
import Register from "./auth/Register/Register";
import ForgotPassword from "./auth/Password/ForgotPassword";
import ResetPassword from "./auth/Password/ResetPassword";
import ForgotPasswordConfirm from "./auth/Password/ForgotPasswordConfirm";
import EmailConfirmation from "./auth/Register/EmailConfirmation";
import Home from "./components/Pages/Home/Home";
import EventDetail from "./components/Pages/EventDetails/EventDetail";
import NotFound from "./components/Pages/NotFoundPage/404PageNotFound";
import PrivateRoute from "./ProtectedRoute/ProtectedRoutes";
import CreateEventForm from "./components/Pages/CreateEvent/CreateEventForm";
import Dashboard from "./components/Pages/Dashboard/OrganizerDashboard";
import CreateTicketForm from "./components/Pages/Dashboard/TicketComponents/CreateTicketForm";
import OrganizerRoute from "./ProtectedRoute/OrganizerRoute";
import NotAuthorized from "./components/Pages/NotFoundPage/NotAuthorized";
import CookieBanner from "./CookieBanner";
import AccessAuth from "./ProtectedRoute/AccessAuth";

function App() {
  const location = useLocation();
  const [gaInitialized, setGaInitialized] = useState(false);

  const handleCookieAccept = useCallback(() => {
    if (!gaInitialized) {
      // Initialize GA
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, { debug: true });
      setGaInitialized(true);

      // Log initialization
      console.log(`GA Initialized`);
    }
  }, [gaInitialized]);

  useEffect(() => {
    if (localStorage.getItem("cookie-consent") === "accepted" && !gaInitialized) {
      handleCookieAccept();
    }
  }, [gaInitialized, handleCookieAccept]);

  useEffect(() => {
    if (gaInitialized) {
      // Send pageview on GA initialization and location change
      ReactGA.send({ hitType: "pageview", page: location.pathname });
      console.log(`GA Pageview sent for ${location.pathname}`);
    }
  }, [gaInitialized, location]);

  return (
    <>
      <CookieBanner onAccept={handleCookieAccept} /> {/* Pass GA initialization callback */}
      <Routes>
        {/* Public Routes */}
        <Route exact path="/login" element={
          <AccessAuth>
            <Login />
          </AccessAuth>
        } />
        <Route exact path="/register" element={
          <AccessAuth>
            <Register />
          </AccessAuth>
        } />
        <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
        <Route exact path="/password-reset-confirm/:uid/:token" element={<ResetPassword />} />
        <Route exact path="/passwordResetConfirmation" element={<ForgotPasswordConfirm />} />
        <Route exact path="/emailConfirmation" element={<EmailConfirmation />} />
        <Route path="" element={<Home />} />
        <Route path="/not-authorized" element={<NotAuthorized />} />

        {/* Private Routes */}
        <Route
          path="/createEventForm"
          element={
            <PrivateRoute>
              <CreateEventForm />
            </PrivateRoute>
          }
        />

        <Route path="/event/:id" element={<EventDetail />} />

        <Route
          exact
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/create-ticket/:id"
          element={
            <PrivateRoute>
              <CreateTicketForm />
            </PrivateRoute>
          }
        />

        {/* Organizer Only Route */}
        <Route
          exact
          path="/editForm/:id"
          element={
            <OrganizerRoute>
              <CreateEventForm />
            </OrganizerRoute>
          }
        />

        {/* Fallback Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
