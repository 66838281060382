// src/components/CardSlider.jsx
import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PlaceholderCard from './PlaceholderCard';
// import FavoriteButton from './FavoriteButton';

const CardSlider = ({ loading, data }) => {
  const sliderRef = useRef(null);

  // Part of different line-clamp implementation for adding "..." to long text, could be used later potentially
  // const [maxWords, setMaxWords] = useState(8);

  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) return;

    const handleWheel = (e) => {
      const { scrollWidth, clientWidth } = slider;
      const isOverflowing = scrollWidth > clientWidth;

      if (isOverflowing) {
        e.preventDefault();
        slider.scrollLeft += e.deltaY;
      }
    };

    // Add wheel event listener with passive: false
    slider.addEventListener('wheel', handleWheel, { passive: false });

    // Clean up the event listener on component unmount
    return () => {
      slider.removeEventListener('wheel', handleWheel);
    };
  }, []);

  // Helper function to format the price
  const formatPrice = (price) => {
    if (price === 'Price not set') return 'Free';

    if (price) {
      const priceRange = price.split("-");
      const firstPrice = parseFloat(priceRange[0].replace("£", ""));
      const secondPrice = priceRange[1] ? parseFloat(priceRange[1].replace("£", "")) : null;

      const isRange = priceRange.length === 2 && firstPrice !== secondPrice;
      const isUpTo = price.includes("Up to");
      const isSame = priceRange.length === 1 || firstPrice === secondPrice;

      if (isRange) {
        return `From £${firstPrice}`;
      } else if (isUpTo) {
        return `${priceRange[0]}`;
      } else if (isSame) {
        return `£${firstPrice}`;
      }
    } else { return "N/A" }
  };

  // Different max words allowed for event name based on screen size - unused but saving just in case line-clamp is not sufficient
  // useEffect(() => {
  //   const updateMaxWords = () => {
  //     setMaxWords(window.innerWidth < 640 ? 6 : 8);
  //   };
  //   updateMaxWords();
  //   window.addEventListener('resize', updateMaxWords);
  //   return () => window.removeEventListener('resize', updateMaxWords);
  // }, []);

  return (
    <div className="w-full mb-5">
      <div
        ref={sliderRef}
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 lg:gap-7 mx-3 py-6"
      >
        {loading
          ? Array(3)
            .fill(0)
            .map((_, index) => <PlaceholderCard key={index} />)
          : data.map((EventData, index) => (
            <Link to={`/event/${EventData.event_id}`} key={index}>
              <div className="flex flex-row sm:flex-col relative rounded-lg overflow-hidden cursor-pointer hover:shadow-lg transition duration-200">

                {/* Thumbnail Image */}
                <div className="relative w-24 h-24 sm:w-full sm:h-auto sm:aspect-[1/1] flex-shrink-0">
                  <img
                    src={EventData?.thumbnail_path || 'https://via.placeholder.com/100'}
                    alt="Event Thumbnail"
                    className="w-full h-full object-cover rounded-lg sm:aspect-[1/1]"
                  />
                  {/* Inside Favorite Button */}
                  {/*<div className="hidden sm:flex items-center sm:absolute sm:right-2 sm:bottom-2 p-2">
                    <FavoriteButton inside={true} />
                  </div>*/}
                </div>

                {/* Event Info */}
                <div className="flex flex-col justify-between px-2 sm:p-4 flex-grow">
                  <div>
                    <p
                      className="font-poppins text-base sm:text-xl font-bold line-clamp-2"
                      title={EventData.event_name}
                    >
                      {/* {truncateText(EventData.event_name, maxWords)} */}
                      {EventData.event_name}
                    </p>
                    <p className="font-poppins text-sm text-black">
                      {new Date(EventData.start_date).toLocaleDateString('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      })} at {EventData.start_time.slice(0, 5)}
                    </p>
                    <p className="font-poppins text-sm text-sunday_tertiary font-bold">
                      {EventData.venue_name || 'Venue Name'}
                    </p>
                    <p className="font-poppins text-sm text-black">
                      {formatPrice(EventData.price_range)}
                    </p>
                  </div>

                  {/* Get Tickets Button (Hidden on Mobile) */}
                  {/* <div className="hidden sm:block mt-2">
                      <button
                        className="py-1 px-4 bg-sunday_primary text-white font-semibold rounded-full hover:bg-sunday_primary_dark transition duration-200"
                      >
                        Get Tickets
                      </button>
                    </div> */}
                </div>

                {/* Outside Favorite Button */}
                {/*<div className="sm:hidden flex items-center p-2">
                  <FavoriteButton />
                </div>*/}
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default CardSlider;
