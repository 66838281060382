// src/components/layout/Navbar/FilterModal.jsx

import React, { useState, useEffect, useRef } from "react";
import { IoClose, IoFilterSharp } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import PlacesAutocomplete from 'react-places-autocomplete';
import { useDispatch } from 'react-redux';
import { setFilters, resetFilters } from '../../../../redux/auth/action/filtersActions';
import { useNavigate, useLocation } from 'react-router-dom';
import apiClient from "../../../../apiClient/apiClient";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Main style file
import 'react-date-range/dist/theme/default.css'; // Theme CSS
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const initialFilterState = {
  name: "",
  category: "",
  dateRange: {
    start: null,
    end: null,
  },
  startTime: "",
  endTime: "",
  location: "",
  ticketPriceMin: "",
  ticketPriceMax: "",
  isFreeTicket: false,
  capacityMin: "",
  capacityMax: "",
};


const FilterModal = ({ isOpen, onClose }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();

  const [filter, setFilter] = useState(initialFilterState);
  const [address, setAddress] = useState("");
  const [isScriptLoaded, setIsScriptLoaded] = useState(!!window.google);
  const [categories, setCategories] = useState([]);
  const suggestionsRef = useRef(null); // To handle clicks outside suggestions

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef(null);
  const modalRef = useRef(null); // Ref for the modal

  const [clickStartedInside, setClickStartedInside] = useState(false);

  // Check if Google API is loaded
  useEffect(() => {
    if (window.google) {
      setIsScriptLoaded(true);
    }
  }, []);

  // Handle mousedown and mouseup to control modal closing
  useEffect(() => {
    const handleMouseDown = (e) => {
      if (modalRef.current && modalRef.current.contains(e.target)) {
        setClickStartedInside(true);
      } else {
        setClickStartedInside(false);
      }
    };

    const handleMouseUp = (e) => {
      if (!modalRef.current || !modalRef.current.contains(e.target)) {
        if (!clickStartedInside) {
          onClose();
        }
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isOpen, clickStartedInside, onClose]);

  // Close the date picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDatePickerOpen && datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setIsDatePickerOpen(false);
      }
    };
    if (isDatePickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDatePickerOpen]);

  // Fetch categories when modal opens
  useEffect(() => {
    if (isOpen) {
      const fetchCategories = async () => {
        try {
          const response = await apiClient.get("/category/");
          setCategories(response.data);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      };
      fetchCategories();
    } else {
      // Reset filter when modal closes
      setFilter(initialFilterState);
      setAddress("");
      setIsDatePickerOpen(false);
    }
  }, [isOpen]);

  // Handle clicks outside suggestions to close them
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        // Close suggestions if clicked outside
        // Implement if needed
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCategoryClick = (category) => {
    setFilter((prev) => ({
      ...prev,
      category: prev.category === category.category_name ? "" : category.category_name,
    }));
  };

  const handleClearFilters = () => {
    dispatch(resetFilters());
    setFilter(initialFilterState);
    setAddress("");
    setIsDatePickerOpen(false);
  };

  const handleSearch = () => {
    // Prepare filter data
    const appliedFilters = {
      ...filter,
      location: address,
      startDate: filter.dateRange.start
        ? formatDate(filter.dateRange.start)
        : "",
      endDate: filter.dateRange.end
        ? formatDate(filter.dateRange.end)
        : "",
    };

    dispatch(setFilters(appliedFilters));
    onClose();

    // Redirect to home page if not already there
    if (locationPath.pathname !== '/') {
      navigate('/');
    }
  };

  const handleSelect = (address) => {
    setAddress(address);
    setFilter((prev) => ({
      ...prev,
      location: address,
    }));
  };

  const formatDate = (date) => {
    // Format date as 'YYYY-MM-DD'
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero-indexed
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Helper function to format time to "HH:mm"
  const formatTime = (date) => {
    if (!date) return "";
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${padZero(hours)}:${padZero(minutes)}`;
  };

  const padZero = (num) => {
    return num < 10 ? `0${num}` : num;
  };

  // Determine if dates are selected for conditional text alignment
  const datesSelected = filter.dateRange.start || filter.dateRange.end;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="flex items-center justify-center min-h-screen p-4">
            <motion.div
              className="shadow-2xl bg-white rounded-lg w-full max-w-xl p-6 relative max-h-[calc(100vh-4rem)] overflow-y-auto"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              ref={modalRef} // Attach ref to modal for click tracking
            >
              {/* Header */}
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold flex items-center">
                  <IoFilterSharp size={24} className="mr-2" />
                  Filter Events
                </h2>
                <button
                  onClick={onClose}
                  className="text-black hover:text-black focus:outline-none"
                  aria-label="Close Filter Modal"
                >
                  <IoClose size={24} />
                </button>
              </div>

              {/* Modal Body */}
              <form className="space-y-4">
                {/* Event Name */}
                <div className="w-[90%]">
                  <label className="block text-black font-medium mb-2" htmlFor="name">
                    Name of the Event
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Event Name"
                    value={filter.name}
                    onChange={handleFilterChange}
                    className="w-full px-4 py-2 border-2 border-gray-400 rounded-full focus:outline-none"
                  />
                </div>

                {/* Category */}
                <div>
                  <label className="block text-black font-medium mb-2">
                    Category
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {categories.map((category) => (
                      <button
                        key={category.category_id}
                        type="button"
                        onClick={() => handleCategoryClick(category)}
                        className={`px-4 py-2 rounded-full border-2 hover:brightness-95 flex justify-center transition duration-200 hover:cursor-pointer ${filter.category === category.category_name
                          ? 'bg-sunday_secondary text-white border-sunday_secondary'
                          : 'bg-white text-black border-gray-400'
                          }`}
                      >
                        {category.category_name}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Date Range */}
                <div className="mb-4 w-56">
                  <label className="block text-black font-medium mb-2">
                    Date Range
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      readOnly
                      value={
                        filter.dateRange.start && filter.dateRange.end
                          ? `${formatDate(filter.dateRange.start)} - ${formatDate(filter.dateRange.end)}`
                          : filter.dateRange.start
                            ? `${formatDate(filter.dateRange.start)}`
                            : ""
                      }
                      onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                      className={`w-full px-4 py-2 border-2 border-gray-400 rounded-full focus:border-sunday_secondary focus:outline-none cursor-pointer ${datesSelected ? 'text-center' : 'text-left'
                        }`}
                      placeholder="Select date range"
                    />
                    {isDatePickerOpen && (
                      <div ref={datePickerRef} className="absolute z-10 mt-2 shadow-lg rounded-lg">
                        <DateRange
                          editableDateInputs={true}
                          onChange={(item) => {
                            setFilter((prev) => ({
                              ...prev,
                              dateRange: { start: item.selection.startDate, end: item.selection.endDate },
                            }));
                          }}
                          moveRangeOnFirstSelection={false}
                          ranges={[
                            {
                              startDate: filter.dateRange.start || new Date(),
                              endDate: filter.dateRange.end || new Date(),
                              key: 'selection',
                            },
                          ]}
                          // Use sunday_secondary color from Tailwind
                          rangeColors={['#D9A8C0']}
                          className="rounded-lg shadow-lg"
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Start Time and End Time */}
                <div>
                  <label className="block text-black font-medium mb-2">
                    Time Range
                  </label>
                  <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                    {/* Start Time */}
                    <div className="flex flex-col">
                      <label className="text-black mb-1" htmlFor="startTime">
                        Start Time
                      </label>
                      <DatePicker
                        selected={
                          filter.startTime ? new Date(`1970-01-01T${filter.startTime}`) : null
                        }
                        onChange={(time) =>
                          setFilter((prev) => ({
                            ...prev,
                            startTime: formatTime(time),
                          }))
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm" // 24-hour format
                        placeholderText="08:00"
                        className="w-full sm:w-20 px-4 py-2 border-2 border-gray-400 rounded-full focus:border-sunday_secondary focus:outline-none cursor-pointer"
                      />
                    </div>

                    {/* End Time */}
                    <div className="flex flex-col">
                      <label className="text-black mb-1" htmlFor="endTime">
                        End Time
                      </label>
                      <DatePicker
                        selected={
                          filter.endTime ? new Date(`1970-01-01T${filter.endTime}`) : null
                        }
                        onChange={(time) =>
                          setFilter((prev) => ({
                            ...prev,
                            endTime: formatTime(time),
                          }))
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm" // 24-hour format
                        placeholderText="10:00"
                        className="w-full sm:w-20 px-4 py-2 border-2 border-gray-400 rounded-full focus:border-sunday_secondary focus:outline-none cursor-pointer"
                      />
                    </div>
                  </div>
                </div>

                {/* Location with Address Finder */}
                <div className="w-[90%]">
                  <label className="block text-black font-medium mb-2" htmlFor="location">
                    Location
                  </label>

                  {!isScriptLoaded && <div>Loading Google Maps API...</div>}

                  {isScriptLoaded ? (
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                      searchOptions={{
                        types: ['(regions)'],
                      }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="relative">
                          <input
                            {...getInputProps({
                              placeholder: 'Enter address or country',
                              className: 'w-full px-4 py-2 border-2 border-gray-400 rounded-full focus:outline-none',
                            })}
                          />
                          {suggestions.length > 0 && (
                            <div
                              ref={suggestionsRef}
                              className="absolute z-10 bg-white border border-gray-400 rounded-md mt-1 w-full max-h-60 overflow-y-auto"
                            >
                              {loading && <div className="px-4 py-2">Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? 'cursor-pointer bg-blue-100 px-4 py-2'
                                  : 'cursor-pointer bg-white px-4 py-2';
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, { className })}
                                    key={suggestion.placeId}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  ) : (
                    <input
                      type="text"
                      placeholder="Loading address finder..."
                      disabled
                      className="w-full px-4 py-2 border-2 border-gray-400 rounded-full focus:outline-none bg-gray-100"
                    />
                  )}
                </div>

                {/* Ticket Price */}
                <div>
                  <label className="block text-black font-medium mb-2">
                    Ticket Price (£)
                  </label>
                  <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                    {/* Min Ticket Price */}
                    <div className="flex items-center mb-4 sm:mb-0">
                      {/* <span className="text-gray-500 mr-1">£</span> */}
                      <input
                        type="number"
                        name="ticketPriceMin"
                        id="ticketPriceMin"
                        placeholder="Min"
                        value={filter.ticketPriceMin}
                        onChange={handleFilterChange}
                        min="0"
                        disabled={filter.isFreeTicket}
                        className="px-2 py-2 border-2 border-gray-400 rounded-full focus:outline-none w-24"
                      />
                    </div>

                    {/* Max Ticket Price */}
                    <div className="flex items-center mb-4 sm:mb-0">
                      {/* <span className="text-gray-500 mr-1">£</span> */}
                      <input
                        type="number"
                        name="ticketPriceMax"
                        id="ticketPriceMax"
                        placeholder="Max"
                        value={filter.ticketPriceMax}
                        onChange={handleFilterChange}
                        min="0"
                        disabled={filter.isFreeTicket}
                        className="px-2 py-2 border-2 border-gray-400 rounded-full focus:outline-none w-24"
                      />
                    </div>

                    {/* Free ticket toggle */}
                    <div className="flex items-center">
                      <button
                        type="button"
                        onClick={() => setFilter((prev) => ({
                          ...prev,
                          isFreeTicket: !prev.isFreeTicket,
                          ticketPriceMax: prev.isFreeTicket ? "" : prev.ticketPriceMax
                        }))}
                        className={`px-4 py-2 rounded-full border-2 focus:outline-none hover:brightness-95 transition duration-150 ${filter.isFreeTicket
                          ? 'bg-sunday_secondary text-white border-sunday_secondary'
                          : 'bg-white text-black border-gray-400'
                          }`}
                      >
                        {filter.isFreeTicket ? 'Free Ticket' : 'Set as Free'}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Capacity */}
                <div>
                  <label className="block text-black font-medium mb-2">
                    Capacity
                  </label>
                  <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                    {/* Min Capacity */}
                    <div className="flex items-center mb-4 sm:mb-0">
                      <input
                        type="number"
                        name="capacityMin"
                        id="capacityMin"
                        placeholder="Min"
                        min="0"
                        step="1"
                        value={filter.capacityMin}
                        onChange={handleFilterChange}
                        className="px-2 py-2 border-2 border-gray-400 rounded-full focus:outline-none w-24"
                      />
                    </div>

                    {/* Max Capacity */}
                    <div className="flex items-center">
                      <input
                        type="number"
                        name="capacityMax"
                        id="capacityMax"
                        placeholder="Max"
                        min="0"
                        step="1"
                        value={filter.capacityMax}
                        onChange={handleFilterChange}
                        className="px-2 py-2 border-2 border-gray-400 rounded-full focus:outline-none w-24"
                      />
                    </div>
                  </div>
                </div>
              </form>

              {/* Modal Footer */}
              <div className="mt-6 flex justify-end space-x-4 transition duration-150">
                <button
                  onClick={onClose}
                  className="px-4 py-2 bg-white text-black rounded-full border-2 border-gray-400 hover:brightness-95 focus:outline-none transition duration-150"
                >
                  Close
                </button>
                <button
                  onClick={handleClearFilters}
                  className="px-4 py-2 bg-white text-black rounded-full border-2 border-gray-400 hover:brightness-95 focus:outline-none transition duration-150"
                >
                  Clear Filters
                </button>
                <button
                  onClick={handleSearch}
                  className="px-4 py-2 bg-white border-2 border-sunday_primary text-black rounded-full hover:brightness-95 focus:outline-none transition duration-150"
                >
                  Search
                </button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FilterModal;
