import React, { useState, useEffect } from "react";
import {
  FaUser,
  FaBell,
  FaLock,
  FaCog,
  FaTicketAlt,
  FaSignOutAlt,
  FaArrowLeft,
} from "react-icons/fa";
import { BiSolidPurchaseTag } from "react-icons/bi";
import Navbar from "../../Layout/Navbar/Navbar";
import Footer from "../../Layout/Footer/Footer";
import Profile from "./ProfileComponents/ProfileComponent";
import Updates from "./Updates";
import PrivacyPolicy from "./PrivacyPolicy";
import Settings from "./Settings";
import { useNavigate, useLocation } from "react-router-dom";
import ManageEvents from "./TicketComponents/ManageEvents";
import LogoutConfirmationModal from "./LogoutConfirmationModal";
import PurchaseLogs from "./PurchaseLogs/PurchaseLogs";

const Dashboard = () => {
  const location = useLocation();
  const [selectedSection, setSelectedSection] = useState(location.state?.selectedSection || "profile");
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
      if (window.innerWidth >= 640) {
        setIsSidebarVisible(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderContent = () => {
    switch (selectedSection) {
      case "profile":
        return <Profile />;
      case "manage-events":
        return <ManageEvents />;
      case "purchase-logs":
        return <PurchaseLogs />;
      case "updates":
        return <Updates />;
      case "privacy-policy":
        return <PrivacyPolicy />;
      case "settings":
        return <Settings />;
      default:
        return null;
    }
  };

  const handleLogoutClick = () => {
    setIsModalOpen(true)
  }

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");
    console.log("All tokens cleared from storage");
    navigate("/login");
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      <div className="flex flex-grow flex-col-reverse lg:flex-row bg-gray-100">
        {/* Sidebar */}
        {isSidebarVisible && (
          <div className="w-full md:min-w-[350px] md:max-w-[350px] bg-white shadow-lg p-4 md:p-8 h-full">
            <div className="flex flex-col justify-between h-full">
              <div>
                <button
                  onClick={() => {
                    setSelectedSection("profile");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "profile"
                    ? "text-white bg-sunday_primary rounded-lg"
                    : "text-sunday_info hover:bg-gray-200 rounded-lg"
                    }`}
                >
                  <FaUser className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Profile</span>
                </button>

                <button
                  onClick={() => {
                    setSelectedSection("manage-events");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "manage-events"
                    ? "text-white bg-sunday_primary rounded-lg"
                    : "text-sunday_info hover:bg-gray-200 rounded-lg"
                    }`}
                >
                  <FaTicketAlt className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Manage Events</span>
                </button>

                <button
                  onClick={() => {
                    setSelectedSection("purchase-logs");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "purchase-logs"
                    ? "text-white bg-sunday_primary rounded-lg"
                    : "text-sunday_info hover:bg-gray-200 rounded-lg"
                    }`}
                >
                  <BiSolidPurchaseTag className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Order Management</span>
                </button>

                <button
                  onClick={() => {
                    setSelectedSection("updates");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "updates"
                    ? "text-white bg-sunday_primary rounded-lg"
                    : "text-sunday_info hover:bg-gray-200 rounded-lg"
                    }`}
                >
                  <FaBell className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Updates</span>
                </button>
                <button
                  onClick={() => {
                    setSelectedSection("privacy-policy");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "privacy-policy"
                    ? "text-white bg-sunday_primary rounded-lg"
                    : "text-sunday_info hover:bg-gray-200 rounded-lg"
                    }`}
                >
                  <FaLock className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Privacy Policy</span>
                </button>
                <button
                  onClick={() => {
                    setSelectedSection("settings");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  className={`flex items-center p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "settings"
                    ? "text-white bg-sunday_primary rounded-lg"
                    : "text-sunday_info hover:bg-gray-200 rounded-lg"
                    }`}
                >
                  <FaCog className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Settings</span>
                </button>
              </div>

              <div className="mt-8">
                <button
                  onClick={handleLogoutClick}
                  className="flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full text-sunday_info bg-white rounded-lg hover:bg-sunday_secondary"
                >
                  <FaSignOutAlt className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Logout</span>
                </button>
              </div>

              <div className="text-center mt-8">
                <p className="text-sm text-gray-600">
                  &copy; 2022 eventfullsundays.co.uk
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Main Content */}
        <div
          className={`w-full ${isSidebarVisible ? "sm:w-full lg:w-[65%] xl:w-[72%]" : ""
            } p-4 md:p-2`}
        >
          {isMobile && !isSidebarVisible && (
            <button
              onClick={() => {
                setIsSidebarVisible(true);
                setSelectedSection("profile");
              }}
              className="flex items-center mt-2 ms-2 mb-4 text-xl text-sunday_info hover:text-sunday_primary duration-100 transition"
            >
              <FaArrowLeft className="mr-2" /> Back
            </button>
          )}
          {renderContent()}
        </div>
      </div>

      <Footer />

      {/** Logout Confirmation modal */}
      <LogoutConfirmationModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onConfirm={handleLogout}
      />

    </div>
  );
};

export default Dashboard;
