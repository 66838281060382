// src/components/modals/TicketUpdateModal.jsx

import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FaCalendarAlt, FaTicketAlt, FaMoneyBillWave, FaListOl, FaInfoCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

// Bind modal to your appElement for accessibility
Modal.setAppElement('#root');

const TicketUpdateModal = ({ isOpen, onRequestClose, onSubmit, initialValues }) => {
  // Define validation schema
  const validationSchema = Yup.object().shape({
    event: Yup.string().required("This field is required."),
    ticket_category: Yup.string().required("This field is required."),
    price: Yup.number()
      .typeError("Price must be a number")
      .positive("Price must be positive")
      .required("This field is required."),
    total_quantity: Yup.number()
      .typeError("Total quantity must be a number")
      .integer("Must be an integer")
      .positive("Must be positive")
      .required("This field is required."),
    ticket_description: Yup.string().required("This field is required."),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Update Ticket"
      className="flex justify-center items-center p-6"
      overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-50"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl relative overflow-y-auto max-h-[90vh]">
        {/* Close Button */}
        <button
          onClick={onRequestClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <MdClose className="w-6 h-6" />
        </button>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await onSubmit(values);
              setSubmitting(false);
              onRequestClose(); // Close the modal after updating
            } catch (error) {
              console.error("Error updating ticket:", error);
              toast.error("Failed to update ticket. Please try again.");
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="w-full">
              {/* Title */}
              <p className="text-2xl font-semibold text-center mb-6">Update Ticket</p>

              {/* Form Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                {/* Event Name (Disabled) */}
                <div className="mb-4">
                  <label htmlFor="eventName" className="block mb-2 text-sm font-medium text-gray-700">
                    Event <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <FaCalendarAlt className="absolute left-3 top-4 text-gray-500 w-5 h-5" />
                    <Field
                      type="text"
                      id="eventName"
                      name="eventName" // Separate name for display
                      value={initialValues.eventName}
                      disabled
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  <ErrorMessage name="event" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Hidden Event ID */}
                <Field type="hidden" name="event" />

                {/* Ticket Category */}
                <div className="mb-4">
                  <label htmlFor="ticket_category" className="block mb-2 text-sm font-medium text-gray-700">
                    Ticket Category <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <FaTicketAlt className="absolute left-3 top-4 text-gray-500 w-5 h-5" />
                    <Field
                      as="select"
                      id="ticket_category"
                      name="ticket_category"
                      className="w-full p-3 pl-10 border rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="" label="Select category" disabled />
                      <option value="free">Free</option>
                      <option value="basic">Basic</option>
                      <option value="standard">Standard</option>
                      <option value="premium">Premium</option>
                    </Field>
                  </div>
                  <ErrorMessage name="ticket_category" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Price */}
                <div className="mb-4">
                  <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-700">
                    Price (£) <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <FaMoneyBillWave className="absolute left-3 top-4 text-gray-500 w-5 h-5" />
                    <Field
                      type="number"
                      id="price"
                      name="price"
                      placeholder="100"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                      min="0"
                      step="0.01"
                    />
                  </div>
                  <ErrorMessage name="price" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Total Quantity */}
                <div className="mb-4">
                  <label htmlFor="total_quantity" className="block mb-2 text-sm font-medium text-gray-700">
                    Total Quantity <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <FaListOl className="absolute left-3 top-4 text-gray-500 w-5 h-5" />
                    <Field
                      type="number"
                      id="total_quantity"
                      name="total_quantity"
                      placeholder="50"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                      min="1"
                    />
                  </div>
                  <ErrorMessage name="total_quantity" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Ticket Description */}
                <div className="mb-4 md:col-span-2">
                  <label htmlFor="ticket_description" className="block mb-2 text-sm font-medium text-gray-700">
                    Ticket Description <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <FaInfoCircle className="absolute left-3 top-3 text-gray-500 w-5 h-5" />
                    <Field
                      as="textarea"
                      id="ticket_description"
                      name="ticket_description"
                      placeholder="Access to VIP lounge and complimentary drinks."
                      className="w-full p-3 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none h-24"
                    />
                  </div>
                  <ErrorMessage name="ticket_description" component="div" className="text-red-500 text-sm mt-1" />
                </div>

              </div>

              {/* Footer - Buttons */}
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onRequestClose}
                  className="bg-gray-500 hover:bg-gray-600 text-white rounded-full px-6 py-2 transition duration-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`bg-blue-500 hover:bg-blue-700 text-white rounded-full px-6 py-2 transition duration-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {isSubmitting ? "Updating..." : "Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

TicketUpdateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    event: PropTypes.string.isRequired, // Event ID
    eventName: PropTypes.string.isRequired, // Event Name for display
    ticket_category: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    total_quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    ticket_description: PropTypes.string.isRequired,
  }).isRequired,
};

export default TicketUpdateModal;
