// src/redux/auth/action/LoginAction.jsx

import axios from "axios";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../constant/LoginConstant";
import apiClient from "../../../apiClient/apiClient";
import { toast } from "react-toastify";

// Create an axios instance without interceptors for token refresh
const refreshClient = axios.create({
  baseURL: process.env.REACT_APP_API_CLIENT_BASE_URL,
});

// Action creators
export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

// Condition for login failure
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

// Condition for logout
export const logout = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  sessionStorage.removeItem("access");
  sessionStorage.removeItem("refresh");
  return { type: LOGOUT };
};

// Action to handle user login
export const loginUser = (values, remember = false) => async (dispatch) => {
  dispatch(loginRequest());

  try {
    // Clear existing tokens to avoid conflicts
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");

    // Attempt to log in
    const response = await apiClient.post("login/", values);
    const { access, refresh } = response.data;

    console.log("[LOGIN DEBUG] Login response: ", response.detail);

    if (access && refresh) {
      // Store tokens based on "Remember Me" checkbox
      if (remember) {
        localStorage.setItem("access", access);
        localStorage.setItem("refresh", refresh);
        console.log("Tokens stored in localStorage");
      } else {
        sessionStorage.setItem("access", access);
        sessionStorage.setItem("refresh", refresh);
        console.log("Tokens stored in sessionStorage");
      }

      // Fetch user details to verify role
      const config = {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      };

      const { data: userData } = await apiClient.get("/user/", config);

      if (userData.role === "organizer") {
        dispatch(loginSuccess({ access, refresh }));
        // Removed redirection from here
      } else {
        toast.error('Only Organizers are allowed to login to this view');
        dispatch(logout());
      }

    } else {
      console.log("[LOGIN DEBUG] Else Response: ", response);
      throw new Error("Invalid response from the server");
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { data } = error.response;

      console.log("[TESTING] Error: ", error);

      if (data.email) {
        toast.error("No active account with this email");
      } else if (data.password) {
        toast.error("Invalid password");
      } else {        
        toast.error(data.detail || "An error occurred. Please try again later.");
      }
    } else {
      console.log("ERROR BUGFIXIN: ",error);

      toast.error("An error occurred. Please try again later.");
    }

    dispatch(loginFailure("Invalid email or password"));
  }
};

// Refresh Access Token (No changes needed here)
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const refreshAccessToken = () => async (dispatch) => {
  try {
    if (isRefreshing) {
      // Return a promise that resolves once the token is refreshed
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      });
    }

    isRefreshing = true;

    const refreshToken = localStorage.getItem("refresh") || sessionStorage.getItem("refresh");

    if (!refreshToken) console.error("No refresh token available");

    const response = await refreshClient.post("/token/refresh/", { refresh: refreshToken });
    const { access: newAccessToken, refresh: newRefreshToken } = response.data;

    // Store the new tokens in the appropriate storage
    if (localStorage.getItem("refresh")) {
      localStorage.setItem("access", newAccessToken);
      localStorage.setItem("refresh", newRefreshToken);
    } else if (sessionStorage.getItem("refresh")) {
      sessionStorage.setItem("access", newAccessToken);
      sessionStorage.setItem("refresh", newRefreshToken);
    }

    // Dispatch the new tokens to Redux store
    dispatch(
      loginSuccess({
        access: newAccessToken,
        refresh: newRefreshToken,
      })
    );

    processQueue(null, newAccessToken); // Resolve all pending requests with new access token
    console.log("[TESTING] Token refreshed successfully");
    isRefreshing = false;
    return newAccessToken;
  } catch (error) {
    processQueue(error, null);
    isRefreshing = false;

    // Handle failure (log out, clear tokens, etc.)
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");
    dispatch(logout());

    console.log("[TESTING] Refresh token failed, user logged out:", error);
    return Promise.reject(error);
  }
};


//logout section
export const logoutUser = () => async (dispatch) => {
  try {
    const refresh = localStorage.getItem("refresh") || sessionStorage.getItem("refresh");
    if (refresh) {
      const response = await apiClient.post("logout/", { refresh });
      console.log(response);
      if (response.status === 205) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        sessionStorage.removeItem("access");
        sessionStorage.removeItem("refresh");
        dispatch(logout());
        window.location.href = "/";
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } else {
      console.log("No refresh token found.");
    }
  } catch (error) {
    console.error("Logout error:", error);
    // Handle error (e.g., show an alert to the user)
  }
};
