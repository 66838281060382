import apiClient from "../../../apiClient/apiClient";
import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_UPDATE_FAILURE,
  GET_PROFILE_UPDATE_REQUEST,
  GET_PROFILE_UPDATE_SUCCESS,
  GET_PROFILE_VERIFY_RESEND_REQUEST,
  GET_PROFILE_VERIFY_RESEND_SUCCESS,
  GET_PROFILE_VERIFY_RESEND_FAILURE
} from "../constant/ProfileConstant";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Action to get user profile
export const getUserProfile = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_REQUEST });
  try {
    const accessToken = localStorage.getItem("access") || sessionStorage.getItem("access");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const { data: user } = await apiClient.get("/user/", config);
    const { data: organizerProfile } = await apiClient.get("/organizer-profile/", config)

    const userMerged = { ...user, ...organizerProfile }


    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: userMerged,
    });

    console.log(userMerged);

  } catch (error) {
    dispatch({
      type: GET_PROFILE_FAILURE,
      payload:
        error.response?.data?.error ||
        "An error occurred while fetching the profile",
    });
  }
};

// Action to update user profile
export const updateUserProfile = (formData) => async (dispatch) => {
  dispatch({ type: GET_PROFILE_UPDATE_REQUEST });

  try {
    const token = localStorage.getItem("access") || sessionStorage.getItem("access");

    // Display the key/value pairs
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }


    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data: userProfile } = await apiClient.put(
      "/user-profile/",
      formData,
      config
    );

    const { data: user } = await apiClient.get("/user/", config);

    const userMerged = { ...user, ...userProfile }

    dispatch({
      type: GET_PROFILE_UPDATE_SUCCESS,
      payload: userMerged,
    });

    toast.success(<p className="m-2">You've successfully updated your profile!</p>);

  } catch (error) {
    dispatch({
      type: GET_PROFILE_UPDATE_FAILURE,
      payload: error.message,
    });
  }
};

// Action to resend acccount verification email
export const sendResetVerificationEmail = (email) => async (dispatch) => {
  dispatch({ type: GET_PROFILE_VERIFY_RESEND_REQUEST });

  try {
    // const token = localStorage.getItem("access");

    console.log("[VERIFY TESTING] Email: ", email);


    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // };

    const response = await apiClient.post("/resend-activation/", { email: email });

    if (response.status === 200) {
      console.log("[VERIFY TESTING] Success: ", response);
      dispatch({
        type: GET_PROFILE_VERIFY_RESEND_SUCCESS,
        payload: response,
      });
    } else {
      console.log("[VERIFY TESTING] There was an error in response: ", response.status);

      dispatch({
        type: GET_PROFILE_VERIFY_RESEND_FAILURE,
        payload: response.status,
      });
    }

  } catch (error) {
    console.log("[VERIFY TESTING] There was an error in catch: ", error);

    dispatch({
      type: GET_PROFILE_VERIFY_RESEND_FAILURE,
      payload: error.message,
    });
  }
};
