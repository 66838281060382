// src/redux/tickets/ticketActions.js

import {
    TICKET_FETCH_ALL_REQUEST,
    TICKET_FETCH_ALL_SUCCESS,
    TICKET_FETCH_ALL_FAIL,
    TICKET_FETCH_DETAILS_REQUEST,
    TICKET_FETCH_DETAILS_SUCCESS,
    TICKET_FETCH_DETAILS_FAIL,
    TICKET_DELETE_REQUEST,
    TICKET_DELETE_SUCCESS,
    TICKET_DELETE_FAIL,
    TICKET_UPDATE_REQUEST,
    TICKET_UPDATE_SUCCESS,
    TICKET_UPDATE_FAIL,
} from "./ticketConstants";

import apiClient from "../../apiClient/apiClient";

// Action to fetch all tickets
export const fetchAllTickets = () => async (dispatch) => {
    try {
        dispatch({ type: TICKET_FETCH_ALL_REQUEST });

        const token = localStorage.getItem("access") || sessionStorage.getItem("access");

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        const { data } = await apiClient.get("tickets/", config);

        console.log("[DEBUG] All tickets list: ", data);
        

        dispatch({
            type: TICKET_FETCH_ALL_SUCCESS,
            payload: data, // Assuming the API returns an array of tickets
        });
    } catch (error) {
        dispatch({
            type: TICKET_FETCH_ALL_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// Action to fetch a specific ticket by event ID
export const fetchTicketDetails = (eventID) => async (dispatch) => {
    try {
        dispatch({ type: TICKET_FETCH_DETAILS_REQUEST });

        const token = localStorage.getItem("access") || sessionStorage.getItem("access");

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        const { data } = await apiClient.get(`ticket/${eventID}/`, config);

        dispatch({
            type: TICKET_FETCH_DETAILS_SUCCESS,
            payload: data, // Assuming the API returns ticket details
        });
    } catch (error) {
        dispatch({
            type: TICKET_FETCH_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// Action to delete a ticket by ticket ID
export const deleteTicket = (ticketID) => async (dispatch) => {
    try {
        dispatch({ type: TICKET_DELETE_REQUEST });

        const token = localStorage.getItem("access") || sessionStorage.getItem("access");

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        await apiClient.delete(`ticket/${ticketID}/`, config);

        dispatch({
            type: TICKET_DELETE_SUCCESS,
            payload: ticketID, // Passing the deleted ticket ID for reducer
        });
    } catch (error) {
        dispatch({
            type: TICKET_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// Update Ticket
export const updateTicket = (ticketID, updatedData) => async (dispatch) => {
    try {
        dispatch({ type: TICKET_UPDATE_REQUEST });

        console.log("[DEBUG] Updated ticket data: ", updatedData);

        const token = localStorage.getItem("access") || sessionStorage.getItem("access");

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        const { data } = await apiClient.put(`ticket/${ticketID}/`, updatedData, config);

        dispatch({
            type: TICKET_UPDATE_SUCCESS,
            payload: data, // Assuming the API returns the updated ticket
        });
    } catch (error) {
        dispatch({
            type: TICKET_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

// Reset Ticket Update
export const resetTicketUpdate = () => ({
    type: 'TICKET_UPDATE_RESET',
  });