import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EventCard from './EventCard';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'; // Import icons

const EventsList = ({ events, ticketsByEvent, onSelectEvent, onDeleteEvent }) => {
  const [sortColumn, setSortColumn] = useState('event_id');
  const [sortDirection, setSortDirection] = useState('desc');

  const handleSort = (column) => {
    if (sortColumn === column) {
      // Toggle sort direction if same column is clicked
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new sort column and default to ascending
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Apply sorting to the events array
  const sortedEvents = [...events].sort((a, b) => {
    let aValue, bValue;

    switch (sortColumn) {
      case 'event_id':
        aValue = a.event_id;
        bValue = b.event_id;
        break;
      case 'event_name':
        aValue = a.event_name.toLowerCase();
        bValue = b.event_name.toLowerCase();
        break;
      case 'sold':
        const soldA = ticketsByEvent[a.event_id]?.reduce(
          (sum, ticket) => sum + (ticket.ticket_sold || 0),
          0
        ) || 0;
        const soldB = ticketsByEvent[b.event_id]?.reduce(
          (sum, ticket) => sum + (ticket.ticket_sold || 0),
          0
        ) || 0;
        aValue = soldA;
        bValue = soldB;
        break;
      case 'gross':
        const grossA = ticketsByEvent[a.event_id]?.reduce(
          (sum, ticket) => sum + ((ticket.ticket_sold || 0) * (ticket.price || 0)),
          0
        ) || 0;
        const grossB = ticketsByEvent[b.event_id]?.reduce(
          (sum, ticket) => sum + ((ticket.ticket_sold || 0) * (ticket.price || 0)),
          0
        ) || 0;
        aValue = grossA;
        bValue = grossB;
        break;
      default:
        aValue = a.event_id;
        bValue = b.event_id;
    }

    if (aValue < bValue) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return (
    <div className="events-list">
      {/* Header */}
      <div className="flex justify-between items-center py-4 px-6 bg-gray-100">
        <div
          className="w-1/2 flex items-center cursor-pointer"
          onClick={() => handleSort('event_id')}
        >
          <span className="font-bold flex items-center" title='Sort by event creation order'>
            Event
            {sortColumn === 'event_id' ? (
              sortDirection === 'asc' ? (
                <FaSortUp className="ml-1" />
              ) : (
                <FaSortDown className="ml-1" />
              )
            ) : (
              <FaSort className="ml-1" />
            )}
          </span>
        </div>
        <div
          className="hidden lg:flex w-1/6 justify-end cursor-pointer"
          onClick={() => handleSort('sold')}
        >
          <span className="font-bold flex items-center">
            Sold
            {sortColumn === 'sold' ? (
              sortDirection === 'asc' ? (
                <FaSortUp className="ml-1" />
              ) : (
                <FaSortDown className="ml-1" />
              )
            ) : (
              <FaSort className="ml-1" />
            )}
          </span>
        </div>
        <div
          className="hidden lg:flex w-1/6 justify-end cursor-pointer"
          onClick={() => handleSort('gross')}
        >
          <span className="font-bold flex items-center">
            Gross
            {sortColumn === 'gross' ? (
              sortDirection === 'asc' ? (
                <FaSortUp className="ml-1" />
              ) : (
                <FaSortDown className="ml-1" />
              )
            ) : (
              <FaSort className="ml-1" />
            )}
          </span>
        </div>
        <div className="w-1/6 flex justify-end">
          {/* Empty header for Action column */}
        </div>
      </div>
      <ul className="eds-card-list">
        {sortedEvents.map((event) => (
          <EventCard
            key={event.event_id}
            event={event}
            tickets={ticketsByEvent[event.event_id] || []}
            onSelectEvent={onSelectEvent}
            onDeleteEvent={onDeleteEvent}
          />
        ))}
      </ul>
    </div>
  );
};

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
  ticketsByEvent: PropTypes.object.isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func.isRequired,
};

export default EventsList;
