// src/components/OrganizerCompanyField.jsx

import React, { useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const OrganizerCompanyField = ({ profileState }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (
      !values.organizer_company && // If the field is empty
      profileState?.profile?.company_name // Profile data is available
    ) {
      setFieldValue('organizer_company', profileState.profile.company_name);
    }
  }, [values.organizer_company, profileState, setFieldValue]);

  return (
    <div className="mb-4">
      <p className="font-medium text-base tracking-wider">
        Organizer Company <span className="text-red-500">*</span>
      </p>
      <Field name="organizer_company">
        {({ field, form: { errors, touched } }) => (
          <input
            type="text"
            {...field}
            placeholder="Organizer company"
            className={`w-full text-sm p-3 rounded-full pl-4 tracking-wide border mt-2 ${
              errors.organizer_company && touched.organizer_company
                ? 'border-red-500'
                : 'border-[#0875E1] focus:border-sunday_primary focus:outline-none'
            }`}
            disabled={false}
          />
        )}
      </Field>
      <ErrorMessage
        name="organizer_company"
        component="div"
        className="text-red-500 text-sm ml-2"
      />
    </div>
  );
};

export default OrganizerCompanyField;
