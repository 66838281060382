// src/redux/tickets/ticketReducer.js

import {
    TICKET_FETCH_ALL_REQUEST,
    TICKET_FETCH_ALL_SUCCESS,
    TICKET_FETCH_ALL_FAIL,
    TICKET_FETCH_DETAILS_REQUEST,
    TICKET_FETCH_DETAILS_SUCCESS,
    TICKET_FETCH_DETAILS_FAIL,
    TICKET_DELETE_REQUEST,
    TICKET_DELETE_SUCCESS,
    TICKET_DELETE_FAIL,
    TICKET_UPDATE_REQUEST,
    TICKET_UPDATE_SUCCESS,
    TICKET_UPDATE_FAIL,
} from "./ticketConstants";

// Reducer to handle fetching all tickets
export const ticketFetchAllReducer = (state = { tickets: [] }, action) => {
    switch (action.type) {
        case TICKET_FETCH_ALL_REQUEST:
            return { loading: true, tickets: [] };
        case TICKET_FETCH_ALL_SUCCESS:
            return { loading: false, tickets: action.payload };
        case TICKET_FETCH_ALL_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer to handle fetching ticket details
export const ticketFetchDetailsReducer = (state = { ticket: {} }, action) => {
    switch (action.type) {
        case TICKET_FETCH_DETAILS_REQUEST:
            return { loading: true, ...state };
        case TICKET_FETCH_DETAILS_SUCCESS:
            return { loading: false, ticket: action.payload };
        case TICKET_FETCH_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer to handle deleting a ticket
export const ticketDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TICKET_DELETE_REQUEST:
            return { loading: true };
        case TICKET_DELETE_SUCCESS:
            return { loading: false, success: true, deletedTicketId: action.payload };
        case TICKET_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

// Update Ticket
export const ticketUpdateReducer = (state = { ticket: {} }, action) => {
    switch (action.type) {
        case TICKET_UPDATE_REQUEST:
            return { loading: true };
        case TICKET_UPDATE_SUCCESS:
            return { loading: false, success: true, ticket: action.payload };
        case TICKET_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case 'TICKET_UPDATE_RESET':
            return { ...state, success: false, error: null };
        default:
            return state;
    }
};
