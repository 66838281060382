// src/redux/createEvent/CreateCategory/CreateCategoryActions.js

import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
} from "../constant/categoryConstants";
import apiClient from "../../../apiClient/apiClient";

// Action to fetch categories
export const fetchCategories = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CATEGORIES_REQUEST });
    try {
      const response = await apiClient.get("/category/");

      console.log("[DEBUG] Category response: ", response);

      dispatch({
        type: FETCH_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
};
